import {
  getAllVehicleMaintenances,
  getAllVehicleData,
  saveVehicleMaintenance,
  updateVehicleMaintenance,
  deleteVehicleMaintenance,
  checkForAffectedDrivings,
  deleteVehicleMaintenanceAttachment,
} from '@/api/vehicleMaintenances';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllVehicleMaintenances(state, item) {
      return getAllVehicleMaintenances(item);
    },
    async getAllVehicleData(state, item) {
      return getAllVehicleData(item);
    },
    async saveVehicleMaintenance(state, item) {
      return saveVehicleMaintenance(item);
    },
    async updateVehicleMaintenance(state, item) {
      return updateVehicleMaintenance(item);
    },
    async deleteVehicleMaintenance(state, item) {
      return deleteVehicleMaintenance(item);
    },
    async checkForAffectedDrivings(state, item) {
      return checkForAffectedDrivings(item);
    },
    async deleteVehicleMaintenanceAttachment(state, item) {
      return deleteVehicleMaintenanceAttachment(item);
    },
  },
  getters: {},
};
