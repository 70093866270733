import { getHttpClient } from './client';

export const getAllPricingRegions = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/price-region';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getPricingRegion = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/price-region/' + data).then((response) => response.data);
};

export const savePricingRegion = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/price-region', 'POST', data).then(
    (response) => response.data
  );
};

export const updatePricingRegion = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/price-region', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePricingRegion = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/price-region/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const getAllCountries = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/country';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};
