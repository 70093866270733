import { getAllFeedbacks } from '@/api/feedbacks';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getAllFeedbacks() {
      return getAllFeedbacks();
    },
  },
};
