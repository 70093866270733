import { loadDrivingDetails, getAllVehicleClasses, saveDriving, cancelDriving, getAllInvoices } from '@/api/customers';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async loadDrivingDetails(state, item) {
      return loadDrivingDetails(item);
    },
    async getAllVehicleClasses(state, item) {
      return getAllVehicleClasses(item);
    },
    async saveDriving(state, item) {
      return saveDriving(item);
    },
    async cancelDriving(state, item) {
      return cancelDriving(item);
    },
    async getAllInvoices(state, item) {
      return getAllInvoices(item);
    },
  },
  getters: {},
};
