import { getHttpClient } from './client';

export const getAllRebates = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/charge-scale';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getRebate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/charge-scale/' + data).then((response) => response.data);
};

export const saveRebate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/charge-scale', 'POST', data).then(
    (response) => response.data
  );
};

export const updateRebate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/charge-scale', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteRebate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/charge-scale/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
