<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mb-2 ms-5"
          :disabled="disabled"
          :loading="loading"
          @click="getFlightInfoData()"
          v-on="on"
          v-bind="attrs">
          <v-icon>mdi-airplane</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('driving.flightInfo') }}</span>
    </v-tooltip>

    <v-dialog v-model="flightInfoDialog" max-width="700px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('driving.flightInfo') }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="flightInfoDialog = false"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="mt-5">
            <tbody>
              <tr>
                <th>{{ $t('flightInfo.airlineName') }}</th>
                <td>{{ flightInfo.airline_name || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.flightNumber') }}</th>
                <td>{{ flightInfo.flight_iata || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.departureAirport') }}</th>
                <td>{{ flightInfo.dep_name || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.departureCountry') }}</th>
                <td>{{ flightInfo.dep_country || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.departureTime') }}</th>
                <td>{{ flightInfo.dep_time || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.arrivalAirport') }}</th>
                <td>{{ flightInfo.arr_name || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.arrivalCountry') }}</th>
                <td>{{ flightInfo.arr_country || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.arrivalTime') }}</th>
                <td>{{ flightInfo.arr_time || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.departureDelayed') }}</th>
                <td v-if="flightInfo.arr_delayed" class="font-weight-bold red--text">
                  {{ formatMinutes(flightInfo.arr_delayed) }}
                </td>
                <td v-else>{{ '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.flightStatus') }}</th>
                <td>{{ flightInfo.status || '/' }}</td>
              </tr>
              <tr>
                <th>{{ $t('flightInfo.percentageOfFlight') }}</th>
                <td>{{ flightInfo.percent || '/' }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { defaultFlightInfo } from '@/mixins/default-items';
export default {
  name: 'FlightInfo',
  components: {},
  props: ['flightNumber', 'disabled'],
  data: () => ({
    flightInfo: {},
    flightInfoDialog: false,
    loading: false,
  }),
  mounted() {},
  created() {
    this.flightInfo = Object.assign({}, defaultFlightInfo);
  },
  computed: {},
  methods: {
    async getFlightInfoData() {
      this.loading = true;
      await this.$store
        .dispatch('drivings/getFlightInfo', this.flightNumber)
        .then((res) => {
          this.flightInfo = res.data;
          this.flightInfoDialog = true;
        })
        .catch((error) => {
          if (error.response.data.data == 'Flight not found') {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('customValidations.flightNotExist', {
                field: this.flightNumber,
              }),
              color: 'red',
            });
          } else {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.anErrorOccured'),
              color: 'red',
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatMinutes(minutes) {
      let hours = Math.floor(minutes / 60); // Calculate total hours
      let remainingMinutes = minutes % 60; // Calculate remaining minutes

      // Convert hours and minutes to strings and add leading zeros if needed
      let hoursFormatted = hours.toString().padStart(2, '0');
      let minutesFormatted = remainingMinutes.toString().padStart(2, '0');

      return `${hoursFormatted}:${minutesFormatted}`;
    },
  },
  watch: {
    flightInfoDialog(val) {
      if (!val) {
        this.flightInfo = Object.assign({}, defaultFlightInfo);
      }
    },
  },
};
</script>
