import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/modules/auth';
import partners from '@/store/modules/partners';
import vehicles from '@/store/modules/vehicles';
import users from '@/store/modules/users';
import clients from '@/store/modules/clients';
import notifications from '@/store/modules/notifications';
import organisations from '@/store/modules/organisations';
import roles from '@/store/modules/roles';
import errorMessages from '@/store/modules/errorMessages';
import charts from '@/store/modules/statisticsCharts';
import feedbacks from '@/store/modules/feedbacks';
import vehicleClasses from '@/store/modules/vehicleClasses';
import rebates from '@/store/modules/rebates';
import customerCards from '@/store/modules/customerCards';
import transactions from '@/store/modules/transactions';
import articlesOfLaw from '@/store/modules/articlesOfLaw';
import pricingRegions from '@/store/modules/pricingRegions';
import currencies from '@/store/modules/currencies';
import paymentMethods from '@/store/modules/paymentMethods';
import drivings from '@/store/modules/drivings';
import googleMapsAPI from '@/store/modules/googleMapsAPI';
import gpsMapAPI from '@/store/modules/gpsMapAPI';
import firebaseToken from '@/store/modules/firebaseToken';
import drivingComment from '@/store/modules/drivingComment';
import statistic from '@/store/modules/statistic';
import expenses from '@/store/modules/expenses';
import vehicleMaintenances from '@/store/modules/vehicleMaintenances';
import subscriptionPackages from '@/store/modules/subscriptionPackages';
import language from '@/store/modules/language';
import forgetPassword from '@/store/modules/forgetPassword';
import loader from '@/store/modules/loader';
import onboardingTutorial from '@/store/modules/onboardingTutorial';
import gnet from '@/store/modules/gnet';
import customers from '@/store/modules/customers';
import invoices from '@/store/modules/invoices';
import drivingOffers from '@/store/modules/drivingOffers';
import dashboard from '@/store/modules/dashboard';
import resources from '@/store/modules/resources';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    snackbar: {},
    addedNewDrivingCounter: 0,
    notificationDriving: { id: null, reload: true },
  },
  getters: {},
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
    updatedNewDrivingCounter(state) {
      state.addedNewDrivingCounter++;
    },
    updateNotificationDriving(state, value) {
      state.notificationDriving.id = value;
      state.notificationDriving.reload = true;
    },
    updateNotificationDrivingReload(state) {
      state.notificationDriving.reload = false;
    },
  },
  actions: {
    showSnackbar({ commit }, snackbar) {
      snackbar.active = true;
      commit('SET_SNACKBAR', snackbar);
    },
  },
  modules: {
    auth,
    partners,
    vehicles,
    users,
    clients,
    notifications,
    organisations,
    roles,
    errorMessages,
    charts,
    feedbacks,
    vehicleClasses,
    rebates,
    customerCards,
    transactions,
    articlesOfLaw,
    pricingRegions,
    currencies,
    paymentMethods,
    drivings,
    googleMapsAPI,
    gpsMapAPI,
    firebaseToken,
    drivingComment,
    statistic,
    expenses,
    vehicleMaintenances,
    subscriptionPackages,
    language,
    forgetPassword,
    loader,
    onboardingTutorial,
    gnet,
    customers,
    invoices,
    drivingOffers,
    dashboard,
    resources,
  },
});
