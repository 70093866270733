import {
  getAllUsers,
  getAllSaasAdmins,
  getAllCustomers,
  saveUser,
  updateUser,
  deleteUser,
  changeUserActiveStatus,
  checkIfDriverIsAvailable,
} from '@/api/users';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getAllUsers(state, item) {
      return getAllUsers(item);
    },
    async getAllSaasAdmins() {
      return getAllSaasAdmins();
    },
    async getAllCustomers(state, item) {
      return getAllCustomers(item);
    },
    async saveUser(state, item) {
      return saveUser(item);
    },
    async updateUser(state, item) {
      return updateUser(item);
    },
    async deleteUser(state, item) {
      return deleteUser(item);
    },
    async changeUserActiveStatus(state, item) {
      return changeUserActiveStatus(item);
    },
    async checkIfDriverIsAvailable(state, item) {
      return checkIfDriverIsAvailable(item);
    },
  },
  getters: {},
};
