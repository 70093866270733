import { getHttpClient } from './client';

export const getAllCurrencies = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/currency';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getCurrency = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/currency/' + data).then((response) => response.data);
};

export const saveCurrency = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/currency', 'POST', data).then((response) => response.data);
};

export const updateCurrency = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/currency', 'POST', data).then((response) => response.data);
};

export const deleteCurrency = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/currency/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const changeCurrencyActiveStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/currency-status/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};

export const getOrganisationCurrencies = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/organisation-currency';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getOrganisationAvailableCurrencies = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/organisation-currency-available-currencies';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveOrganisationCurrencies = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-currency', 'POST', data).then((response) => response.data);
};

export const deleteOrganisationCurrency = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-currency/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const changeCurrencyDefaultStatus = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/organisation-currency-default-status/' + data.id,
    'POST',
    data
  ).then((response) => response.data);
};
