<template>
  <v-btn :disabled="this.failed || this.loading" :loading="this.loading" class="primary" text type="submit">
    {{ $t(buttonText) }}
  </v-btn>
</template>

<script>
export default {
  name: 'VehiclePage',
  props: ['failed', 'loading', 'buttonText'],
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
