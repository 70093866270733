export const languageOptions = [
  { abbr: 'me', lang: 'Crnogorski' },
  { abbr: 'en', lang: 'English' },
  { abbr: 'de', lang: 'Deutsch' },
  { abbr: 'es', lang: 'Español' },
  { abbr: 'fr', lang: 'Français' },
  { abbr: 'hr', lang: 'Hrvatski' },
  { abbr: 'it', lang: 'Italiano' },
  { abbr: 'nl', lang: 'Nederlands' },
  { abbr: 'pt', lang: 'Português' },
  { abbr: 'ru', lang: 'Русский' },
  { abbr: 'sl', lang: 'Slovenščina' },
  { abbr: 'sr', lang: 'Српски' },
  { abbr: 'tr', lang: 'Türkçe' },
];
