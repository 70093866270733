import { getHttpClient } from './client';

export const getAllArticlesOfLaw = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/organisation-settings';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAllAdvancedSettingsForOrganization = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-settings-auth').then(
    (response) => response.data
  );
};

export const getArticlesOfLaw = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-settings/' + data).then(
    (response) => response.data
  );
};

export const saveArticlesOfLaw = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-settings', 'POST', data).then(
    (response) => response.data
  );
};

export const updateArticlesOfLaw = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-settings', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteArticlesOfLaw = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-settings/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
