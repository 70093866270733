import {
  deleteVehicleExpense,
  getAllExpensesTypes,
  getAllVehicleExpenses,
  saveVehicleExpense,
  updateVehicleExpense,
  deleteExpenseAttachment,
  exportExcel,
} from '@/api/expenses';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllVehicleExpenses(state, item) {
      return getAllVehicleExpenses(item);
    },
    async getAllExpensesTypes() {
      return getAllExpensesTypes();
    },
    async deleteVehicleExpense(state, item) {
      return deleteVehicleExpense(item);
    },
    async saveVehicleExpense(state, item) {
      return saveVehicleExpense(item);
    },
    async updateVehicleExpense(state, item) {
      return updateVehicleExpense(item);
    },
    async deleteExpenseAttachment(state, item) {
      return deleteExpenseAttachment(item);
    },
    async exportExcel(state, item) {
      return exportExcel(item);
    },
  },
  getters: {},
};
