export function checkoutSuccessCallBack(data, store) {
  if (data.name === 'checkout.completed') {
    var newResource = store.state.auth.chargeDateBody;
    if (newResource) {
      newResource.trialEnded = true;

      var trialEndDate = new Date();
      trialEndDate.setHours(trialEndDate.getHours() - 1); // set new for next 24 hours
      newResource.trialEndDate = trialEndDate;

      var chargeDate = new Date();
      chargeDate.setHours(chargeDate.getHours() + 29 * 24); // set new for next month
      newResource.date = chargeDate;

      store.dispatch('auth/setChargeDateBody', newResource);
    }
    // JUST REVERSE PADDLE STATE IN ORDER TO WATCH FOR CHANGES TO CHANGE ACTIVE PACKAGE
    store.dispatch('auth/setPaddleState', !store.state.auth.paddleState);

    store.dispatch('auth/setTrialBannerDisplay', false);

    var date = new Date();
    date.setHours(date.getHours() + 24); // set new for next 24 hours
    localStorage.setItem('showtime', date);
  }
}
