import { getHttpClient } from './client';

export const getAllCards = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/payment-card';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getCard = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-card/' + data).then((response) => response.data);
};

export const saveCard = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-card', 'POST', data).then(
    (response) => response.data
  );
};

export const updateCard = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-card', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteCard = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-card/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
