import { getHttpClient } from './client';

export const getAllOrganisations = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation').then((response) => response.data);
};

export const getAllOrganisationsForSales = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/get-organisations-for-sales').then(
    (response) => response.data
  );
};

export const getOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation/' + data).then((response) => response.data);
};

export const saveOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation', 'POST', data).then(
    (response) => response.data
  );
};

export const updateOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const loadOrganisationViewOnlyData = async (id) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-view-only-data/' + id, 'GET').then(
    (response) => response.data
  );
};

export const toggleOrganisationsActiveStatus = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/change-organisation-active-status/' + data.id,
    'POST'
  ).then((response) => response.data);
};

export const assignSaasAdminOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/change-company', 'POST', data).then(
    (response) => response.data
  );
};

export const toggleOrganisationPublicRoutes = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation-settings-allow-api', 'POST').then(
    (response) => response.data
  );
};

export const toggleOrganisationPublicLink = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/toggle-public-booking').then(
    (response) => response.data
  );
};

export const deleteOrganizationImage = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/delete-organisation-image/' + data.id, 'POST').then(
    (response) => response.data
  );
};

export const deleteOrganizationSignature = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/delete-organisation-signature/' + data.id, 'POST').then(
    (response) => response.data
  );
};

export const deleteOrganizationSeal = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/delete-organisation-seal/' + data.id, 'POST').then(
    (response) => response.data
  );
};

export const getAllReferents = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/referents').then((response) => response.data);
};

export const enableStripeConnect = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/stripe/create-account', 'POST').then(
    (response) => response.data
  );
};

export const disableStripeConnect = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/stripe/disable-connect', 'POST').then(
    (response) => response.data
  );
};
