export default {
  sidebar: {
    fleet: 'Frota',
    vehicles: 'Veículos',
    vehiclesClasses: 'Classes de Veículos',
    administration: 'Administração',
    currencies: 'Moedas',
    currenciesAdministration: 'Administração de Moedas',
    paymentTypes: 'Métodos de Pagamento',
    paymentTypesAdministration: 'Administração de Métodos de Pagamento',
    users: 'Usuários',
    usersAdministration: 'Administração de Usuários',
    clients: 'Clientes',
    clientsAdministration: 'Administração de Clientes',
    organizations: 'Organizações',
    organizationsAdministration: 'Administração de Organizações',
    saasAdmin: 'Admin. Saas',
    administrators: 'Administradores',
    saasAdminAdministration: 'Administração de Admin. Saas',
    statistics: 'Estatísticas',
    generalOverview: 'Visão Geral',
    payment: 'Pagamento',
    rebates: 'Descontos',
    pricingRegions: 'Regiões de preços',
    pricingRegionsAdministration: 'Administração de regiões de preços',
    help: 'Ajuda',
    drivings: 'Pedidos de Transporte',
    calendar: 'Calendário',
    tabularView: 'Visualização Tabular',
    expenses: 'Custos',
    vehicleExpensesAdministration: 'Administração de Despesas de Veículos',
    vehicleMaintenance: 'Manutenção de Veículos',
    vehicleMaintenanceAdministration: 'Administração de Manutenção de Veículos',
    organization: 'Organização',
    organizationAdminstration: 'Administração de Organização',
    packages: 'Pacotes',
    packagesChange: 'Alterar Pacotes',
    logout: 'Sair do Sistema',
    logoutText: 'Tem certeza que deseja sair do sistema?',
    unaccessibleRoutes: 'Você não pode acessar esta página. Faça upgrade do pacote para acesso completo.',
    transactions: 'Transações',
    gpsMap: 'Mapa GPS',
    team: 'Equipe',
    teamAdministration: 'Administração da equipe',
    invoices: 'Faturas',
    invoicesAdministration: 'Administração de faturas',
    drivingOffers: 'Ofertas',
    drivingOffersAdministration: 'Administração de ofertas',
    dashboard: 'Painel de controle',
    dashboardAdministration: 'Administração do painel de controle',
    resourceOverview: 'Visão geral dos recursos',
    resourceOverviewAdministration: 'Administração da visão geral dos recursos',
    rides: 'Viagens',
  },
  header: {
    organization: 'Organização',
    newDriving: 'Pedido de Transporte',
    dailyRent: 'Aluguel Diário',
    transfer: 'Transferência',
    notifications: 'Notificações',
    changeLanguage: 'Mudar Idioma',
  },
  footer: {
    poweredBy: 'Desenvolvido por',
    subscriptionTo: 'Sua assinatura para',
    trialEndsAt: 'Seu período de teste para',
    packageExpiresIn: 'pacote expira em',
    clickToChangePackage: 'Clique para mudar de pacote',
    clickToActivatePackage: 'Clique para ativar o pacote',
    clickToPay: 'Clique para pagar',
    packageHasExpired: 'pacote expirou',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Sim',
    no: 'Não',
    save: 'Salvar',
    send: 'Enviar',
    sendMessage: 'Enviar mensagem',
    edit: 'Editar',
    add: 'Adicionar',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    cancelDriving: 'Cancelar Transporte',
    markAsDone: 'Marcar como Concluído',
    markAsNoShow: 'Marcar como não comparecimento',
    deleteDriving: 'Apagar condução',
    newOrganisation: 'Nova Organização',
    changePackage: 'Alterar Pacotes',
    downloadStats: 'Baixar Estatísticas',
    downloadPDF: 'Baixar PDF',
    today: 'Hoje',
    choose: 'Escolher',
    login: 'Entrar',
    generateFile: 'Gerar Contrato',
    generateDrivingOrder: 'Imprimir Pedido de Transporte',
    downloadFile: 'Baixar Arquivo',
    activate: 'Ativar',
    deactivate: 'Desativar',
    showImage: 'Mostrar Imagem',
    showDetails: 'Mostrar Detalhes',
    actions: 'Ações',
    delete: 'Excluir',
    open: 'Abrir',
    show: 'Mostrar',
    options: 'Opções',
    prev: 'Anterior',
    next: 'Próximo',
    skip: 'Pular',
    finish: 'Concluir',
    settings: 'Configurações',
    cancelSubscription: 'Cancelar assinatura',
    backToEditOrganization: 'Voltar para editar organização',
    payNow: 'Pague agora',
    back: 'Voltar',
    integrationRoutes: 'Rotas de integração',
    transactions: 'Transações',
    articlesOfLaw: 'Artigos de lei',
    export: 'Exportar',
    makeReturnTrip: 'Fazer uma viagem de regresso',
    selectOrganisation: 'Selecionar organização',
    advancedSettings: 'Configurações avançadas',
    accept: 'Aceitar',
    reject: 'Rejeitar',
    sendViaGnet: 'Enviar via GNET',
    printInvoice: 'Imprimir fatura',
    enable: 'Ativar',
    disable: 'Desativar',
    generatePaymentLink: 'Gerar link de pagamento',
    addItem: '+ Adicionar item',
    visitWebsite: 'Visite nosso site',
    byMoreSms: 'Compre mais SMS',
  },
  tooltips: {
    save: 'Salvar',
    edit: 'Editar',
    delete: 'Excluir',
    showImage: 'Mostrar Imagem',
    open: 'Abrir',
    show: 'Mostrar',
    showDetails: 'Mostrar Detalhes',
    downloadFile: 'Baixar Arquivo',
    generateFile: 'Gerar Contrato',
    generateDrivingOrder: 'Imprimir Pedido de Transporte',
    activate: 'Ativar',
    deactivate: 'Desativar',
    cancel: 'Cancelar',
    cancelDriving: 'Cancelar Transporte',
    cannotMarkAsCanceled: 'O pedido não pode ser marcado como cancelado porque já foi aceito',
    markAsDone: 'Marcar como Concluído',
    cannotMarkAsDone: 'O pedido não pode ser marcado como concluído, porque ainda não ocorreu',
    markAsNoShow: 'Marcar como não comparecimento',
    cannotMarkAsNoShow: 'O pedido não pode ser marcado como não comparecimento, pois ainda não ocorreu',
    deleteDriving: 'Apagar condução',
    cannotDelete:
      'Lamentamos, mas por motivos de segurança, os usuários não podem ser excluídos enquanto estiverem conectados.',
    duplicate: 'Fazer uma cópia',
    makeReturnTrip: 'Fazer uma viagem de regresso',
    refresh: 'Atualizar',
    selectOrganisation: 'Selecionar organização',
    sendViaGnet: 'Enviar via GNET',
    receivedViaGnet: 'Recebido via GNET',
    sentViaGnet: 'Enviado via GNET',
    printInvoice: 'Imprimir fatura',

    optionsDisabledGnet: 'Você deve rejeitar ou aceitar a ordem para acessar o menu de opções',
    cannotMakeReturnTrip: 'Você não pode fazer uma viagem de retorno para aluguel diário',
    cannotSendGnetOrderViaGnet: 'Você não pode enviar a ordem via gnet',
    cannotSendViaGnetWithoutGnetId: 'Você não pode enviar a ordem via gnet sem ID gnet',
    cannotPrintInvoiceWithoutClient: 'Você não pode imprimir a fatura sem escolher um cliente',
    cannotMarkAsDoneStatusRejected: 'A ordem não pode ser marcada como concluída porque já foi marcada como rejeitada',
    cannotMarkAsDoneStatusCanceled: 'A ordem não pode ser marcada como concluída porque já foi marcada como cancelada',
    cannotMarkAsDoneStatusDone: 'A ordem não pode ser marcada como concluída porque já foi marcada como concluída',
    cannotMarkAsDoneStatusNoShow:
      'A ordem não pode ser marcada como concluída porque já foi marcada como não comparecimento',
    cannotMarkAsNoShowStatusRejected:
      'A ordem não pode ser marcada como não comparecimento porque já foi marcada como rejeitada',
    cannotMarkAsNoShowStatusCanceled:
      'A ordem não pode ser marcada como não comparecimento porque já foi marcada como cancelada',
    cannotMarkAsNoShowStatusDone:
      'A ordem não pode ser marcada como não comparecimento porque já foi marcada como concluída',
    cannotMarkAsNoShowStatusNoShow:
      'A ordem não pode ser marcada como não comparecimento porque já foi marcada como não comparecimento',
    cannotMarkAsCanceledGnet: 'A ordem não pode ser marcada como cancelada porque foi recebida via gnet',
    cannotMarkAsCanceledStatusRejected:
      'A ordem não pode ser marcada como cancelada porque já foi marcada como rejeitada',
    cannotMarkAsCanceledStatusExpired:
      'A ordem não pode ser marcada como cancelada porque já foi marcada como expirada',
    cannotMarkAsCanceledStatusCanceled:
      'A ordem não pode ser marcada como cancelada porque já foi marcada como cancelada',
    cannotMarkAsCanceledStatusDraft: 'A ordem não pode ser marcada como cancelada porque já foi marcada como rascunho',
    cannotMarkAsCanceledStatusCompleted:
      'A ordem não pode ser marcada como cancelada porque já foi marcada como concluída',
    cannotMarkAsCanceledStatusNoShow:
      'A ordem não pode ser marcada como cancelada porque já foi marcada como não comparecimento',
    cannotDeleteDrivingSentViaGnet: 'A ordem não pode ser excluída porque foi enviada via gnet',
    cannotDeleteDrivingReceivedViaGnet: 'A ordem não pode ser excluída porque foi recebida via gnet e aceita',
    generatePaymentLink: 'Gerar link de pagamento',
    cannotGeneratePaymentLInk: 'O link de pagamento não pode ser gerado porque você não ativou o Stripe',
  },
  snackbar: {
    close: 'Fechar',
    savedSuccessfully: 'Salvo com sucesso',
    excelDownloadedSuccessfully: 'Baixado com sucesso',
    deletedSuccessfully: 'Excluído com sucesso',
    sentSuccessfully: 'Enviado com sucesso',
    activeStatusChanged: 'Status ativo alterado com sucesso',
    defaultStatusChanged: 'Status padrão alterado com sucesso',
    primaryStatusChanged: 'Status principal alterado com sucesso',
    anErrorOccured: 'Ocorreu um erro, informe o suporte',
    loadingCommentsFailed: 'Falha ao carregar comentários',
    drivingCanceled: 'Pedido de transporte cancelado com sucesso',
    drivingFinished: 'Pedido de transporte concluído com sucesso',
    drivingNoShow: 'A ordem de condução foi marcada com sucesso como não comparecimento.',
    drivingDeleted: 'a ordem de condução foi excluída com sucesso',
    drivingAccepted: 'Ordem de condução foi aceite com sucesso',
    drivingRejected: 'Ordem de condução foi rejeitada com sucesso',
    planChanged: 'Plano alterado com sucesso',
    planChangeFailed: 'Não é possível alterar o plano',
    saveChangesFirst: 'Primeiro salve as alterações feitas anteriormente',
    makeRoutesPublic: 'Rotas são publicamente acessíveis',
    makeRoutesNotPublic: 'Rotas não são mais publicamente acessíveis',
    makePublicLinkActive: 'O link público agora está disponível',
    makePublicLinkInactive: 'O link público não está mais disponível',
    integrationKeyCopied: 'Chave de integração copiada',
    copyToken: 'Copie manualmente o token',
    publicLinkCopied: 'Link público copiado',
    copyPublicLink: 'Copie manualmente o link público',
    organisationChanged: 'Empresa alterada com sucesso',
    changeFailed: 'A alteração falhou, tente novamente',
    loginFailed: 'Nome de usuário ou senha incorretos',
    organisationSuspended: 'Sua organização está suspensa',
    userEmailDoesNotExists: 'Usuário com este e-mail não existe',
    canceledSubscrSuccessfully: 'Assinatura cancelada com sucesso',
    toManyRequests: 'Muitos pedidos. Tente novamente em um minuto.',
    visibleStatusChanged: 'Estado visível alterado com sucesso',
    noInternetConnection: 'Sem internet. Por favor, verifique sua conexão com a internet.',
    backOnline: 'Você está de volta online.',
    driverNotifiedToTurnGpsOn: 'Motorista notificado para ligar o GPS',
    savedSuccessfullyWithGnetError: 'A sincronização com o GNET falhou, a ordem de condução foi salva com sucesso.',
    logoutFromAnotherDevice: 'Você foi desconectado de outro dispositivo.',
    stripeEnabled: 'Stripe ativado com sucesso',
    stripeDisabled: 'Stripe desativado com sucesso',
    finishStripeConnect: 'Por favor, termine a configuração do Stripe',
  },
  filters: {
    filter: 'Filtrar',
    currentYear: 'Ano Atual',
    lastYear: 'Ano Passado',
    lastQuarter: 'Último Trimestre',
    lastThreeYears: 'Últimos Três Anos',
    filterByDriver: 'Filtrar por Motorista',
    filterByVehicle: 'Filtrar por Veículo',
    filterByClient: 'Filtrar por clientes',
    day: 'Dia',
    week: 'Semana',
    month: 'Mês',
    vehicle: 'Veículo',
    driving: 'Condução',
    user: 'Usuário',
    fromDate: 'Data Inicial',
    toDate: 'Data Final',
    threeMonths: 'Três meses',
    halfYear: 'Meio ano',
    year: 'Ano',
  },
  months: {
    jan: 'Janeiro',
    feb: 'Fevereiro',
    mar: 'Março',
    apr: 'Abril',
    may: 'Maio',
    jun: 'Junho',
    jul: 'Julho',
    aug: 'Agosto',
    sep: 'Setembro',
    oct: 'Outubro',
    nov: 'Novembro',
    dec: 'Dezembro',
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro',
  },
  usersPageHeaders: {
    firstName: 'Primeiro nome',
    lastName: 'Sobrenome',
    username: 'Nome de usuário',
    email: 'Email',
    phoneNumber: 'Número de telefone',
    role: 'Função',
    active: 'Ativo',
    actions: 'Ações',
  },
  usersRoles: {
    masterAdmin: 'Administrador Master',
    manager: 'Gerente',
    driver: 'Motorista',
    operator: 'Operador',
    externalAssociate: 'Associado Externo',
    sales: 'Vendas',
    salesLead: 'Líder de Vendas',
    gnet: 'GNET',
    customer: 'Usuário do portal',
  },
  masterUsersPageHeaders: {
    firstName: 'Primeiro nome',
    lastName: 'Sobrenome',
    username: 'Nome de usuário',
    email: 'Email',
    phoneNumber: 'Número de telefone',
    role: 'Função',
    organisation: 'Organização',
    active: 'Ativo',
    actions: 'Ações',
  },
  usersPage: {
    newItem: 'Novo usuário',
    editItem: 'Editar Usuário',
    id: 'Id',
    firstName: 'Primeiro nome',
    lastName: 'Sobrenome',
    username: 'Nome de usuário',
    password: 'Senha',
    phoneNumber: 'Número de telefone',
    role: 'Função',
    client: 'Cliente',
    email: 'Email',
    organisation: 'Organização',
    percentage: 'Porcentagem',
    externalAssociate: 'Associado Externo',
    image: 'Imagem',
    deleteUser: 'Excluir usuário',
    deleteMessage: 'Tem certeza de que deseja excluir o usuário ',
    emailChanged: 'Alteração de Email',
    verificationEmailSent:
      'A solicitação de mudança de endereço de email foi enviada. Para concluir o processo e garantir a segurança da conta do usuário, o novo endereço de email deve ser verificado clicando no link de verificação enviado para o novo email. O endereço de email não será alterado até que seja verificado com sucesso.',
  },
  clientsPageHeaders: {
    name: 'Nome',
    phoneNumber: 'Número de telefone',
    address: 'Endereço',
    email: 'Email',
    companyId: 'ID da Empresa/ID pessoal',
    clientType: 'Tipo de cliente',
    percentage: 'Porcentagem',
    active: 'Ativo',
    actions: 'Ações',
  },
  clientsPage: {
    newItem: 'Novo cliente',
    editItem: 'Editar Cliente',
    id: 'Id',
    name: 'Nome',
    address: 'Endereço',
    phoneNumber: 'Número de telefone',
    email: 'Email',
    companyId: 'ID da Empresa/ID pessoal',
    clientType: 'Tipo de cliente',
    individual: 'Pessoa física',
    business: 'Entidade empresarial',
    percentage: 'Porcentagem',
    deleteClient: 'Excluir cliente',
    deleteMessage: 'Tem certeza de que deseja excluir o cliente ',
    tabs: {
      general: 'Geral',
      transfers: 'Transferências',
      users: 'Usuários',
      invoices: 'Faturas',
      cards: 'Cartões',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Número do Pedido de Transporte',
    drivingType: 'Tipo de Pedido de Transporte',
    drivingStatus: 'Status',
    locationFrom: 'Ponto de Partida',
    locationTo: 'Destino',
    vehicleClass: 'Classe do Veículo',
    vehicle: 'Veículo',
    driversName: 'Motorista',
    startTime: 'Horário de Retirada',
    actions: 'Ações',
    exportExcel: 'Exportar Excel',
    price: 'Preço',
    totalPrice: 'Preço total',
    confirmed: 'Confirmado',
    paid: 'Pago',
    numberOfDrivings: 'Número de ordens de condução',
  },
  vehiclesHeaders: {
    plateNumber: 'Número de Registro',
    brand: 'Marca do Veículo',
    vehicleClass: 'Classe do Veículo',
    passengersNumber: 'Número de Passageiros',
    fuelConsumption: 'Consumo de Combustível do Carro',
    yearOfManufacture: 'Ano de Fabricação',
    pricePerKm: 'Preço por Km',
    pricePerMi: 'Preço por milha',
    pricePerHour: 'Preço por Hora',
    pricePerWaitingHour: 'Preço por Hora de Espera',
    active: 'Ativo',
    actions: 'Ações',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'Novo Veículo',
    editItem: 'Alterar Veículo',
    vehiclePlates: 'Placas do Veículo',
    vehicleBrand: 'Marca do Veículo',
    vehicleClass: 'Classe do Veículo',
    passengersNumber: 'Número de Passageiros',
    fuelConsumption: 'Consumo de Combustível do Carro (100km)',
    fuelConsumptionMi: 'Consumo de combustível do carro (100 milhas)',
    manufactureYear: 'Ano de Fabricação',
    price: 'Preço por Km',
    priceMi: 'Preço por milha',
    hourPrice: 'Preço por Hora',
    image: 'Imagem',
    hourWaitingPrice: 'Preço por Hora de Espera',
    deleteMessage: 'Tem certeza de que deseja excluir este veículo?',
    tabs: {
      general: 'Geral',
      transfers: 'Transferências',
      costs: 'Custos',
      damages: 'Danos',
    },
    licence: 'Número da licença',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Classe de Veículo',
    active: 'Ativo',
    actions: 'Ações',
  },
  vehicleClassesPage: {
    newItem: 'Nova classe de veículo',
    editItem: 'Alterar classe de veículo',
    id: 'Id',
    vehicleClass: 'Classe de Veículo',
    hourWaitingPrice: 'Preço por hora de espera',
    deleteMessage: 'Tem certeza de que deseja excluir esta classe de veículo?',
  },
  expensesHeaders: {
    name: 'Nome',
    vehicle: 'Veículo',
    type: 'Tipo',
    description: 'Descrição',
    price: 'Preço',
    expenseDate: 'Data',
    actions: 'Ação',
    driving: 'Condução',
    exportExcel: 'Exportar Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Serviço Menor',
    majorService: 'Serviço Maior',
    fuel: 'Combustível',
    tires: 'Pneus',
    parking: 'Estacionamento',
    other: 'Outros',
  },
  expensesPage: {
    newItem: 'Novo registro de custo',
    editItem: 'Alterar custo',
    id: 'Id',
    name: 'Nome',
    vehicle: 'Veículo',
    costType: 'Tipo de Custo',
    price: 'Preço',
    currency: 'Moeda',
    costTime: 'Tempo do Custo',
    description: 'Descrição',
    deleteMessage: 'Tem certeza de que deseja excluir este custo?',
    costTypes: {
      'Mali servis': 'Serviço Menor',
      'Veliki servis': 'Serviço Maior',
      Gorivo: 'Combustível',
      Gume: 'Pneus',
      Parking: 'Estacionamento',
      Ostalo: 'Outros',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Veículo',
    details: 'Detalhes',
    dateFrom: 'Data de Início',
    dateTo: 'Data de Término',
    actions: 'Ações',
  },
  vehicleMaintenancePage: {
    newItem: 'Novo registro de manutenção do veículo',
    editItem: 'Alterar manutenção do veículo',
    id: 'Id',
    vehicle: 'Veículo',
    description: 'Descrição',
    dateFrom: 'De',
    dateTo: 'Para',
    deleteMessage: 'Tem certeza de que deseja excluir esta manutenção do veículo?',
  },
  rebatesHeaders: {
    min: 'Número Mínimo de Pedidos de Transporte',
    max: 'Número Máximo de Pedidos de Transporte',
    percentage: 'Desconto (%)',
    actions: 'Ações',
  },
  rebatesPage: {
    newItem: 'Novo registro de desconto',
    editItem: 'Alterar desconto',
    rebateMin: 'Número Mínimo de Pedidos de Transporte',
    rebateMax: 'Número Máximo de Pedidos de Transporte',
    rebate: 'Desconto (%)',
    deleteRebate: 'Eliminar desconto',
    deleteMessage: 'Tem certeza de que deseja excluir este desconto?',
  },
  customerCardsHeaders: {
    cardholderName: 'Nome do titular do cartão',
    cardNumber: 'Número do cartão',
    expiryDate: 'Data de validade',
    cvv: 'CVV',
    actions: 'Ações',
  },
  customerCardsPage: {
    newItem: 'Nova entrada de cartão',
    editItem: 'Alteração do cartão',
    cardholderName: 'Nome do titular do cartão',
    cardNumber: 'Número do cartão',
    expiryDate: 'Data de validade',
    cvv: 'CVV',
    deleteCard: 'Excluir cartão',
    deleteMessage: 'Tem certeza de que deseja excluir este cartão?',
  },
  pricingRegionsHeaders: {
    name: 'Nome',
    actions: 'Ações',
  },
  pricingRegionsPage: {
    newItem: 'Nova entrada de região de preços',
    editItem: 'Alteração de região de preços',
    name: 'Nome',
    chooseCurrency: 'Escolha a moeda',
    team10Price: 'Preço da equipa 10 - Ano',
    team10PaddlePriceId: 'ID de preço paddle da equipa 10 - Ano',
    team25Price: 'Preço da equipa 25 - Ano',
    team25PaddlePriceId: 'ID de preço paddle da equipa 25 - Ano',
    team50Price: 'Preço da equipa 50 - Ano',
    team50PaddlePriceId: 'ID de preço paddle da equipa 50 - Ano',
    team10PriceMonthly: 'Preço da equipa 10 - Mês',
    team10PaddlePriceIdMonthly: 'ID de preço paddle da equipa 10 - Mês',
    team25PriceMonthly: 'Preço da equipa 25 - Mês',
    team25PaddlePriceIdMonthly: 'ID de preço paddle da equipa 25 - Mês',
    team50PriceMonthly: 'Preço da equipa 50 - Mês',
    team50PaddlePriceIdMonthly: 'ID de preço paddle da equipa 50 - Mês',
    chooseCountries: 'Escolha os países',
    description: 'Descrição',
    deletePricingRegion: 'Eliminar região de preços',
    deleteMessage: 'Tem certeza de que deseja excluir esta região de preços?',
  },
  notificationsHeaders: {
    time: 'Hora',
    notification: 'Notificação',
  },
  organisationsHeaders: {
    name: 'Organização',
    phoneNumber: 'Número de telefone',
    email: 'Email',
    address: 'Endereço',
    companyId: 'ID da Empresa',
    vat: 'NIF',
    active: 'Ativo',
    createdAt: 'Registrado',
    actions: 'Ações',
    billingDate: 'Data de faturamento',
  },
  organisationsPage: {
    newItem: 'Nova entrada de organização',
    editItem: 'Editar organização',
    id: 'Id',
    name: 'Organização',
    address: 'Endereço',
    phoneNumber: 'Número de telefone',
    email: 'Email',
    vat: 'NIF',
    companyId: 'ID da Empresa',
    warrantNumber: 'Número da Decisão',
    image: 'Imagem',
    signature: 'Assinatura',
    seal: 'Selo',
    deactivateMessage: 'Tem certeza de que deseja ',
    deactivate: 'DESATIVAR',
    activate: 'ATIVAR',
    organisation: 'organização',
    deleteOrganisation: 'Excluir organização',
    deleteMessage: 'Tem certeza de que deseja excluir esta organização?',
    cancelSubscrMessage:
      'Tem certeza de que deseja cancelar sua assinatura? Esteja ciente de que ao confirmar esta ação: 1. A conta da sua organização e todos os dados associados serão permanentemente excluídos. Esta ação não pode ser desfeita. 2. Você será automaticamente desconectado do sistema imediatamente após o cancelamento. 3. Você não terá mais acesso para fazer login ou recuperar quaisquer dados relacionados à sua organização no futuro. Recomendamos fortemente que você revise todas as informações necessárias ou exporte dados críticos antes de prosseguir com esta ação irreversível. Se você tiver alguma dúvida ou precisar de assistência, não hesite em contatar nossa equipe de suporte. Deseja prosseguir com o cancelamento?',
    allDrivings: 'Total de pedidos de transporte',
    allDrivingsAccepted: 'Total de pedidos de transporte aceitos',
    allDrivingsCanceled: 'Total de pedidos de transporte rejeitados',
    allVehicles: 'Total de veículos',
    allUsers: 'Total de usuários',
    areYouSure: 'Tem certeza?',
    makeRoutesAvailable: 'Tornar rotas de integração disponíveis',
    percentage: 'Porcentagem',
    deleteImageMessage: 'Tem certeza de que deseja excluir esta imagem?',
    deleteImageTitle: 'Excluir Imagem',
    cancelSubscriptionTitle: 'Cancelar Assinatura',
    subscriptionType: 'Tipo de assinatura',
    billingDate: 'Data de faturamento',
    transactions: 'Transações',
    articlesOfLaw: 'Artigos de lei',
    country: 'País',
    referent: 'Referente',
    language: 'Idioma',
    gnetId: 'ID GNET',
  },
  transactionsHeaders: {
    invoiceNumber: 'Número da fatura',
    status: 'Status',
    total: 'Total',
    currency: 'Moeda',
    billedAt: 'Faturado em',
    actions: 'Ações',
  },
  transactionsPage: {
    newItem: 'Nova entrada de transação',
    editItem: 'Editar transação',
    id: 'Id',
    invoiceNumber: 'Número da fatura',
    status: 'Status',
    sent: 'Enviado',
    completed: 'Concluído',
    total: 'Total',
    currency: 'Moeda',
    billedAt: 'Faturado em',
    actions: 'Ações',
    deleteTransaction: 'Excluir transação',
    deleteMessage: 'Tem certeza de que deseja excluir esta transação?',
    currentSubscription: 'Pacote atual',
    nextBillingDate: 'Próxima data de cobrança',
    numberOfSeatsTaken: 'Número de membros da equipe ocupados',
    numberOfSeatsAvailable: 'Número de membros da equipe disponíveis',
  },
  currenciesHeaders: {
    name: 'Nome',
    code: 'Código',
    symbol: 'Símbolo',
    active: 'Ativo',
    default: 'Primário',
    actions: 'Ações',
  },
  currenciesPage: {
    newItem: 'Nova entrada de moeda',
    editItem: 'Alterar moeda',
    id: 'Id',
    currency: 'Moeda',
    code: 'Código',
    symbol: 'Símbolo',
    deleteMessage: 'Tem certeza de que deseja excluir esta moeda?',
  },
  paymentMethodsHeaders: {
    name: 'Nome',
    active: 'Ativo',
    default: 'Primário',
    priceVisible: 'Preço visível',
    actions: 'Ações',
  },
  paymentMethodsPage: {
    newItem: 'Nueva entrada de método de pago',
    editItem: 'Cambiar método de pago',
    id: 'Id',
    paymentMethod: 'Método de pago',
    deleteMessage: '¿Estás seguro de que quieres eliminar este método de pago?',
  },
  feedbacksHeaders: {
    subject: 'Nombre',
    organisation: 'Organización',
    user: 'Usuario',
    actions: 'Acciones',
  },
  passengersHeaders: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    phoneNumber: 'Número de telefone',
    email: 'Email',
    passport: 'Pasaporte',
    nationality: 'Nacionalidad',
    actions: 'Acciones',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Localização do ponto de passagem',
    arrivalTime: 'Hora de chegada',
    actions: 'Ações',
  },
  tables: {
    noDataText: 'Sin datos',
    search: 'Buscar',
    drivings: 'Órdenes de manejo',
    drivingsByPage: 'Órdenes de manejo por página',
    vehicles: 'Vehículos',
    vehiclesByPage: 'Vehículos por página',
    vehiclesClasses: 'Clases de vehículos',
    vehiclesClassesByPage: 'Clases de vehículos por página',
    expenses: 'Custos',
    expensesByPage: 'Custos por página',
    vehiclesMaintenance: 'Averías de vehículos',
    vehiclesMaintenanceByPage: 'Averías de vehículos por página',
    reportsByPage: 'Informes por página',
    currencies: 'Monedas',
    currencieseByPage: 'Monedas por página',
    payments: 'Métodos de pago',
    paymentsByPage: 'Métodos de pago por página',
    saasAdministrators: 'Todos los administradores',
    users: 'Todos los usuarios',
    usersByPage: 'Usuarios por página',
    clients: 'Todos los clientes',
    clientsByPage: 'Clientes por página',
    organisations: 'Todas las organizaciones',
    organisationsByPage: 'Organizaciones por página',
    rebates: 'Descuentos',
    rebatesByPage: 'Descuentos por página',
    cards: 'Cartões',
    cardsByPage: 'Cartões por página',
    transactions: 'Transações',
    transactionsByPage: 'Transações por página',
    pricingRegions: 'Regiões de Preços',
    pricingRegionsByPage: 'Regiões de Preços por página',
    feedback: 'Comentarios',
    feedbacksByPage: 'Comentarios por página',
    notifications: 'Notificaciones',
    notificationsByPage: 'Notificaciones por página',
    all: 'Todos',
    passengers: 'Pasajeros',
    passengersByPage: 'Pasajeros por página',
    of: 'de',
    checkpoints: 'Pontos de passagem',
    checkpointsByPage: 'Pontos de passagem por página',
    invoices: 'Faturas',
    invoicesByPage: 'Faturas por página',
    drivingOffers: 'Ofertas de condução',
    drivingOffersByPage: 'Ofertas de condução por página',
    smsMessages: 'Mensagens SMS',
    smsMessagesByPage: 'Mensagens SMS por página',
    resourcesUsed: 'Recursos utilizados',
    resourcesUsedByPage: 'Recursos por página',
    inNext3Days: 'Nos próximos 3 dias',
    notConfirmedOrPaid: 'Não confirmado ou pago',
    inNext5Days: 'Nos próximos 5 dias',
    latestReviews: 'Últimas avaliações',
    driversByPage: 'Motoristas por página',
    reviewsByPage: 'Avaliações por página',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Número de órdenes de manejo aceptadas',
    numberOfRejectedDrivings: 'Número de órdenes de manejo rechazadas por conductor',
    vehicleDrivingsNumber: 'Número de órdenes de manejo por vehículo',
    vehicleProfit: 'Ganancias por vehículo',
    monthDrivingsNumber: 'Número de órdenes de manejo por mes',
    driverProfit: 'Ganancias por conductor',
  },
  reportsHeaders: {
    vehicle: 'Vehículo',
    expenses: 'Gastos',
    profit: 'Ganancia',
    earnings: 'Ingresos',
    daysWorking: 'Días de trabajo',
    usageIndex: 'Índice de uso',
    client: 'Cliente',
    numberOfDrivings: 'Número de Conduções',
    percentForClient: 'Preço para o cliente',
    sumOfPrice: 'Quantia',
  },
  packages: {
    active: 'Activo',
    starterTitle: 'Inicial',
    monthlyPackagesMessage: 'Suscripción mensual por usuario.',
    starterText: 'Ideal para individuos o pequeños equipos que solo necesitan funcionalidades básicas.',
    starterListItemOne: 'Aplicaciones móviles',
    starterListItemTwo: 'Notificaciones push',
    starterListItemThree: 'Acceso a la administración',
    starterListItemFour: 'Integración en el sitio web',
    standardTitle: 'Estándar',
    standardText: 'Perfecto para organizaciones con más de 5 conductores.',
    standardListItemOne: 'Incluye paquete INICIAL',
    standardListItemTwo: 'No hay límite en el número de conductores',
    standardListItemThree: '99.9% de tiempo de actividad',
    standardListItemFour: 'Informes y gestión de vehículos',
    premiumTitle: 'Premium',
    premiumText: 'Perfecto para organizaciones de servicio de limusina que operan con datos precisos.',
    premiumListItemOne: 'Incluye paquete ESTÁNDAR',
    premiumListItemTwo: 'BI y Pronóstico de Costos',
    premiumListItemThree: 'Exportación de datos',
    premiumListItemFour: 'Informes semanales y mensuales',
    warningOne: '* Los precios mostrados son suscripciones mensuales por usuario',
    warningTwo:
      '* Después de cambiar a un nuevo paquete, no es posible cambiar a un paquete más pequeño durante los siguientes 6 meses',
    warningThree:
      '* Los usuarios del plan de demostración tienen una visión de las funcionalidades proporcionadas por el paquete premium',
    warningOneAnnualPackage:
      '¿Necesita más? ¿Tiene una organización más grande? Tenemos un paquete EMPRESARIAL para usted.',
    warningTwoAnnualPackage: 'y nuestro equipo de ventas se pondrá en contacto con usted en breve.',
    packageChange: 'Cambiar paquete',
    changePackage: '¿Estás seguro de que quieres cambiar a',
    package: 'paquete',
    annualPricing: 'Preços anuais',
    monthlyPricing: 'Preços mensais',
    annualPackageType10: 'Equipo 10',
    annualPackageType25: 'Equipo 25',
    annualPackageType50: 'Equipo 50',
    'Team 10': 'Equipo 10',
    'Team 25': 'Equipo 25',
    'Team 50': 'Equipo 50',
    annualPackagesMessage: 'Suscripción anual por organización.',
    annualPackageType10MainMessage: 'Hasta 10 cuentas de usuario.',
    annualPackageType25MainMessage: 'Hasta 25 cuentas de usuario.',
    annualPackageType50MainMessage: 'Hasta 50 cuentas de usuario.',
    annualPackageListItemOne: 'Paquete Estándar',
    annualPackageListItemTwo: 'Pago único',
    annualPackageListItemThree: 'Beneficios de precio',
    annualPackageType10Discount: '- hasta un 65% de descuento',
    annualPackageType25Discount: '- hasta un 68% de descuento',
    annualPackageType50Discount: '- hasta un 65% de descuento',
    allFeaturesIncluded: 'Todos os recursos incluídos',
    trialPeriodIncluded: 'Período de teste incluído',
    unlimitedVehicles: 'Veículos ilimitados',
    unlimitedBookings: 'Reservas ilimitadas',
    onetimePaymentAnnually: 'Pagamento único anualmente',
  },
  successPaymentPage: {
    message: 'Pagamento bem-sucedido. Obrigado.',
  },
  demoExpiring: {
    notification: 'Notificação',
    expiredSubscriptionMessage:
      'Informamos que sua assinatura para esta plataforma expirou. Para continuar a usar nossos serviços, por favor, renove sua assinatura.',
  },
  login: {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    rememberMe: 'Recordarme',
    login: 'Iniciar sesión',
    noAccount: '¿Todavía no tienes una cuenta? Puedes registrarte',
    forgetPassword: '¿Olvidaste tu contraseña? Puedes restablecerla',
    registerHere: 'aquí',
    email: 'Correo electrónico',
    enterEmail: 'Por favor, ingrese su correo electrónico',
    enterPassword: 'Por favor, ingrese su nueva contraseña',
    signInWithGoogle: 'Iniciar sesión con Google',
  },
  registration: {
    register: 'Registrar',
    name: 'Nome da Organização',
    address: 'Endereço',
    phoneNumber: 'Número de Telefone',
    email: 'E-mail',
    companyId: 'Identificação da Empresa',
    vat: 'NIF',
    language: 'Idioma',
    package: 'Pacote',
    accept: 'Eu aceito',
    terms: 'os termos de uso',
    successfullyRegistered: 'O usuário foi registrado com sucesso. Você receberá um e-mail com um link de verificação.',
    haveAccount: 'Já tem uma conta? Pode fazer login',
    loginHere: 'Aqui',
    trialPeriodInfo:
      'Incluído é um período de avaliação gratuito de 14 dias. A faturação seguirá após o término do período de avaliação.',
  },
  driving: {
    drivingType: 'Tipo de pedido',
    general: 'Informações gerais',
    newDriving: 'Programar un traslado',
    newDailyRent: 'Programar un alquiler diario',
    areYouSure: '¿Estás seguro?',
    vehicleIsUnavailable: 'El vehículo está ocupado.',
    vehicleIsDamaged: 'El vehículo está dañado.',
    sureAboutThisVehicle: '¿Estás seguro de que quieres elegir este vehículo?',
    driverUnavaliable: 'El conductor está ocupado.',
    sureAboutThisDriver: '¿Estás seguro de que quieres seleccionar a este conductor?',
    checkVehicleSeatAvailability: 'Este vehículo no puede llevar a tantos pasajeros.',
    cancelDriving: '¿Estás seguro de que quieres cancelar la orden de manejo?',
    finishDriving: '¿Estás seguro de que quieres finalizar la orden de manejo?',
    noShowDriving: 'Tem certeza de que deseja marcar o pedido de condução como não comparecimento?',
    deleteDriving: '¿Estás seguro de que quieres excluir la orden de manejo?',
    newTransfer: 'Nuevo traslado',
    driving: 'Orden de manejo',
    comments: 'Comentarios',
    id: 'Id',
    locationFrom: 'Punto de partida',
    locationTo: 'Destino',
    vehicleClass: 'Clase de vehículo',
    vehicle: 'Vehículo',
    driver: 'Conductor',
    pickupTime: 'Hora de recogida',
    clientConfirmed: 'Cliente confirmado',
    description: 'Descripción',
    extraInfo: 'Información adicional',
    drivingNotAccepted: 'No aceptaron la orden de manejo:',
    currency: 'Moneda',
    paymentMethod: 'Métodos de pago',
    price: 'Precio',
    paid: 'Pagado',
    distance: 'Distancia (km)',
    distanceMi: 'Distância (milhas)',
    expectedTime: 'Duración esperada',
    waitingTime: 'Tiempo de espera (h)',
    EnterTheNumberOfHoursWaiting: 'Ingresa el número de horas de espera',
    dropOffTime: 'Hora de llegada',
    comebackTime: 'Hora de regreso',
    acceptUntil: 'Aceptar hasta',
    drivingRequired: '¿Es obligatoria la orden de manejo?',
    comment: 'Comentario',
    attachments: 'Adjuntos',
    addFiles: '+ Agregar archivos',
    deleteFiles: '¿Estás seguro de que quieres eliminar este archivo?',
    passengers: 'Pasajeros',
    addPassenger: '+ Agregar pasajero',
    deletePassenger: '¿Estás seguro de que quieres eliminar este pasajero?',
    chooseClient: 'Elegir cliente',
    costs: 'Costos',
    checkpoints: 'Pontos de passagem',
    addCheckpoint: 'Adicionar ponto de passagem',
    deleteCheckpoint: 'Tem certeza de que deseja excluir este ponto de passagem?',
    waitingBoardText: 'Texto de espera no quadro',
    flightNumber: 'Número do voo',
    flightInfo: 'Informações do voo',
    createdBy: 'Ordem de condução criada às <b>{time}</b> por <b>{user}</b>',
    client: 'Cliente',
    gnet: 'GNET',
    note: 'Nota',
    noteDriver: 'Nota para o motorista',
    noteDispatcher: 'Nota para o despachante',
    passenger: 'Passageiro',
    fullName: 'Nome completo',
    phoneNumber: 'Número de telefone',
    email: 'Email',
    suitcasesNumber: 'Número de malas',
    passengersNumber: 'Número de passageiros',
    babySeatsNumber: 'Número de assentos para bebês',
    stopsNumber: 'Número de paradas',
    steps: {
      mainInfo: 'Informações principais',
      additionalInfo: 'Informações adicionais',
      review: 'Revisão',
    },
    commissionAmount: 'Comissão',
  },
  drivingStatus: {
    pending: 'Pendiente',
    accepted: 'Aceptado',
    rejected: 'Rechazado',
    expired: 'Caducado',
    canceled: 'Cancelado',
    draft: 'Borrador',
    done: 'Completado',
    noShow: 'Não comparecimento',
  },
  drivingOrderType: {
    dailyRent: 'Alquiler diario',
    transfer: 'Traslado',
  },
  flightInfo: {
    airlineName: 'Nome da companhia aérea',
    flightNumber: 'Número do voo',
    departureAirport: 'Aeroporto de partida',
    departureCountry: 'País de partida',
    departureTime: 'Hora de partida',
    arrivalAirport: 'Aeroporto de chegada',
    arrivalCountry: 'País de chegada',
    arrivalTime: 'Hora de chegada',
    departureDelayed: 'Atraso na partida',
    flightStatus: 'Estado do voo',
    percentageOfFlight: 'Percentagem do voo',
  },
  customValidations: {
    fileType: 'El tipo de archivo .{extension} no es compatible',
    fileSize: 'Los archivos no pueden ser más grandes que {size}',
    fieldRequired: 'El campo {field} es obligatorio',
    fieldType: 'El campo {field} debe ser de tipo {type}',
    fieldLengthMin: 'El campo {field} debe tener {length} o más',
    fieldLengthMax: 'El campo {field} debe tener {length} o menos',
    fieldUnique: 'El campo {field} debe ser único',
    fieldValid: 'O campo {field} deve ser válido',
    noActiveSubscription: 'No se encontró una suscripción activa',
    usersLimitExceeded: 'Se alcanzó el límite de usuarios',
    fieldBefore: '{field1} deve ser antes de {field2}',
    flightNotExist: 'O voo {field} não existe',
    gnetKeyNotValid: 'A chave Gnet fornecida não é válida.',
    gnetPassengersRequired: 'A ordem de condução não pode ser enviada para a GNET sem passageiros.',
    priceAndCurrencyRequired: 'O link de pagamento não pode ser gerado sem preço e moeda',
    fieldValueNotSupported: 'O valor do campo {field} não é suportado',
    noCountryCode: 'Você não selecionou um país',
    noStripeForYourCountry: 'Stripe não está disponível no seu país',
    stripeAlreadyEnabled: 'Stripe já está ativado',
    fieldValueMin: 'O campo {field} deve ser {min} ou mais',
    fieldValueMax: 'O campo {field} deve ser {max} ou menos',
    markAsDoneDate: 'A hora de recolha não pode estar no futuro',
  },
  fieldTypes: {
    string: 'cadena',
    integer: 'número',
    date: 'fecha',
  },

  names: {
    username: 'nombre de usuario',
    password: 'contraseña',
    choose_client: 'elegir cliente',
    choose_month: 'elegir mes',
    currency: 'moneda',
    payment_method: 'método de pago',
    vehicle_class: 'clase de vehículo',
    first_name: 'nombre',
    last_name: 'apellido',
    passport: 'pasaporte',
    nationality: 'nacionalidad',
    plate_number: 'número de placa',
    brand: 'marca de automóvil',
    number_of_passengers: 'número de pasajeros',
    fuel_consumption: 'consumo de combustible',
    year_of_manufacture: 'año de fabricación',
    price_per_km: 'precio por km',
    price_per_mi: 'preço por milha',
    price_per_hour: 'precio por hora',
    price_per_waiting_hour: 'precio por hora de espera',
    vehicle: 'vehículo',
    description: 'descripción',
    time_from: 'hora desde',
    time_to: 'hora hasta',
    name: 'nombre',
    cost_type: 'tipo de costo',
    cost_time: 'tiempo de costo',
    price: 'precio',
    rebate_min: 'órdenes mínimas de manejo',
    rebate_max: 'órdenes máximas de manejo',
    rebate: 'descuento',
    phone_number: 'número de teléfono',
    organisation: 'organización',
    role: 'rol',
    image: 'imagen',
    location_from: 'ubicación desde',
    location_to: 'ubicación hasta',
    pickup_time: 'hora de recogida',
    distance: 'distancia',
    waiting_time: 'tiempo de espera',
    comment: 'comentario',
    address: 'dirección',
    vat: 'NIF',
    company_id: 'identificación de la empresa',
    company_personal_id: 'id da empresa/id pessoal',
    licence: 'licencia',
    warrant_number: 'número de garantía',
    seal: 'sello',
    signature: 'firma',
    email: 'correo electrónico',
    percentage: 'porcentaje',
    organizationName: 'nome da organização',
    package: 'pacote',
    choose_currency: 'escolha a moeda',
    team_10_price: 'preço da equipe 10',
    team_10_paddle_price_id: 'ID do preço Paddle da equipe 10',
    team_25_price: 'preço da equipe 25',
    team_25_paddle_price_id: 'ID do preço Paddle da equipe 25',
    team_50_price: 'preço da equipe 50',
    team_50_paddle_price_id: 'ID do preço Paddle da equipe 50',
    team_10_price_monthly: 'preço da equipe 10',
    team_10_paddle_price_id_monthly: 'ID do preço Paddle da equipe 10',
    team_25_price_monthly: 'preço da equipe 25',
    team_25_paddle_price_id_monthly: 'ID do preço Paddle da equipe 25',
    team_50_price_monthly: 'preço da equipe 50',
    team_50_paddle_price_id_monthly: 'ID do preço Paddle da equipe 50',
    countries: 'países',
    invoice_number: 'número da fatura',
    status: 'status',
    total: 'total',
    billed_at: 'faturado em',
    articles_of_law: 'artigos de lei',
    country: 'país',
    referent: 'referente',
    language: 'idioma',
    location: 'localização',
    arrival_time: 'hora de chegada',
    waiting_board_text: 'texto de espera no quadro',
    flight_number: 'número do voo',
    gnet_id: 'ID GNET',
    receiver_id: 'ID do receptor',
    preferred_vehicle_type: 'tipo de veículo preferido',
    reservation_type: 'tipo de reserva',
    run_type: 'tipo de corrida',
    trip_duration_minutes: 'duração da viagem',
    client_type: 'tipo de cliente',
    client: 'cliente',
    card_holder_name: 'nome do titular do cartão',
    card_number: 'número do cartão',
    expiry_date: 'data de validade',
    cvv: 'cvv',
    code: 'código',
    symbol: 'símbolo',
    quantity: 'quantidade',
    discount: 'desconto',
    driving_type: 'tipo de condução',
    full_name: 'nome completo',
    suitcases_number: 'número de malas',
    passengers_number: 'número de passageiros',
    baby_seats_number: 'número de assentos para bebês',
    stops_number: 'número de paradas',
    commission_amount: 'comissão',
  },
  welcomeModal: {
    welcome: 'Bienvenido a LimoExpress',
  },
  weekDays: {
    monday: 'lunes',
    tuesday: 'martes',
    wednesday: 'miércoles',
    thursday: 'jueves',
    friday: 'viernes',
    saturday: 'sábado',
    sunday: 'domingo',
  },
  select: {
    noDataAvailable: 'No hay datos disponibles',
  },
  excelExport: {
    to_date_range: 'No se puede generar el informe para fechas futuras. Por favor, ingrese fechas válidas',
    overlap_range: 'La hora de finalización no puede ser mayor que la hora de inicio.',
  },
  setPassword: {
    success: 'Correo electrónico enviado',
    checkEmail: 'Revise su correo electrónico para obtener instrucciones adicionales.',
    already_changed: 'Ya ha realizado un cambio',
    send_new_reset_request:
      'Ya ha cambiado la contraseña con este enlace. Envíe una nueva solicitud de restablecimiento de contraseña.',
  },
  statisticsPage: {
    reportType: 'Tipo de informe',
    reportTime: 'Tiempo del informe',
    vehicleUtilization: 'Utilización del vehículo',
    costEffectiveness: 'Eficiencia de costos',
    generalReport: 'Informe general',
    clientsReport: 'Relatório por Clientes',
  },
  profile: {
    yourProfile: 'Tu perfil',
    name: 'Nombre',
    role: 'Rol',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    percentage: 'Porcentaje',
  },
  gpsMaps: {
    noTransfers: 'Não há transferências em andamento ou próximas.',
    map: 'Mapa',
    transfers: 'Transferências',
    drivers: 'Motoristas',
    ongoing: 'Em andamento',
    upcoming: 'Próximas',
    driving: 'Condução',
    driver: 'Condutor',
    vehicle: 'Veículo',
    requestDriverLocation: 'Solicitar localização do motorista',
    lastRecordedLocation: 'Última localização registrada',
  },
  driverStatus: {
    available: 'Disponível',
    offline: 'Desconectado',
    inTransfer: 'Em transferência',
  },
  onboardingTutorialMain: {
    languageStep: 'Aqui você pode definir seu idioma preferido para o aplicativo.',
    notificationsStep: 'Estas são as suas notificações.',
    addTransferStep:
      'Clique neste botão para criar suas transferências e aluguéis diários. Os aluguéis diários são transferências personalizadas sem destino.',
    sideMenuButtonStep: 'Clique aqui para mostrar ou ocultar a barra de menu.',
    sideMenuExpStep:
      'A partir daqui, você pode navegar para as principais seções do aplicativo: Transferências, Frota, Custos, Administração, Estatísticas e Transações.',
    sideMenuButtonCloseStep: 'Clique novamente no ícone para ocultar a barra de menu.',
    supportStep: 'Se precisar de ajuda com algum problema, não hesite em contactar o Suporte clicando aqui.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Seus veículos serão listados aqui em uma tabela, depois de adicioná-los.',
    searchStep: 'Você pode encontrar veículos mais facilmente pesquisando diretamente por eles.',
    addStep:
      "Adicione novos veículos com o botão 'Adicionar'. Por favor, note que você precisa adicionar 'Classes de veículos' antes de usar esta função.",
  },
  onboardingTutorialStatistics: {
    typeStep:
      'Nesta seção, você pode encontrar todos os seus dados estatísticos mostrados em gráficos. Selecione o tipo de estatística aqui.',
    intervalStep: 'Adicione um intervalo para visualizar estatísticas.',
    downloadStep: 'Você também pode baixar e imprimir essas tabelas.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'No módulo GPS, você pode acompanhar seus motoristas e transferências no mesmo lugar. Escolha esta aba para ver as transferências em andamento e futuras.',
    tabTwoStep:
      'Selecione veículos para vê-los no mapa. Você pode adicionar veículos livres diretamente daqui para as transferências.',
    mapStep: 'Selecione ou desselecione veículos clicando no alfinete deles no mapa.',
  },
  advancedSettings: {
    advancedSettings: 'Configurações avançadas',
    distanceUnit: 'Unidade de distância',
    distanceUnitDescription: 'Selecione a unidade para medições de distância.',
    kilometers: 'Quilômetros',
    miles: 'Milhas',
    dateFormat: 'Formato de data',
    dateFormatDescription: 'Selecione o formato de data que prefere usar.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Ordens de condução obrigatórias',
    mandatoryDrivingsDescription: 'Escolha se deseja tornar todas as ordens de condução obrigatórias por padrão.',
    mandatoryDrivingsDefault: 'Ordens de Condução Obrigatórias (Padrão)',
    paidDrivings: 'Pedidos de condução pagos',
    paidDrivingsDescription: 'Marque para tornar todos os pedidos de condução pagos por padrão.',
    paidDrivingsDefault: 'Pedidos de condução pagos (Padrão)',
    notificationsEmail: 'E-mail para notificações',
    notificationsEmailDescription:
      'Altere o e-mail padrão para receber notificações (se você não quiser usar o endereço de e-mail da sua organização).',
    email: 'E-mail',
    articlesOfLaw: 'Artigos de lei',
    integrations: 'Integrações',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET é uma plataforma abrangente projetada para otimizar o rastreamento em tempo real, a gestão de frotas e as operações de despacho para a indústria de transporte.',
    makeRoutesAvailable: 'Tornar rotas de integração disponíveis',
    makeRoutesAvailableDescription: 'Ative sua chave API para integrar o LimoExpress ao seu site, sistema ERP e mais.',
    makePublicLinkAvailable: 'Tornar link público disponível',
    makePublicLinkAvailableDescription:
      'Ative o link para acessar a página de reservas pública para que seus clientes possam acessar o formulário de reserva avançado e criar contas facilmente. Você pode usar o link em newsletters, no site, em folhetos; é um link exclusivo para a sua empresa.',
    emailsEnabled: 'E-mails ativados',
    emailsEnabledDefault: 'E-mails ativados (Por padrão)',
    passengerReviewsEnabled: 'Avaliações de passageiros ativadas',
    passengerReviewsEnabledDefault: 'Avaliações de passageiros ativadas',
    passengerReviewsEnabledDescription:
      'Ao ativar esta funcionalidade, os seus passageiros (se for inserido um email) receberão um email de confirmação após a conclusão da viagem (marcada como Concluída) e terão a oportunidade de fornecer avaliações (feedback) sobre os seus serviços.',
    vat: 'NIF',
    vatPercentage: 'Percentual de IVA',
    vatPercentageDescription: 'Insira o percentual de IVA.',
    invoicePaymentInstructions: 'Instruções de pagamento da fatura',
    stripe: 'Stripe',
    stripeDescription: 'Ative o Stripe para cobrar seus clientes por seus serviços. LimoExpress não cobra comissão.',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Número do pedido',
    drivingType: 'Tipo de pedido',
    drivingStatus: 'Estado',
    locationFrom: 'Ponto de partida',
    locationTo: 'Destino',
    driver: 'Motorista',
    client: 'Cliente',
    plateNumber: 'Número da placa',
    note: 'Descrição',
    fuelConsumption: 'Consumo de combustível',
    pickupTime: 'Hora de recolha',
    numberOfPassengers: 'Número de passageiros',
    passengers: 'Passageiros',
    price: 'Preço',
    paid: 'Pagado',
    paymentMethod: 'Método de pagamento',
    waitingTime: 'Tempo de espera (h)',
    distance: 'Distância (km)',
    distanceMi: 'Distância (milhas)',
    selectAll: 'Selecionar tudo',
  },
  gnet: {
    gnetInfo: 'Informação GNET',
    requesterId: 'ID do solicitante',
    receiverId: 'ID do receptor',
    preferredVehicleType: 'Tipo de veículo preferido',
    reservationType: 'Tipo de reserva',
    runType: 'Tipo de corrida',
    price: 'Preço',
    currency: 'Moeda',
    noteDriver: 'Nota para o motorista',
    noteDispatcher: 'Nota para o despachante',
    acceptDriving: 'Tem certeza de que deseja aceitar a ordem de condução?',
    rejectDriving: 'Tem certeza de que deseja rejeitar a ordem de condução?',
    tripDurationMinutes: 'Duração da viagem (minutos)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Número da fatura',
    client: 'Cliente',
    totalPrice: 'Preço total',
    drivings: 'Conduções',
    paid: 'Pagado',
    currency: 'Moeda',
    createdAt: 'Criado em',
    actions: 'Ações',
  },
  invoicesPage: {
    newItem: 'Nova entrada de fatura',
    chooseClient: 'Escolher cliente',
    chooseDrivings: 'Escolher conduções',
    deleteMessage: 'Tem certeza de que deseja excluir esta fatura?',
  },
  drivingOffersHeaders: {
    number: 'Número da oferta de condução',
    totalPrice: 'Preço total',
    description: 'Descrição',
    paid: 'Pagado',
    currency: 'Moeda',
    createdAt: 'Criado em',
    actions: 'Ações',
  },
  drivingOffersPage: {
    newItem: 'Nova entrada de oferta de condução',
    item: 'Item',
    description: 'Descrição',
    title: 'Título',
    vehicleClass: 'Classe de veículo',
    quantity: 'Quantidade',
    price: 'Preço',
    discount: 'Desconto',
    vat: 'NIF',
    deleteMessage: 'Tem certeza de que deseja excluir esta oferta de condução?',
  },
  modal: {
    reservationSuccessTitle: 'Sucesso',
    reservationSuccessMessage: 'Pedido de reserva enviado com sucesso. Alguém entrará em contato com você em breve.',
    reservationErrorTitle: 'Formulário desativado',
    reservationErrorMessage:
      'O formulário foi desativado pela organização. Por favor, entre em contato com o suporte ou tente novamente mais tarde.',
  },
  dashboardPage: {
    drivingCountToday: 'Número de ordens de condução (hoje)',
    drivingCountThisMonth: 'Número de ordens de condução (últimos 30 dias)',
    dailyRentsAndTransfers: 'Número de ordens de condução (alugueres/transferências diárias)',
    drivingTotalAmount: 'Montante total',
    driversCount: 'Motoristas ativos (±7 dias)',
    passengerCount: 'Número de passageiros (últimos 30 dias)',
  },
  reviewsHeaders: {
    rating: 'Avaliação',
    comment: 'Comentário',
  },
  resourceOverviewHeaders: {
    actionType: 'Tipo de ação',
    user: 'Usuário',
    action: 'Ação',
    createdAt: 'Criado em',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Número de mensagens SMS restantes',
    flightsCheckLeft: 'Número de verificações de voo restantes',
    sms: 'SMS',
    flightCheck: 'Verificação de voo',
  },
};
