<template>
  <v-app :class="{ 'disabled-content': tourActive && $store.state.auth.authenticated && $store.state.auth.role !== 1 }">
    <v-layout fill-height>
      <v-overlay class="app-loader" :value="$store.state.loader.loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-overlay>

      <main-layout v-if="this.$store.state.auth.authenticated && this.$store.state.auth.user" />

      <HeaderEmpty v-else />
      <!--      <WelcomeModal :show-modal="this.$store.state.auth.showWelcomeModal" />-->

      <v-main :class="this.$store.state.auth.trialBannerDisplay ? 'margin-large' : 'margin-small'">
        <router-view :key="$route.fullPath" class="mb-5" />
      </v-main>
      <DemoExpiring v-if="$store.state.auth.user" />
      <Footer />
    </v-layout>

    <v-snackbar
      v-model="snackbar.active"
      :class="snackbar.textColor ? 'snackbar-warning' : ''"
      :color="snackbar.color"
      :timeout="snackbar.duration || '2000'"
      bottom>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
          {{ $t('snackbar.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import HeaderEmpty from './components/HeaderEmpty';
import DemoExpiring from './components/DemoExpiring';
import Footer from './components/Footer';
import MainLayout from './components/MainLayout';
import { mapState } from 'vuex';
import state from '@/store';
import i18n from '@/i18n/i18n';
import router from '@/router';

export default {
  name: 'App',
  components: {
    HeaderEmpty,
    DemoExpiring,
    Footer,
    MainLayout,
  },
  data: () => ({
    display: false,
    displayForm: false,
    transferOrDaily: null,
  }),
  computed: {
    ...mapState(['snackbar']),
    ...mapState('onboardingTutorial', ['tourActive']),
  },
  created() {
    let limoExpressUser = localStorage.getItem('LimoExpressUser');
    let parsedUser = limoExpressUser ? JSON.parse(limoExpressUser) : null;
    let paddleCustomer = localStorage.getItem('customer');
    let parsedCustomer = paddleCustomer ? JSON.parse(paddleCustomer) : null;
    let subscriptionStorage = localStorage.getItem('subscription');
    let subscription = subscriptionStorage ? JSON.parse(subscriptionStorage) : null;
    let subscriptionTypeIdStorage = localStorage.getItem('subscription_type_id');
    let subscriptionTypeId = subscriptionTypeIdStorage ? subscriptionTypeIdStorage : null;
    if (
      localStorage.getItem('LimoExpressToken') &&
      parsedUser &&
      localStorage.getItem('loggedIn') &&
      localStorage.getItem('saveToken')
    ) {
      state.dispatch('auth/setUser', parsedUser);
      state.dispatch('auth/setRole', parsedUser);
      state.dispatch('auth/setCustomer', parsedCustomer);
      state.dispatch('auth/setAuthToken', localStorage.getItem('LimoExpressToken'));
      state.dispatch('auth/setAuthenticated', true);
      state.dispatch('auth/setSubscription', subscription);
      state.dispatch('auth/setSubscriptionTypeId', subscriptionTypeId);
      if (this.$route.name === 'Login' || window.location.pathname === '/login' || window.location.pathname === '/') {
        this.$router.push({ name: 'Dashboard' });
      }
    }

    if (!localStorage.getItem('loggedIn')) {
      state.dispatch('auth/removeUserData');

      if (!this.isAllowedRoute()) {
        router.push({ name: 'Login' });
      }
    }

    this.snackbar.active = false;

    if (this.$store.state.auth.subscription && this.checkSubscription) {
      setInterval(() => {
        this.$store
          .dispatch('auth/activeSubscription')
          .then((res) => {
            state.dispatch('auth/setSubscription', res.data);
            localStorage.setItem('subscription', res.data ? JSON.stringify(res.data) : '');
            localStorage.setItem('subscription_type_id', res.subscription_type_id);
          })
          .catch(() => {});
      }, 3600000);
    }
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },
  mounted() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_BROWSER_TITLE,
      meta: [
        { name: 'description', content: 'LimoExpress - Limo Service SaaS Software' },
        { property: 'og:title', content: 'LimoExpress - Limo Service SaaS Software' },
        { property: 'og:site_name', content: 'LimoExpress - Limo Service SaaS Software' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
    displayFormAndDisableOptions(data) {
      this.display = false;
      this.displayForm = true;
      // data is integer passed to the function, 1 representing Transfer and 2 representing Dnevni najam
      this.transferOrDaily = data;
    },
    calculateRemainingHours(date) {
      return Math.round((new Date(date) - new Date()) / (1000 * 60 * 60));
    },
    async checkSubscription() {
      if (this.$store.state.auth.customer && this.$store.state.auth.res) {
        this.packageName = await this.$store?.getters['auth/user']?.organisation?.package_name;
        this.$store.dispatch('subscriptionPackages/getChargeDate').then((res) => {
          this.expirationHours = this.calculateRemainingHours(res.date);
          if (res.trialEnded === false) {
            this.showWarning = true;
          } else {
            this.showWarning = this.expirationHours < 0;
          }
        });
      } else {
        this.showWarning = false;
      }
    },
    isAllowedRoute: function () {
      const allowedRoutes = new Set([
        'SocialLogin',
        'ForgetPassword',
        'SetPasswordPage',
        'EnterEmailPage',
        'Registration',
        'PublicReservationForm',
      ]);
      const allowedPaths = new Set([
        '/social-login',
        '/forget-password',
        '/set-password',
        '/change-password',
        '/registration',
        '/public/reservation-form',
      ]);

      return allowedRoutes.has(this.$route.name) || allowedPaths.has(window.location.pathname);
    },

    handleOnline() {
      this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.backOnline'), color: 'green', duration: 3000 });
    },
    handleOffline() {
      this.$store.dispatch('showSnackbar', {
        text: i18n.t('snackbar.noInternetConnection'),
        color: 'red',
        duration: 3000,
      });
    },
  },
  watch: {
    '$store.state.auth.subscription': {
      handler() {
        this.checkSubscription();
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  },
};
</script>

<style>
.v-card__actions {
  padding: 16px;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 16px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

tbody tr.active-row,
tbody tr.inactive-row {
  background-color: #fff;
}

.active-row {
  cursor: pointer;
}

.inactive-row td:not(:last-child) {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.date-picker button {
  width: 50% !important;
}

.add-button {
  z-index: 22;
  position: fixed;
  bottom: 70px;
  right: 10px;
  width: 60px;
  height: 60px;
  font-size: 40px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1976d2;
  border-radius: 50%;
  color: white;
}

.add-driving-options {
  z-index: 22;
  position: fixed;
  bottom: 75px;
  right: 75px;
  width: 140px;
  height: 60px;
}

.add-driving-options > .v-btn {
  margin: 0;
  padding: 0;
  width: 100%;
}

.add-button > .rotate-right {
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

.add-button > .rotate-left {
  transform: rotate(0deg);
  transition-duration: 1s;
}

.margin-large {
  margin-bottom: 112px;
}

.margin-small {
  margin-bottom: 56px;
}

.disabled-content {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
}

.snackbar-warning .v-snack__content,
.snackbar-warning .v-snack__action .v-btn__content {
  color: #000;
}

.app-loader {
  z-index: 9999 !important;
}
</style>
