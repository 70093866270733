export default {
  namespaced: true,
  state: { loading: false },
  mutations: {
    SET_LOADER(state, value) {
      state.loading = value;
    },
  },
  actions: {
    showLoader({ commit }) {
      commit('SET_LOADER', true);
    },
    hideLoader({ commit }) {
      commit('SET_LOADER', false);
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
};
