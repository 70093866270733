import { getHttpClient } from './client';

export const getVisitedSteps = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user-tour-log').then((response) => response.data);
};

export const sendVisitedStep = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user-tour-log', 'POST', data).then(
    (response) => response.data
  );
};
