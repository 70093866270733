import { getGoogleMapsAPI } from 'gmap-vue';

const getLatLng = (location) => ({
  lat:
    typeof location?.geometry?.location?.lat === 'number'
      ? location.geometry.location.lat
      : location.geometry.location.lat(),
  lng:
    typeof location?.geometry?.location?.lng === 'number'
      ? location.geometry.location.lng
      : location.geometry.location.lng(),
});

const getDistanceBetweenTwoPlacesHelperFunction = async ({ from, to }) => {
  try {
    const google = await getGoogleMapsAPI();
    const distanceMatrixService = new google.maps.DistanceMatrixService();
    const fromLatLng = getLatLng(from);
    const toLatLng = getLatLng(to);

    let responseData = await distanceMatrixService.getDistanceMatrix({
      origins: [fromLatLng],
      destinations: [toLatLng],
      travelMode: 'DRIVING',
    });

    if (responseData.rows[0]?.elements[0]?.status === 'OK') {
      return responseData.rows[0].elements[0];
    }
    return null;
  } catch (e) {
    // console.error(e);
    return null;
  }
};

export const getDistanceBetweenMultipleLocations = async ({ from, to, checkpoints }) => {
  try {
    let totalDistance = 0;
    let totalDuration = 0;
    let previousLocation = from;
    // Calculate distance from 'from' to each checkpoint
    for (let checkpoint of checkpoints) {
      const distanceResult = await getDistanceBetweenTwoPlacesHelperFunction({
        from: previousLocation,
        to: checkpoint,
      });

      if (distanceResult) {
        totalDistance += distanceResult.distance.value;
        totalDuration += distanceResult.duration.value;
      }
      previousLocation = checkpoint;
    }
    // Calculate distance from last checkpoint to 'to'
    const finalLeg = await getDistanceBetweenTwoPlacesHelperFunction({
      from: previousLocation,
      to: to,
    });
    if (finalLeg) {
      totalDistance += finalLeg.distance.value;
      totalDuration += finalLeg.duration.value;
    }

    return {
      totalDistance,
      totalDuration,
    };
  } catch (e) {
    // console.error(e);
    return null;
  }
};

async function waitForGoogleMapsAPI() {
  return new Promise((resolve) => {
    const checkExist = setInterval(() => {
      if (window.google && window.google.maps) {
        clearInterval(checkExist);
        resolve();
      }
    }, 100); // Check every 100 milliseconds
  });
}

export const getLocationName = async ({ lat, lng }) => {
  try {
    // Wait until google.maps is defined
    await waitForGoogleMapsAPI();

    const google = await getGoogleMapsAPI();
    const geocoder = new google.maps.Geocoder();

    if (lat && lng) {
      const latlng = new google.maps.LatLng(lat, lng);
      const geocoderRequest = { location: latlng };

      return new Promise((resolve, reject) => {
        geocoder.geocode(geocoderRequest, (results, status) => {
          if (status === 'OK' && results && results.length > 0) {
            resolve(results[0].formatted_address);
          } else {
            reject('');
          }
        });
      });
    } else {
      return '';
    }
  } catch (error) {
    return '';
  }
};
