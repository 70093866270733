import {
  changeCurrencyActiveStatus,
  changeCurrencyDefaultStatus,
  deleteCurrency,
  getAllCurrencies,
  getCurrency,
  saveCurrency,
  updateCurrency,
  getOrganisationCurrencies,
  getOrganisationAvailableCurrencies,
  saveOrganisationCurrencies,
  deleteOrganisationCurrency,
} from '@/api/currencies';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllCurrencies(state, item) {
      return getAllCurrencies(item);
    },
    async getOrganisationCurrencies(state, item) {
      return getOrganisationCurrencies(item);
    },
    async getOrganisationAvailableCurrencies(state, item) {
      return getOrganisationAvailableCurrencies(item);
    },
    async getCurrency(state, item) {
      return getCurrency(item);
    },
    async saveCurrency(state, item) {
      return saveCurrency(item);
    },
    async updateCurrency(state, item) {
      return updateCurrency(item);
    },
    async deleteCurrency(state, item) {
      return deleteCurrency(item);
    },
    async changeCurrencyActiveStatus(state, item) {
      return changeCurrencyActiveStatus(item);
    },
    async changeCurrencyDefaultStatus(state, item) {
      return changeCurrencyDefaultStatus(item);
    },
    async saveOrganisationCurrencies(state, item) {
      return saveOrganisationCurrencies(item);
    },
    async deleteOrganisationCurrency(state, item) {
      return deleteOrganisationCurrency(item);
    },
  },
  getters: {},
};
