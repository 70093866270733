import { sendForgetPasswordEmail } from '@/api/forgetPassword';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async sendForgetPasswordEmail(state, item) {
      return sendForgetPasswordEmail(item);
    },
  },
  getters: {},
};
