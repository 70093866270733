import { deletePartner, getAllPartners, getPartner, savePartner, UpdatePartner } from '@/api/partners';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getAllPartners() {
      return getAllPartners();
    },
    async getPartner(state, item) {
      return getPartner(item);
    },
    async savePartner(state, item) {
      return savePartner(item);
    },
    async updatePartner(state, item) {
      return UpdatePartner(item);
    },
    async deletePartner(state, item) {
      return deletePartner(item);
    },
  },
  getters: {},
};
