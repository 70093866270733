import {
  getAllDrivingOffers,
  getDrivingOffer,
  saveDrivingOffer,
  deleteDrivingOffer,
  generateDrivingOfferPaymentLink,
} from '@/api/drivingOffers';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllDrivingOffers(state, item) {
      return getAllDrivingOffers(item);
    },
    async getDrivingOffer(state, item) {
      return getDrivingOffer(item);
    },
    async saveDrivingOffer(state, item) {
      return saveDrivingOffer(item);
    },
    async deleteDrivingOffer(state, item) {
      return deleteDrivingOffer(item);
    },
    async generateDrivingOfferPaymentLink(state, item) {
      return generateDrivingOfferPaymentLink(item);
    },
  },
  getters: {},
};
