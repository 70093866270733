import { getHttpClient } from './client';

export const getAllVehicleMaintenances = async (params) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/vehicle-maintenance';
  let queryString = '?';
  if (params.from_date) {
    queryString += `from=${params.from_date}`;
  }
  if (params.to_date) {
    queryString += `&to=${params.to_date}`;
  }
  if (params.vehicle_id) {
    queryString += `&vehicle_id=${params.vehicle_id}`;
  }
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAllVehicleData = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-data/' + data.id, 'GET').then(
    (response) => response.data
  );
};

export const saveVehicleMaintenance = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-maintenance', 'POST', data).then(
    (response) => response.data
  );
};

export const updateVehicleMaintenance = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-maintenance', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteVehicleMaintenance = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-maintenance/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const checkForAffectedDrivings = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/check-for-affected-drivings', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteVehicleMaintenanceAttachment = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/delete-vehicle-maintenance-file/' + data.id,
    'DELETE',
    data
  ).then((response) => response.data);
};
