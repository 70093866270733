import { getHttpClient } from './client';

export const getAllPaymentMethods = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/payment-method';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getPaymentMethod = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-method/' + data).then((response) => response.data);
};

export const savePaymentMethod = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-method', 'POST', data).then(
    (response) => response.data
  );
};

export const updatePaymentMethod = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-method', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePaymentMethod = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-method/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const changePaymentMethodActiveStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-status/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};

export const changePaymentMethodDefaultStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-default-status/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};

export const changePaymentMethodVisibleStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/payment-hidden-status/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};
