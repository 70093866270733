import {
  changePackage,
  getAllPackages,
  getAllPricingRegions,
  getCalculatedPrice,
  getChargeDate,
  cancelPackage,
  getCurrentPackage,
  getPackagesTypes,
  checkPackageChangeAvailability,
} from '@/api/subscriptionPackages';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllPackages() {
      return getAllPackages();
    },
    async getAllPricingRegions() {
      return getAllPricingRegions();
    },
    async getPackagesTypes() {
      return getPackagesTypes();
    },
    async getCalculatedPrice() {
      return getCalculatedPrice();
    },
    async getChargeDate() {
      return getChargeDate();
    },
    async changePackage(state, item) {
      return changePackage(item);
    },
    async getCurrentPackage(state, item) {
      return getCurrentPackage(item);
    },
    async cancelPackage(state, item) {
      return cancelPackage(item);
    },
    checkPackageChangeAvailability(state, item) {
      return checkPackageChangeAvailability(item);
    },
  },
  getters: {},
};
