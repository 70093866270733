import { deleteCard, getAllCards, getCard, saveCard, updateCard } from '@/api/customerCards';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllCards(state, item) {
      return getAllCards(item);
    },
    async getCard(state, item) {
      return getCard(item);
    },
    async saveCard(state, item) {
      return saveCard(item);
    },
    async updateCard(state, item) {
      return updateCard(item);
    },
    async deleteCard(state, item) {
      return deleteCard(item);
    },
  },
  getters: {},
};
