import { getHttpClient } from './client';

export const saveDrivingComment = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-comment', 'POST', data).then(
    (response) => response.data
  );
};

export const loadDrivingComments = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/driving-comment';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const loadDrivingDetails = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/driving-details/';
  return await getHttpClient(baseUrl + data.driving_id).then((response) => response.data);
};
