import {
  getAllArticlesOfLaw,
  getAllAdvancedSettingsForOrganization,
  getArticlesOfLaw,
  saveArticlesOfLaw,
  updateArticlesOfLaw,
  deleteArticlesOfLaw,
} from '@/api/articlesOfLaw';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllArticlesOfLaw(state, item) {
      return getAllArticlesOfLaw(item);
    },
    async getAllAdvancedSettingsForOrganization(state, item) {
      return getAllAdvancedSettingsForOrganization(item);
    },
    async getArticlesOfLaw(state, item) {
      return getArticlesOfLaw(item);
    },
    async saveArticlesOfLaw(state, item) {
      return saveArticlesOfLaw(item);
    },
    async updateArticlesOfLaw(state, item) {
      return updateArticlesOfLaw(item);
    },
    async deleteArticlesOfLaw(state, item) {
      return deleteArticlesOfLaw(item);
    },
  },
  getters: {},
};
