import { checkHealth, saveGnetKey, gnetDrivingDecision, getAllPartners, sendDrivingToGnet } from '@/api/gnet';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async checkHealth(state, item) {
      return checkHealth(item);
    },
    async saveGnetKey(state, item) {
      return saveGnetKey(item);
    },
    async gnetDrivingDecision(state, item) {
      return gnetDrivingDecision(item);
    },
    async getAllPartners(state, item) {
      return getAllPartners(item);
    },
    async sendDrivingToGnet(state, item) {
      return sendDrivingToGnet(item);
    },
  },
  getters: {},
};
