import { changeLanguage } from '@/api/languages';

const state = {
  currentLanguage: localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en',
};

const mutations = {
  setLanguage(state, language) {
    state.currentLanguage = language;
  },
};

const actions = {
  async changeLanguage({ commit }, language) {
    commit('setLanguage', language);
    return changeLanguage({ lang: language });
  },
  setLanguage({ commit }, language) {
    commit('setLanguage', language);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
