import { getHttpClient } from './client';

export const checkHealth = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/gnet/check-health').then((response) => response.data);
};

export const saveGnetKey = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/gnet/save-key', 'POST', data).then(
    (response) => response.data
  );
};

export const gnetDrivingDecision = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/gnet/driving-decision', 'POST', data).then(
    (response) => response.data
  );
};

export const getAllPartners = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/gnet/get-partners').then((response) => response.data);
};

export const sendDrivingToGnet = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/gnet/farm-out', 'POST', data).then(
    (response) => response.data
  );
};
