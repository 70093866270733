import {
  deletePricingRegion,
  getAllPricingRegions,
  getPricingRegion,
  savePricingRegion,
  updatePricingRegion,
  getAllCountries,
} from '@/api/pricingRegions';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllPricingRegions(state, item) {
      return getAllPricingRegions(item);
    },
    async getPricingRegion(state, item) {
      return getPricingRegion(item);
    },
    async savePricingRegion(state, item) {
      return savePricingRegion(item);
    },
    async updatePricingRegion(state, item) {
      return updatePricingRegion(item);
    },
    async deletePricingRegion(state, item) {
      return deletePricingRegion(item);
    },
    async getAllCountries(state, item) {
      return getAllCountries(item);
    },
  },
  getters: {},
};
