import {
  changeVehicleActiveStatus,
  checkIfVehicleIsAvailable,
  checkVehicleSeatAvailability,
  deleteVehicle,
  getAllVehicles,
  getVehicle,
  saveVehicle,
  updateVehicle,
} from '@/api/vehicles';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllVehicles(state, item) {
      return getAllVehicles(item);
    },
    async getVehicle(state, item) {
      return getVehicle(item);
    },
    async saveVehicle(state, item) {
      return saveVehicle(item);
    },
    async updateVehicle(state, item) {
      return updateVehicle(item);
    },
    async deleteVehicle(state, item) {
      return deleteVehicle(item);
    },
    async changeVehicleActiveStatus(state, item) {
      return changeVehicleActiveStatus(item);
    },
    async checkIfVehicleIsAvailable(state, item) {
      return checkIfVehicleIsAvailable(item);
    },
    async checkVehicleSeatAvailability(state, item) {
      return checkVehicleSeatAvailability(item);
    },
  },
  getters: {},
};
