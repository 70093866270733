import { saveDrivingComment, loadDrivingComments, loadDrivingDetails } from '@/api/drivingComment';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async saveDrivingComment(state, item) {
      return saveDrivingComment(item);
    },
    async loadDrivingComments(state, item) {
      return loadDrivingComments(item);
    },
    async loadDrivingDetails(state, item) {
      return loadDrivingDetails(item);
    },
  },
  getters: {},
};
