import { getHttpClient } from './client';

export const getAllTransactions = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/transaction';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getTransaction = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/transaction/' + data).then((response) => response.data);
};

export const saveTransaction = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/transaction', 'POST', data).then(
    (response) => response.data
  );
};

export const updateTransaction = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/transaction', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteTransaction = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/transaction/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const getCurrentSubscription = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/current-subscription').then(
    (response) => response.data
  );
};
