<template>
  <v-app-bar app class="primary" dark>
    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-tooltip left>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn text v-on="{ ...on, ...tooltipOn }" class="ml-auto">
                <v-icon> mdi-web </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('header.changeLanguage') }} </span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in languageOptions" :key="index" @click="changeLocale(item.abbr)">
            {{ item.lang }}
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import { languageOptions } from '@/mixins/language-options';

export default {
  name: 'HeaderEmpty',
  data() {
    return {
      languageOptions: languageOptions,
    };
  },
  methods: {
    changeLocale(lang) {
      this.$store.dispatch('language/setLanguage', lang);
      localStorage.setItem('currentLanguage', lang);
    },
  },
};
</script>
