import {
  changeVehicleClassActiveStatus,
  deleteVehicleClass,
  getAllVehicleClasses,
  getVehicleClass,
  saveVehicleClass,
  updateVehicleClass,
} from '@/api/vehicleClasses';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllVehicleClasses(state, item) {
      return getAllVehicleClasses(item);
    },
    async getVehicleClass(state, item) {
      return getVehicleClass(item);
    },
    async saveVehicleClass(state, item) {
      return saveVehicleClass(item);
    },
    async updateVehicleClass(state, item) {
      return updateVehicleClass(item);
    },
    async deleteVehicleClass(state, item) {
      return deleteVehicleClass(item);
    },
    async changeVehicleClassActiveStatus(state, item) {
      return changeVehicleClassActiveStatus(item);
    },
  },
  getters: {},
};
