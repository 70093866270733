import { getHttpClient, getHttpClientForBlob } from './client';

export const generalReportData = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClient(process.env.VUE_APP_API_URL + '/general-data' + queryString).then(
    (response) => response.data
  );
};

export const downloadGeneralDataPDF = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClientForBlob(process.env.VUE_APP_API_URL + '/export-general-data-pdf' + queryString).then(
    (response) => response.data
  );
};

export const getProfitabilityQuarterReport = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/profitability-index-quarter/' + data.year + '/' + data.quarter
  );
};

export const getProfitabilityYearReport = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/profitability-index/' + data.year);
};

export const getUtilizationQuarterReport = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/utilization-index-quarter/' + data.year + '/' + data.quarter
  );
};

export const getUtilizationYearReport = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/utilization-index/' + data.year);
};

export const getClientsQuarterReport = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/client-percentage-quarter/' + data.year + '/' + data.quarter
  );
};

export const getClientsYearReport = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client-percentage/' + data.year);
};
