import { getHttpClient } from './client';

export const getDrivingsData = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + `/gps-coordinates/get-drivings-data`).then(
    (response) => response.data
  );
};

export const getDriversData = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + `/gps-coordinates/get-drivers-data`).then(
    (response) => response.data
  );
};

export const requestDriverLocation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/gps-coordinates/request-driver-location/' + data.id).then(
    (response) => response.data
  );
};

export const updateDrivingFromMap = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/drivings/update-from-map', 'POST', data).then(
    (response) => response.data
  );
};
