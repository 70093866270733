import { getDashboardData } from '@/api/dashboard';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getDashboardData(state, item) {
      return getDashboardData(item);
    },
  },
  getters: {},
};
