<template>
  <div>
    <v-menu offset-y :key="disable" :close-on-content-click="false" ref="menu">
      <!-- <v-menu offset-y :key="disable" :close-on-content-click="false" ref="menu" v-if="isGnetDrivingAccepted"> -->
      <template v-slot:activator="{ on }">
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltipOn }">
            <div style="display: block" v-on="{ ...on, ...tooltipOn }">
              <v-btn :disabled="disableOptions" text>
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </div>
          </template>
          <span v-if="disableOptions">
            {{ $t('tooltips.optionsDisabledGnet') }}
          </span>
          <span v-else>
            {{ $t('buttons.options') }}
          </span>
        </v-tooltip>
      </template>

      <v-list>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item @click="handleClickEmit('duplicate')">
                <v-list-item-title>
                  {{ $t('buttons.duplicate') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span>{{ $t('tooltips.duplicate') }}</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item
                :disabled="editedDrivingItem.driving_type_id === 2"
                @click="handleClickEmit('makeReturnTrip')">
                <v-list-item-title>
                  {{ $t('buttons.makeReturnTrip') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="editedDrivingItem.driving_type_id === 1">{{ $t('tooltips.makeReturnTrip') }}</span>
          <span v-else>{{ $t('tooltips.cannotMakeReturnTrip') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="
            editedDrivingItem.id &&
            !editedDrivingItem.gnetData?.id &&
            user?.organisation?.organisation_settings?.gnet_id
          "
          left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item :disabled="disableSendViaGnet" @click="handleClickEmit('openGnetModal')">
                <v-list-item-title>
                  {{ $t('buttons.sendViaGnet') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="!editedDrivingItem.gnetData?.id && user?.organisation?.organisation_settings?.gnet_id">
            {{ $t('tooltips.sendViaGnet') }}
          </span>
          <span v-else-if="editedDrivingItem.gnetData?.id">
            {{ $t('tooltips.cannotSendGnetOrderViaGnet') }}
          </span>
          <span v-else-if="!user?.organisation?.organisation_settings?.gnet_id">
            {{ $t('tooltips.cannotSendViaGnetWithoutGnetId') }}
          </span>
        </v-tooltip>

        <v-tooltip
          v-if="editedDrivingItem.id && user?.organisation?.organisation_settings?.stripe_connect_enabled"
          left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item :disabled="disableGeneratePaymentLink" @click="handleClick(generatePaymentLink)">
                <v-list-item-title>
                  {{ $t('buttons.generatePaymentLink') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="user?.organisation?.organisation_settings?.stripe_connect_enabled">
            {{ $t('tooltips.generatePaymentLink') }}
          </span>
          <span v-else>
            {{ $t('tooltips.cannotGeneratePaymentLInk') }}
          </span>
        </v-tooltip>

        <v-tooltip v-if="editedDrivingItem.id && $store.getters['auth/user'].organisation.country.code === 'ME'" left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item @click="handleClick(generateContract)">
                <v-list-item-title>
                  {{ $t('buttons.generateFile') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span>
            {{ $t('tooltips.generateFile') }}
          </span>
        </v-tooltip>

        <v-tooltip v-if="editedDrivingItem.id" left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item @click="handleClick(generateDrivingOrder)">
                <v-list-item-title>
                  {{ $t('buttons.generateDrivingOrder') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span>
            {{ $t('tooltips.generateDrivingOrder') }}
          </span>
        </v-tooltip>

        <v-tooltip v-if="editedDrivingItem.id" left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item @click="handleClick(printInvoice)">
                <v-list-item-title>
                  {{ $t('buttons.printInvoice') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="editedDrivingItem.client">{{ $t('tooltips.printInvoice') }}</span>
          <span v-else>{{ $t('tooltips.cannotPrintInvoiceWithoutClient') }}</span>
        </v-tooltip>

        <v-tooltip v-if="editedDrivingItem?.id" left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item :disabled="disableMarkAsCanceled" @click="handleClickEmit('openCancelModal')">
                <v-list-item-title>
                  {{ $t('buttons.cancelDriving') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="!disableMarkAsCanceled">
            {{ $t('tooltips.cancelDriving') }}
          </span>
          <span v-if="this.editedDrivingItem.gnetData?.id && this.editedDrivingItem.gnet_reservation_creator == 0">
            {{ $t('tooltips.cannotMarkAsCanceledGnet') }}
          </span>
          <span v-else-if="getStatusId == 3">
            {{ $t('tooltips.cannotMarkAsCanceledStatusRejected') }}
          </span>
          <span v-else-if="getStatusId == 4">
            {{ $t('tooltips.cannotMarkAsCanceledStatusExpired') }}
          </span>
          <span v-else-if="getStatusId == 5">
            {{ $t('tooltips.cannotMarkAsCanceledStatusCanceled') }}
          </span>
          <span v-else-if="getStatusId == 6">
            {{ $t('tooltips.cannotMarkAsCanceledStatusDraft') }}
          </span>
          <span v-else-if="getStatusId == 7">
            {{ $t('tooltips.cannotMarkAsCanceledStatusCompleted') }}
          </span>
          <span v-else-if="getStatusId == 12">
            {{ $t('tooltips.cannotMarkAsCanceledStatusNoShow') }}
          </span>
        </v-tooltip>

        <v-tooltip v-if="editedDrivingItem?.id" left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item :disabled="disableMarkAsDone" @click="handleClickEmit('openFinishModal')">
                <v-list-item-title>
                  {{ $t('buttons.markAsDone') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="!disableMarkAsDone">
            {{ $t('tooltips.markAsDone') }}
          </span>
          <span v-else-if="new Date(editedDrivingItem.pickup_time_original) > new Date()">
            {{ $t('tooltips.cannotMarkAsDone') }}
          </span>
          <span v-else-if="getStatusId == 3">
            {{ $t('tooltips.cannotMarkAsDoneStatusRejected') }}
          </span>
          <span v-else-if="getStatusId == 5">
            {{ $t('tooltips.cannotMarkAsDoneStatusCanceled') }}
          </span>
          <span v-else-if="getStatusId == 7">
            {{ $t('tooltips.cannotMarkAsDoneStatusDone') }}
          </span>
          <span v-else-if="getStatusId == 12">
            {{ $t('tooltips.cannotMarkAsDoneStatusNoShow') }}
          </span>
        </v-tooltip>

        <v-tooltip v-if="editedDrivingItem?.id" left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item :disabled="disableMarkAsDone" @click="handleClickEmit('openNoShowModal')">
                <v-list-item-title>
                  {{ $t('buttons.markAsNoShow') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="!disableMarkAsDone">
            {{ $t('tooltips.markAsNoShow') }}
          </span>
          <span v-else-if="new Date(editedDrivingItem.pickup_time_original) > new Date()">
            {{ $t('tooltips.cannotMarkAsNoShow') }}
          </span>
          <span v-else-if="getStatusId == 3">
            {{ $t('tooltips.cannotMarkAsNoShowStatusRejected') }}
          </span>
          <span v-else-if="getStatusId == 5">
            {{ $t('tooltips.cannotMarkAsNoShowStatusCanceled') }}
          </span>
          <span v-else-if="getStatusId == 7">
            {{ $t('tooltips.cannotMarkAsNoShowStatusDone') }}
          </span>
          <span v-else-if="getStatusId == 12">
            {{ $t('tooltips.cannotMarkAsNoShowStatusNoShow') }}
          </span>
        </v-tooltip>

        <v-tooltip v-if="editedDrivingItem.id" left>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: block" v-bind="attrs" v-on="on">
              <v-list-item
                :disabled="disableDeleteOption"
                @click="handleClickEmit('openDeleteDialog')"
                :style="disableDeleteOption ? '' : 'color: red'">
                <v-list-item-title>
                  {{ $t('buttons.deleteDriving') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span v-if="!disableDeleteOption">{{ $t('tooltips.deleteDriving') }}</span>
          <span v-else-if="editedDrivingItem.gnet_reservation_creator == 1">
            {{ $t('tooltips.cannotDeleteDrivingSentViaGnet') }}
          </span>
          <span
            v-else-if="
              editedDrivingItem.gnet_reservation_creator == 0 &&
              (editedDrivingItem.gnet_reservation_accepted == 1 || this.gnetDrivingStatusAccepted)
            ">
            {{ $t('tooltips.cannotDeleteDrivingReceivedViaGnet') }}
          </span>
        </v-tooltip>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'DrivingOptions',
  components: {},
  props: ['editedDrivingItem', 'disable', 'gnetDrivingStatusAccepted'],
  data: () => ({
    user: {},
  }),
  mounted() {},
  created() {
    this.user = this.$store.getters['auth/user'];
  },
  computed: {
    disableOptions() {
      return (
        this.editedDrivingItem.gnetData?.id &&
        this.editedDrivingItem.gnet_reservation_creator == 0 &&
        this.editedDrivingItem.gnet_reservation_accepted == 0 &&
        this.getStatusId != 3 &&
        !this.gnetDrivingStatusAccepted
      );
    },
    disableSendViaGnet() {
      return this.editedDrivingItem?.gnetData != null || !this.user?.organisation?.organisation_settings?.gnet_id;
    },
    disableGeneratePaymentLink() {
      return !this.user?.organisation?.organisation_settings?.stripe_connect_enabled;
    },
    disableMarkAsDone() {
      const proposal = this.editedDrivingItem?.drivingProposal;

      return (
        new Date(this.editedDrivingItem.pickup_time_original) > new Date() ||
        proposal.drivingProposalStatus === 'Rejected' ||
        proposal.drivingProposalStatus === 'Canceled' ||
        proposal.drivingProposalStatus === 'Done' ||
        proposal.drivingProposalStatus === 'No Show'
      );
    },
    disableMarkAsCanceled() {
      const proposal = this.editedDrivingItem?.drivingProposal;

      return (
        (this.editedDrivingItem.gnetData?.id && this.editedDrivingItem.gnet_reservation_creator == 0) ||
        (proposal.drivingProposalStatus !== 'Pending' && proposal.drivingProposalStatus !== 'Accepted')
      );
    },
    showMarkAsCanceled() {
      const proposal = this.editedDrivingItem?.drivingProposal;
      return (
        proposal && (proposal.drivingProposalStatus === 'Pending' || proposal.drivingProposalStatus === 'Accepted')
      );
    },
    disableDeleteOption() {
      return (
        this.editedDrivingItem.gnetData?.id &&
        (this.editedDrivingItem.gnet_reservation_creator == 1 ||
          (this.editedDrivingItem.gnet_reservation_creator == 0 &&
            (this.editedDrivingItem.gnet_reservation_accepted == 1 || this.gnetDrivingStatusAccepted)))
      );
    },
    isGnetDrivingAccepted() {
      return (
        this.gnetDrivingStatusAccepted ||
        !this.editedDrivingItem.gnetData?.id ||
        (this.editedDrivingItem.gnetData?.id && this.editedDrivingItem.gnet_reservation_accepted == 1)
      );
    },
    getStatusId() {
      const proposal = this.editedDrivingItem?.drivingProposal;

      let id;
      if (this.editedDrivingItem?.id && proposal) {
        id = parseInt(proposal.driving_proposal_status_id);
      }

      return id;
    },
  },
  methods: {
    generatePaymentLink() {
      const data = {
        drivingId: this.editedDrivingItem?.id,
      };

      this.$store
        .dispatch('drivings/generateDrivingPaymentLink', data)
        .then((res) => {
          window.open(res.data, '_blank');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        });
    },
    generateContract() {
      this.$store
        .dispatch('drivings/generateContract', this.editedDrivingItem.id)
        .then((res) => {
          let filePath = '';
          if (!res.data.url.includes('https://') && !res.data.url.includes('http://')) {
            filePath = process.env.VUE_APP_BACKEND_URL.concat(res.data.url.replace('public', 'storage'));
          } else {
            filePath = res.data.url;
          }

          window.open(filePath, '_blank');
          this.editedDrivingItem.attachments.push({ url: res.data.url });
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    generateDrivingOrder() {
      this.$store
        .dispatch('drivings/generateDrivingOrder', this.editedDrivingItem.id)
        .then((res) => {
          let file = res.path;

          if (!res.path.includes('https://') && !res.path.includes('http://')) {
            file = process.env.VUE_APP_BACKEND_URL.concat(res.path.replace('public', 'storage'));
          }

          window.open(file, '_blank');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    printInvoice() {
      this.$store
        .dispatch('drivings/printInvoice', this.editedDrivingItem.id)
        .then((res) => {
          let file = '';
          if (!res.path.includes('https://') && !res.path.includes('http://')) {
            file = process.env.VUE_APP_BACKEND_URL.concat(res.path.replace('public', 'storage'));
          } else {
            file = res.path;
          }

          window.open(file, '_blank');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    // We need this in order to close menu on clicking on menu item, initialy is set to not close because of disabled items
    handleClick(method) {
      method();
      this.$refs.menu.save((this.$refs.menu.isActive = false));
    },
    // We need this in order to close menu on clicking on menu item, initialy is set to not close because of disabled items
    handleClickEmit(action) {
      this.$emit(action);
      this.$refs.menu.save((this.$refs.menu.isActive = false));
    },
  },
  watch: {},
};
</script>
