import { getVisitedSteps, sendVisitedStep } from '@/api/onboardingTutorial';

export default {
  namespaced: true,
  state: {
    tourActive: false,
  },
  mutations: {
    setTourActive(state, isActive) {
      state.tourActive = isActive;
    },
  },
  actions: {
    toggleTour({ commit }, isActive) {
      commit('setTourActive', isActive);
    },
    async getVisitedSteps() {
      return getVisitedSteps();
    },
    async sendVisitedStep(state, item) {
      return sendVisitedStep(item);
    },
  },
  getters: {
    isTourActive(state) {
      return state.tourActive;
    },
  },
};
