export default {
  sidebar: {
    fleet: 'Fleet',
    vehicles: 'Vehicles',
    vehiclesClasses: 'Vehicle classes',
    administration: 'Administration',
    currencies: 'Currencies',
    currenciesAdministration: 'Currency administration',
    paymentTypes: 'Payment methods',
    paymentTypesAdministration: 'Payment methods administration',
    users: 'Users',
    usersAdministration: 'Users administration',
    clients: 'Clients',
    clientsAdministration: 'Clients administration',
    organizations: 'Organizations',
    organizationsAdministration: 'Administration of organizations',
    saasAdmin: 'Saas admin',
    administrators: 'Administrators',
    saasAdminAdministration: 'Saas admin administration',
    statistics: 'Statistics',
    generalOverview: 'General report',
    payment: 'Payment',
    rebates: 'Rebates',
    pricingRegions: 'Pricing regions',
    pricingRegionsAdministration: 'Pricing regions administration',
    help: 'Help',
    drivings: 'Driving orders',
    calendar: 'Calendar',
    tabularView: 'Tabular view',
    expenses: 'Costs',
    vehicleExpensesAdministration: 'Administration of vehicle costs',
    vehicleMaintenance: 'Vehicle malfunctions',
    vehicleMaintenanceAdministration: 'Vehicle malfunctions administration',
    organization: 'Organization',
    organizationAdminstration: 'Administration of organization',
    packages: 'Packages',
    packagesChange: 'Packages change',
    logout: 'Logout from the system',
    logoutText: 'Are you sure you want to log out of the system?',
    unaccessibleRoutes: "You can't access this page. Upgrade the package for full access.",
    transactions: 'Transactions',
    gpsMap: 'GPS map',
    team: 'Team',
    teamAdministration: 'Team administration',
    invoices: 'Invoices',
    invoicesAdministration: 'Invoice administration',
    drivingOffers: 'Offers',
    drivingOffersAdministration: 'Offers administration',
    dashboard: 'Dashboard',
    dashboardAdministration: 'Dashboard administration',
    resourceOverview: 'Resource overview',
    resourceOverviewAdministration: 'Resource overview administration',
    rides: 'Rides',
  },
  header: {
    organization: 'Organization',
    newDriving: 'Driving order',
    dailyRent: 'Daily rent',
    transfer: 'Transfer',
    notifications: 'Notifications',
    changeLanguage: 'Change language',
  },
  footer: {
    poweredBy: 'Powered by',
    subscriptionTo: 'Your subscription to',
    trialEndsAt: 'Your trial period for',
    packageExpiresIn: 'package expires in',
    clickToChangePackage: 'Click to change package',
    clickToActivatePackage: 'Click to activate package',
    clickToPay: 'Click for payment',
    packageHasExpired: 'package has expired',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Yes',
    no: 'No',
    save: 'Save',
    send: 'Send',
    sendMessage: 'Send message',
    edit: 'Edit',
    add: 'Add',
    confirm: 'Confirm',
    cancel: 'Cancel',
    cancelDriving: 'Cancel driving',
    markAsDone: 'Mark as done',
    markAsNoShow: 'Mark as no show',
    deleteDriving: 'Delete driving',
    newOrganisation: 'New organization',
    changePackage: 'Packages change',
    downloadStats: 'Download statistics',
    downloadPDF: 'Download PDF',
    today: 'Today',
    choose: 'Choose',
    login: 'Log in',
    generateFile: 'Generate contract',
    generateDrivingOrder: 'Print driving order',
    downloadFile: 'Download file',
    activate: 'Activate',
    deactivate: 'Deactivate',
    showImage: 'Show image',
    showDetails: 'Show details',
    actions: 'Actions',
    delete: 'Delete',
    open: 'Open',
    show: 'Show',
    options: 'Options',
    prev: 'Previous',
    next: 'Next',
    skip: 'Skip',
    finish: 'Finish',
    settings: 'Settings',
    cancelSubscription: 'Cancel subscription',
    backToEditOrganization: 'Back to edit organization',
    payNow: 'Pay now',
    back: 'Go back',
    integrationRoutes: 'Integration routes',
    transactions: 'Transactions',
    articlesOfLaw: 'Articles of law',
    export: 'Export',
    duplicate: 'Make a copy',
    makeReturnTrip: 'Make a return trip',
    selectOrganisation: 'Select organisation',
    advancedSettings: 'Advanced settings',
    accept: 'Accept',
    reject: 'Reject',
    sendViaGnet: 'Send via GNET',
    printInvoice: 'Print invoice',
    enable: 'Enable',
    disable: 'Disable',
    generatePaymentLink: 'Generate payment link',
    addItem: '+ Add item',
    visitWebsite: 'Wisit our website',
    byMoreSms: 'By more SMS',
  },
  tooltips: {
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    showImage: 'Show image',
    open: 'Open',
    show: 'Show',
    showDetails: 'Show details ',
    downloadFile: 'Download file',
    generateFile: 'Generate contract',
    generateDrivingOrder: 'Print driving order',
    activate: 'Activate',
    deactivate: 'Deactivate',
    cancel: 'Cancel',
    cancelDriving: 'Cancel driving',
    cannotMarkAsCanceled: 'The order cannot be marked as canceled because it has already been accepted',
    markAsDone: 'Mark as done',
    cannotMarkAsDone: 'The order cannot be marked as completed because it has not yet occurred',
    markAsNoShow: 'Mark as no show',
    cannotMarkAsNoShow: 'The order cannot be marked as no show because it has not yet occurred',
    deleteDriving: 'Delete driving',
    cannotDelete: 'We are sorry, but for security reasons, users cannot be deleted while they are currently logged in.',
    duplicate: 'Make a copy',
    makeReturnTrip: 'Make a return trip',
    refresh: 'Refresh',
    selectOrganisation: 'Select organisation',
    sendViaGnet: 'Send via GNET',
    receivedViaGnet: 'Received via GNET',
    sentViaGnet: 'Sent via GNET',
    printInvoice: 'Print invoice',
    //new
    optionsDisabledGnet: 'You have to reject or accept the order to access the option menu',
    cannotMakeReturnTrip: 'You cannot make a return trip for daily rent',
    cannotSendGnetOrderViaGnet: 'You cannot send the gnet order via gnet',
    cannotSendViaGnetWithoutGnetId: 'You cannot send the order via gnet without gnet id',
    cannotPrintInvoiceWithoutClient: 'You cannot print invoice without choosing client',
    cannotMarkAsDoneStatusRejected:
      'The order cannot be marked as completed because it has already been marked as rejected',
    cannotMarkAsDoneStatusCanceled:
      'The order cannot be marked as completed because it has already been marked as canceled',
    cannotMarkAsDoneStatusDone:
      'The order cannot be marked as completed because it has already been marked as completed',
    cannotMarkAsDoneStatusNoShow:
      'The order cannot be marked as completed because it has already been marked as no show',
    cannotMarkAsNoShowStatusRejected:
      'The order cannot be marked as no show because it has already been marked as rejected',
    cannotMarkAsNoShowStatusCanceled:
      'The order cannot be marked as no show because it has already been marked as canceled',
    cannotMarkAsNoShowStatusDone:
      'The order cannot be marked as no show because it has already been marked as completed',
    cannotMarkAsNoShowStatusNoShow:
      'The order cannot be marked as no show because it has already been marked as no show',
    cannotMarkAsCanceledGnet: 'The order cannot be marked as canceled because it is recieved via gnet',
    cannotMarkAsCanceledStatusRejected:
      'The order cannot be marked as canceled because it has already been marked as rejected',
    cannotMarkAsCanceledStatusExpired:
      'The order cannot be marked as canceled because it has already been marked as expired',
    cannotMarkAsCanceledStatusCanceled:
      'The order cannot be marked as canceled because it has already been marked as canceled',
    cannotMarkAsCanceledStatusDraft:
      'The order cannot be marked as canceled because it has already been marked as draft',
    cannotMarkAsCanceledStatusCompleted:
      'The order cannot be marked as canceled because it has already been marked as completed',
    cannotMarkAsCanceledStatusNoShow:
      'The order cannot be marked as canceled because it has already been marked as no show',
    cannotDeleteDrivingSentViaGnet: 'The order cannot be deleted because it is sent via gnet',
    cannotDeleteDrivingReceivedViaGnet: 'The order cannot be deleted because it is received via gnet and accepted',
    generatePaymentLink: 'Generate payment link',
    cannotGeneratePaymentLInk: 'Payment link cannot be generated because you have not activated Stripe',
  },
  snackbar: {
    close: 'Close',
    savedSuccessfully: 'Saved successfully',
    excelDownloadedSuccessfully: 'Downloaded successfully',
    deletedSuccessfully: 'Deleted successfully',
    sentSuccessfully: 'Sent successfully',
    activeStatusChanged: 'Active status changed successfully',
    defaultStatusChanged: 'Default status changed successfully',
    primaryStatusChanged: 'Primary status changed successfully',
    anErrorOccured: 'An error occurred, report to support',
    loadingCommentsFailed: 'Loading comments failed',
    drivingCanceled: 'Driving order is successfully canceled',
    drivingFinished: 'Driving order is successfully completed',
    drivingNoShow: 'Driving order is successfully marked as no show',
    drivingDeleted: 'Driving order is successfully deleted',
    drivingAccepted: 'Driving order is successfully accepted',
    drivingRejected: 'Driving order is successfully rejected',
    planChanged: 'Plan successfully changed',
    planChangeFailed: 'It is not possible to change plan',
    saveChangesFirst: 'First save the changes you made earlier',
    makeRoutesPublic: 'Routes are publicly accessible',
    makeRoutesNotPublic: 'Routes are no longer publicly accessible',
    makePublicLinkActive: 'Public link is now available',
    makePublicLinkInactive: 'Public link is no longer available',
    integrationKeyCopied: 'Integration key copied',
    copyToken: 'Manually copy the token',
    publicLinkCopied: 'Public link copied',
    copyPublicLink: 'Manually copy the public link',
    organisationChanged: 'Company changed successfully',
    changeFailed: 'The change failed, please try again',
    loginFailed: 'Username or password is incorrect',
    organisationSuspended: 'Your organization is suspended',
    userEmailDoesNotExists: 'User with this email does not exists',
    canceledSubscrSuccessfully: 'Subscription successfully canceled',
    toManyRequests: 'To many requests. Try again in a minute.',
    visibleStatusChanged: 'Visible status changed successfully',
    noInternetConnection: 'No internet. Please check your internet connection.',
    backOnline: 'You are back online.',
    driverNotifiedToTurnGpsOn: 'Driver notified to turn GPS on',
    savedSuccessfullyWithGnetError: 'GNET synchronization failed, driving order saved sucessfully.',
    logoutFromAnotherDevice: 'You have been logged out from another device.',
    stripeEnabled: 'Stripe successfully activated',
    stripeDisabled: 'Stripe successfully deactivated',
    finishStripeConnect: 'Please finish your Stripe Setup',
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Current year',
    lastYear: 'Last year',
    lastQuarter: 'Last quarter',
    lastThreeYears: 'Last three years',
    filterByDriver: 'Filter by driver',
    filterByVehicle: 'Filter by vehicle',
    filterByClient: 'Filter by clients',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    vehicle: 'Vehicle',
    driving: 'Driving',
    user: 'User',
    fromDate: 'From date',
    toDate: 'To date',
    threeMonths: 'Three months',
    halfYear: 'Half year',
    year: 'Year',
  },
  months: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  usersPageHeaders: {
    firstName: 'First name',
    lastName: 'Last name',
    username: 'User name',
    email: 'Email',
    phoneNumber: 'Phone number',
    role: 'Role',
    active: 'Active',
    actions: 'Actions',
  },
  usersRoles: {
    masterAdmin: 'Master administrator',
    manager: 'Manager',
    driver: 'Driver',
    operator: 'Operator',
    externalAssociate: 'External associate',
    sales: 'Sales',
    salesLead: 'Sales lead',
    gnet: 'GNET',
    customer: 'Portal user',
  },
  masterUsersPageHeaders: {
    firstName: 'First name',
    lastName: 'Last name',
    username: 'User name',
    email: 'Email',
    phoneNumber: 'Phone number',
    role: 'Role',
    organisation: 'Organization',
    active: 'Active',
    actions: 'Actions',
  },
  usersPage: {
    newItem: 'New user entry',
    editItem: 'Edit User',
    id: 'Id',
    firstName: 'First name',
    lastName: 'Last name',
    username: 'User name',
    password: 'Password',
    phoneNumber: 'Phone number',
    role: 'Role',
    client: 'Client',
    email: 'Email',
    organisation: 'Organization',
    percentage: 'Percentage',
    externalAssociate: 'External associate',
    image: 'Image',
    deleteUser: 'Delete user',
    deleteMessage: 'Are you sure you want to delete the user ',
    emailChanged: 'Email change',
    verificationEmailSent:
      'The request for changing the email address has been sent. To complete the process and ensure the security of the user account, the new email address must be verified by clicking on the verification link sent to the new email. The email address will not be changed until it is successfully verified.',
  },
  clientsPageHeaders: {
    name: 'Name',
    phoneNumber: 'Phone number',
    address: 'Address',
    email: 'Email',
    companyId: 'Company ID/Personal ID',
    clientType: 'Client type',
    percentage: 'Percentage',
    active: 'Active',
    actions: 'Actions',
  },
  clientsPage: {
    newItem: 'New client entry',
    editItem: 'Edit User',
    id: 'Id',
    name: 'Name',
    address: 'Address',
    phoneNumber: 'Phone number',
    email: 'Email',
    companyId: 'Company ID/Personal ID',
    clientType: 'Client type',
    individual: 'Individual',
    business: 'Business entity',
    percentage: 'Percentage',
    deleteClient: 'Delete client',
    deleteMessage: 'Are you sure you want to delete the client ',
    tabs: {
      general: 'General',
      transfers: 'Transfers',
      users: 'Users',
      invoices: 'Invoices',
      cards: 'Cards',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Driving order number',
    drivingType: 'Driving order type',
    drivingStatus: 'Status',
    locationFrom: 'Starting point',
    locationTo: 'Destination',
    vehicleClass: 'Vehicle class',
    vehicle: 'Vehicle',
    driversName: 'Driver',
    startTime: 'Pickup time',
    actions: 'Actions',
    exportExcel: 'Export excel',
    price: 'Price',
    totalPrice: 'Total price',
    confirmed: 'Confirmed',
    paid: 'Paid',
    numberOfDrivings: 'Number of driving orders',
  },
  vehiclesHeaders: {
    plateNumber: 'Registration number',
    brand: ' Vehicle Brand',
    vehicleClass: 'Vehicle class',
    passengersNumber: 'Passengers number',
    fuelConsumption: 'Car fuel consumption',
    yearOfManufacture: 'Year of manufacture',
    pricePerKm: 'Price per km',
    pricePerMi: 'Price per mile',
    pricePerHour: 'Price per hour',
    pricePerWaitingHour: 'Price per waiting hour',
    active: 'Active',
    actions: 'Actions',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'New vehicle entry',
    editItem: 'Change of vehicle',
    vehiclePlates: 'Registration marks',
    vehicleBrand: 'Vehicle brand',
    vehicleClass: 'Vehicle class',
    passengersNumber: 'Passenger number',
    fuelConsumption: 'Car fuel consumption (100km)',
    fuelConsumptionMi: 'Car fuel consumption (100mi)',
    manufactureYear: 'Year of manufacture',
    price: 'Price per km',
    priceMi: 'Price per mile',
    hourPrice: 'Price per hour',
    image: 'Image',
    hourWaitingPrice: 'Price per waiting hour',
    deleteMessage: 'Are you sure you want to delete this vehicle?',
    tabs: {
      general: 'General',
      transfers: 'Transfers',
      costs: 'Costs',
      damages: 'Damages',
    },
    licence: 'Licence number',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Vehicle class',
    active: 'Active',
    actions: 'Actions',
  },
  vehicleClassesPage: {
    newItem: 'New vehicle class entry',
    editItem: 'Vehicle class change',
    id: 'Id',
    vehicleClass: 'Vehicle class',
    hourWaitingPrice: 'Price per waiting hour',
    deleteMessage: 'Are you sure you want to delete this vehicle class?',
  },
  expensesHeaders: {
    name: 'Name',
    vehicle: 'Vehicle',
    type: 'Type',
    description: 'Description',
    price: 'Price',
    expenseDate: 'Date',
    actions: 'Action',
    driving: 'Driving',
    exportExcel: 'Export excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Minor service',
    majorService: 'Major service',
    fuel: 'Fuel',
    tires: 'Tires',
    parking: 'Parking',
    other: 'Other',
  },
  expensesPage: {
    newItem: 'New cost entry ',
    editItem: 'Cost Change',
    id: 'Id',
    name: 'Name',
    vehicle: 'Vehicle',
    costType: 'Cost type',
    price: 'Price',
    currency: 'Currency',
    costTime: 'Cost time',
    description: 'Description',
    deleteMessage: 'Are you sure you want to delete this cost?',
    costTypes: {
      'Mali servis': 'Minor service',
      'Veliki servis': 'Major service',
      Gorivo: 'Fuel',
      Gume: 'Tires',
      Parking: 'Parking',
      Ostalo: 'Other',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Vehicle',
    details: 'Details',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    actions: 'Actions',
  },
  vehicleMaintenancePage: {
    newItem: 'New entry of vehicle breakdown ',
    editItem: 'Change of vehicle breakdown',
    id: 'Id',
    vehicle: 'Vehicle',
    description: 'Description',
    dateFrom: 'Time from',
    dateTo: 'Time to',
    deleteMessage: 'Are you sure you want to delete this vehicle breakdown?',
  },
  rebatesHeaders: {
    min: 'Minimum number of driving orders',
    max: 'Maximum number of driving orders',
    percentage: 'Rebate (%)',
    actions: 'Actions',
  },
  rebatesPage: {
    newItem: 'New entry of rebate',
    editItem: 'Change of rebate',
    rebateMin: 'Minimum number of driving orders',
    rebateMax: 'Maximum number of driving orders',
    rebate: 'Rebate (%)',
    deleteRebate: 'Delete rebate',
    deleteMessage: 'Are you sure you want to delete this rebate?',
  },
  customerCardsHeaders: {
    cardholderName: 'Cardholder name',
    cardNumber: 'Card number',
    expiryDate: 'Expiry date',
    cvv: 'CVV',
    actions: 'Actions',
  },
  customerCardsPage: {
    newItem: 'New entry of card',
    editItem: 'Change of card',
    cardholderName: 'Cardholder name',
    cardNumber: 'Card number',
    expiryDate: 'Expiry date',
    cvv: 'CVV',
    deleteCard: 'Delete card',
    deleteMessage: 'Are you sure you want to delete this card?',
  },
  pricingRegionsHeaders: {
    name: 'Name',
    actions: 'Actions',
  },
  pricingRegionsPage: {
    newItem: 'New entry of pricing region',
    editItem: 'Change of pricing region',
    name: 'Name',
    chooseCurrency: 'Choose currency',
    team10Price: 'Team 10 price - Year',
    team10PaddlePriceId: 'Team 10 paddle price id - Year',
    team25Price: 'Team 25 price - Year',
    team25PaddlePriceId: 'Team 25 paddle price id - Year',
    team50Price: 'Team 50 price - Year',
    team50PaddlePriceId: 'Team 50 paddle price id - Year',
    team10PriceMonthly: 'Team 10 price - Month',
    team10PaddlePriceIdMonthly: 'Team 10 paddle price id - Month',
    team25PriceMonthly: 'Team 25 price - Month',
    team25PaddlePriceIdMonthly: 'Team 25 paddle price id - Month',
    team50PriceMonthly: 'Team 50 price - Month',
    team50PaddlePriceIdMonthly: 'Team 50 paddle price id - Month',
    chooseCountries: 'Choose countries',
    description: 'Description',
    deletePricingRegion: 'Delete pricing region',
    deleteMessage: 'Are you sure you want to delete this pricing region?',
  },
  notificationsHeaders: {
    time: 'Time',
    notification: 'Notification',
  },
  organisationsHeaders: {
    name: 'Organization',
    phoneNumber: 'Phone number',
    email: 'Email',
    address: 'Address',
    companyId: 'Company ID',
    vat: 'VAT',
    active: 'Active',
    createdAt: 'Registered',
    actions: 'Actions',
    billingDate: 'Billing date',
  },
  organisationsPage: {
    newItem: 'New user entry ',
    editItem: 'Edit user',
    id: 'Id',
    name: 'Organization',
    address: 'Address',
    phoneNumber: 'Phone number',
    email: 'Email',
    vat: 'VAT',
    companyId: 'Company ID',
    warrantNumber: 'Number of decision',
    image: 'Image',
    signature: 'Signature',
    seal: 'Stamp',
    deactivateMessage: 'Are you sure you want to ',
    deactivate: 'DEACTIVATE',
    activate: 'ACTIVATE',
    organisation: 'organization',
    deleteOrganisation: 'Delete organization',
    deleteMessage: 'Are you sure you want to delete this organization?',
    cancelSubscrMessage:
      "Are you sure you want to cancel your subscription? Please be aware that by confirming this action: 1. Your organization's account and all associated data will be permanently deleted. This action cannot be undone. 2. You will be automatically logged out of the system immediately after cancellation. 3. You will no longer have access to log in or retrieve any data related to your organization in the future. We highly recommend reviewing any necessary information or exporting any critical data before proceeding with this irreversible action. If you have any questions or need assistance, please do not hesitate to contact our support team. Do you wish to proceed with the cancellation?",
    allDrivings: 'Total number of driving orders',
    allDrivingsAccepted: 'Total accepted driving orders',
    allDrivingsCanceled: 'Total rejected driving orders',
    allVehicles: 'Total number of vehicles',
    allUsers: 'Total number of users',
    areYouSure: 'Are you sure?',
    makeRoutesAvailable: 'Make integration routes available',
    percentage: 'Percentage',
    deleteImageMessage: 'Are you sure you want to delete this image?',
    deleteImageTitle: 'Delete Image',
    cancelSubscriptionTitle: 'Cancel Subscription',
    subscriptionType: 'Subscription type',
    billingDate: 'Billing date',
    articlesOfLaw: 'Articles of law',
    country: 'Country',
    referent: 'Referent',
    language: 'Language',
    gnetId: 'GNET ID',
  },
  transactionsHeaders: {
    invoiceNumber: 'Invoice number',
    status: 'Status',
    total: 'Total',
    currency: 'Currency',
    billedAt: 'Billed at',
    actions: 'Actions',
  },
  transactionsPage: {
    newItem: 'New transaction entry ',
    editItem: 'Edit transaction',
    id: 'Id',
    invoiceNumber: 'Invoice number',
    status: 'Status',
    sent: 'Sent',
    completed: 'Completed',
    total: 'Total',
    currency: 'Currency',
    billedAt: 'Billed at',
    actions: 'Actions',
    deleteTransaction: 'Delete transaction',
    deleteMessage: 'Are you sure you want to delete this transaction?',
    currentSubscription: 'Current package',
    nextBillingDate: 'Next billing date',
    numberOfSeatsTaken: 'Number of seats taken',
    numberOfSeatsAvailable: 'Number of seats available',
  },
  currenciesHeaders: {
    name: 'Name',
    code: 'Code',
    symbol: 'Symbol',
    active: 'Active',
    default: 'Primary',
    actions: 'Actions',
  },
  currenciesPage: {
    newItem: 'New currency entry ',
    editItem: 'Currency change',
    id: 'Id',
    currency: 'Currency',
    code: 'Code',
    symbol: 'Symbol',
    deleteMessage: 'Are you sure you want to delete this currency?',
  },
  paymentMethodsHeaders: {
    name: 'Name',
    active: 'Active',
    default: 'Primary',
    priceVisible: 'Price visible',
    actions: 'Actions',
  },
  paymentMethodsPage: {
    newItem: 'New payment method entry',
    editItem: 'Change of payment method',
    id: 'Id',
    paymentMethod: 'Payment method',
    deleteMessage: 'Are you sure you want to delete this payment method?',
  },
  feedbacksHeaders: {
    subject: 'Name',
    organisation: 'Organization',
    user: 'User',
    actions: 'Actions',
  },
  passengersHeaders: {
    firstName: 'First name',
    lastName: 'Last name',
    phoneNumber: 'Phone number',
    email: 'Email',
    passport: 'Passport',
    nationality: 'Nationality',
    actions: 'Actions',
  },
  checkpointsHeaders: {
    checkpointLocation: "Stop's location",
    arrivalTime: 'Arrival time',
    actions: 'Actions',
  },
  tables: {
    noDataText: 'No data',
    search: 'Search',
    drivings: 'Driving orders',
    drivingsByPage: 'Driving orders by page',
    vehicles: 'Vehicles',
    vehiclesByPage: 'Vehicles by page',
    vehiclesClasses: 'Vehicle classes',
    vehiclesClassesByPage: 'Vehicle classes by page',
    expenses: 'Costs',
    expensesByPage: 'Costs by page',
    vehiclesMaintenance: 'Vehicle malfunctions',
    vehiclesMaintenanceByPage: 'Vehicle malfunctions by page',
    reportsByPage: 'Reports by page',
    currencies: 'Currencies',
    currencieseByPage: 'Currencies by page',
    payments: 'Payment methods',
    paymentsByPage: 'Payment methods by page',
    saasAdministrators: 'All administrators',
    users: 'All users',
    usersByPage: 'Users by page',
    clients: 'All clients',
    clientsByPage: 'Clients by page',
    organisations: 'All organizations',
    organisationsByPage: 'Organizations by page',
    rebates: 'Rebates',
    rebatesByPage: 'Rebates by page',
    cards: 'Cards',
    cardsByPage: 'Cards by page',
    transactions: 'Transactions',
    transactionsByPage: 'Transactions by page',
    pricingRegions: 'Pricing Regions',
    pricingRegionsByPage: 'Pricing Regions by page',
    feedback: 'Feedbacks',
    feedbacksByPage: 'Feedbacks by page',
    notifications: 'Notifications',
    notificationsByPage: 'Notifications by page',
    all: 'All',
    passengers: 'Passengers',
    passengersByPage: 'Passengers by page',
    of: 'of',
    checkpoints: 'Stops',
    checkpointsByPage: 'Stops by page',
    invoices: 'Invoices',
    invoicesByPage: 'Invoices by page',
    drivingOffers: 'Driving offers',
    drivingOffersByPage: 'Driving offers by page',
    resourcesUsed: 'Resources used',
    resourcesUsedByPage: 'Resources by page',
    inNext3Days: 'In next 3 days',
    notConfirmedOrPaid: 'Not confirmed or paid',
    inNext5Days: 'In next 5 days',
    latestReviews: 'Latest reviews',
    driversByPage: 'Drivers by page',
    reviewsByPage: 'Reviews by page',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Number of accepted driving orders',
    numberOfRejectedDrivings: 'Number of rejected driving orders per driver',
    vehicleDrivingsNumber: 'Number of driving orders per vehicle',
    vehicleProfit: 'Earnings per vehicle',
    monthDrivingsNumber: 'Number of driving orders per month',
    driverProfit: 'Earnings per driver',
  },
  reportsHeaders: {
    vehicle: 'Vehicle',
    expenses: 'Expenses',
    profit: 'Profit',
    earnings: 'Earnings',
    daysWorking: 'Working days',
    usageIndex: 'Usage index',
    client: 'Client',
    numberOfDrivings: 'Number of Drivings',
    percentForClient: 'Price for the client',
    sumOfPrice: 'Amount',
  },
  packages: {
    active: 'Active',
    starterTitle: 'Starter',
    monthlyPackagesMessage: 'Monthly subscription per user.',
    starterText: 'Ideal for individuals or small teams who only need basic functionality.',
    starterListItemOne: 'Mobile applications',
    starterListItemTwo: 'Push notifications',
    starterListItemThree: 'Access to administration',
    starterListItemFour: 'Website integration',
    standardTitle: 'Standard',
    standardText: 'Perfect for organizations with over 5 drivers.',
    standardListItemOne: 'STARTER package included',
    standardListItemTwo: 'There is no limit on the number of drivers',
    standardListItemThree: '99.9% Uptime',
    standardListItemFour: 'Reports & Vehicle Management',
    premiumTitle: 'Premium',
    premiumText: 'Perfect for limo service organizations that operate on accurate data.',
    premiumListItemOne: 'STANDARD package included',
    premiumListItemTwo: 'BI & Cost Forecasting',
    premiumListItemThree: 'Export data',
    premiumListItemFour: 'Weekly and Monthly Reports',
    warningOne: '* The prices shown are monthly subscription per user',
    warningTwo:
      '* After switching to a new package, it is not possible to switch to a smaller package for the following 6 months',
    warningThree: '* Users of the demo plan have an insight into the functionalities provided by the premium package',
    warningOneAnnualPackage: 'Need more? Do you have a larger organization? We have an ENTERPRISE package for you.',
    warningTwoAnnualPackage: 'and our sales team will contact you shortly.',
    packageChange: 'Package change',
    changePackage: 'Are you sure you want to switch to',
    package: 'package',
    annualPricing: 'Annual pricing',
    monthlyPricing: 'Monthly pricing',
    annualPackageType10: 'Team 10',
    annualPackageType25: 'Team 25',
    annualPackageType50: 'Team 50',
    'Team 10': 'Team 10',
    'Team 25': 'Team 25',
    'Team 50': 'Team 50',
    annualPackagesMessage: 'Annual subscription per organization.',
    annualPackageType10MainMessage: 'Up to 10 user accounts.',
    annualPackageType25MainMessage: 'Up to 25 user accounts.',
    annualPackageType50MainMessage: 'Up to 50 user accounts.',
    annualPackageListItemOne: 'Package Standard',
    annualPackageListItemTwo: 'One-time payment',
    annualPackageListItemThree: 'Price benefits',
    annualPackageType10Discount: '- up to 65% off',
    annualPackageType25Discount: '- up to 68% off',
    annualPackageType50Discount: '- up to 65% off',
    allFeaturesIncluded: 'All features included',
    trialPeriodIncluded: 'Trial period included',
    unlimitedVehicles: 'Unlimited vehicles',
    unlimitedBookings: 'Unlimited bookings',
    onetimePaymentAnnually: 'Onetime payment annually',
  },
  successPaymentPage: {
    message: 'You have paid successfully. Thank you.',
  },
  demoExpiring: {
    notification: 'Notification',
    expiredSubscriptionMessage:
      'We inform you that your subscription to this platform has expired. To continue using our services, please renew your subscription.',
  },
  login: {
    username: 'Username',
    password: 'Password',
    rememberMe: 'Remember me',
    login: 'Log in',
    noAccount: "Don't have an account yet? You can register",
    forgetPassword: 'Forgot your password? You can reset it',
    registerHere: 'Here',
    email: 'Email',
    enterEmail: 'Please enter your email',
    enterPassword: 'Please enter your new password',
    signInWithGoogle: 'Sign in with Google',
    passwordChangeError: 'Something went wrong with setting your password. Please try again or contact support.',
  },
  registration: {
    register: 'Register',
    name: 'Organization name',
    address: 'Address',
    phoneNumber: 'Phone number',
    email: 'Email',
    companyId: 'Company ID',
    vat: 'VAT',
    language: 'Language',
    package: 'Package',
    accept: 'I accept',
    terms: 'the terms of use',
    successfullyRegistered:
      'The user has been successfully registered. You will receive an email with a verification link.',
    haveAccount: 'Already have an account? You can login',
    loginHere: 'Here',
    trialPeriodInfo: '14 days free trial period included. Billing will follow after the end of the trial period.',
  },
  driving: {
    drivingType: 'Driving order type',
    general: 'General info',
    newDriving: 'Schedule a transfer',
    newDailyRent: 'Schedule a daily rental',
    areYouSure: 'Are you sure?',
    vehicleIsUnavailable: 'The vehicle is occupied.',
    vehicleIsDamaged: 'The vehicle is broken.',
    sureAboutThisVehicle: 'Are you sure you want to choose this vehicle?',
    driverUnavaliable: 'The driver is busy.',
    sureAboutThisDriver: 'Are you sure you want to select this driver?',
    checkVehicleSeatAvailability: 'This vehicle cannot hold this many passengers.',
    cancelDriving: 'Are you sure you want to cancel the driving order?',
    finishDriving: 'Are you sure you want to end the driving order?',
    noShowDriving: 'Are you sure you want to mark driving order as no show?',
    deleteDriving: 'Are you sure you want to delete the driving order?',
    newTransfer: 'New transfer',
    driving: 'Driving order',
    comments: 'Comments',
    id: 'Id',
    locationFrom: 'Starting point',
    locationTo: 'Destination',
    vehicleClass: 'Vehicle class',
    vehicle: 'Vehicle',
    driver: 'Driver',
    pickupTime: 'Pickup time',
    clientConfirmed: 'Client confimed',
    description: 'Description',
    extraInfo: 'Additional info',
    drivingNotAccepted: 'They did not accept the driving order:',
    currency: 'Currency',
    paymentMethod: 'Payment methods',
    price: 'Price',
    paid: 'Paid',
    distance: 'Distance (km)',
    distanceMi: 'Distance (miles)',
    expectedTime: 'Expected duration',
    waitingTime: 'Waiting time (h)',
    EnterTheNumberOfHoursWaiting: 'Enter the number of hours waiting',
    dropOffTime: 'Arrival time',
    comebackTime: 'Return time',
    acceptUntil: 'Accept until',
    drivingRequired: 'Is driving order mandatory',
    comment: 'Comment',
    attachments: 'Attachments',
    addFiles: '+ Add files',
    deleteFiles: 'Are you sure you want to delete this file?',
    passengers: 'Passengers',
    addPassenger: '+ Add passenger',
    deletePassenger: 'Are you sure you want to delete this passenger?',
    chooseClient: 'Choose client',
    costs: 'Costs',
    checkpoints: 'Stops',
    addCheckpoint: 'Add a stop',
    deleteCheckpoint: 'Are you sure you want to delete this stop?',
    waitingBoardText: 'Waiting board text',
    flightNumber: 'Flight number',
    flightInfo: 'Flight info',
    createdBy: 'Driving order created at <b>{time}</b> by <b>{user}</b>',
    client: 'Client',
    gnet: 'GNET',
    note: 'Note',
    noteDriver: 'Note for driver',
    noteDispatcher: 'Note for dispatcher',
    passenger: 'Passenger',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    email: 'Email',
    suitcasesNumber: 'Suitcases number',
    passengersNumber: 'Passengers number',
    babySeatsNumber: 'Baby seats number',
    stopsNumber: 'Stops number',
    steps: {
      mainInfo: 'Main Info',
      additionalInfo: 'Additional Info',
      review: 'Review',
    },
    commissionAmount: 'Commission',
  },
  drivingStatus: {
    pending: 'Pending',
    accepted: 'Accepted',
    rejected: 'Rejected',
    expired: 'Expired',
    canceled: 'Canceled',
    draft: 'Draft',
    done: 'Done',
    noShow: 'No Show',
  },
  drivingOrderType: {
    dailyRent: 'Daily rent',
    transfer: 'Transfer',
  },
  flightInfo: {
    airlineName: 'Airline name',
    flightNumber: 'Flight number',
    departureAirport: 'Departure airport',
    departureCountry: 'Departure country',
    departureTime: 'Departure time',
    arrivalAirport: 'Arrival airport',
    arrivalCountry: 'Arrival country',
    arrivalTime: 'Arrival time',
    departureDelayed: 'Departure delayed',
    flightStatus: 'Flight status',
    percentageOfFlight: 'Percentage of flight',
  },
  customValidations: {
    fileType: `The file type .{extension} is not supported`,
    fileSize: 'Files cannot be larger than {size}',
    fieldRequired: 'Field {field} is required',
    fieldType: 'Field {field} must be of type {type}',
    fieldLengthMin: 'The {field} field must be {length} or more',
    fieldLengthMax: 'The {field} field must be {length} or less',
    fieldUnique: 'Field {field} must be unique',
    fieldValid: 'Field {field} must be valid',
    noActiveSubscription: 'No active subscription found',
    usersLimitExceeded: 'User limit reached',
    fieldBefore: 'The {field1} must be before {field2}',
    flightNotExist: 'The flight {field} does not exist',
    gnetKeyNotValid: 'Gnet key provided is not valid.',
    gnetPassengersRequired: 'Driving order cannot be sent to GNET without passengers.',
    priceAndCurrencyRequired: 'Payment link cannot be generated without price and currency',
    fieldValueNotSupported: 'The {field} field value is not supported',
    noCountryCode: 'You have no country selected',
    noStripeForYourCountry: 'Stripe is not available in your country',
    stripeAlreadyEnabled: 'Stripe is already activated',
    fieldValueMin: 'The {field} field must be {min} or more',
    fieldValueMax: 'The {field} field must be {max} or less',
    markAsDoneDate: 'Pickup time cannot be in the future',
  },
  fieldTypes: {
    string: 'string',
    integer: 'number',
    date: 'date',
  },
  names: {
    username: 'username',
    password: 'password',
    choose_client: 'choose client',
    choose_month: 'choose month',
    currency: 'currency',
    payment_method: 'payment method',
    vehicle_class: 'vehicle class',
    first_name: 'first name',
    last_name: 'last name',
    passport: 'passport',
    nationality: 'nationality',
    plate_number: 'plate number',
    brand: 'car brand',
    number_of_passengers: 'number of passengers',
    fuel_consumption: 'fuel consumption',
    year_of_manufacture: 'year of manufacture',
    price_per_km: 'price per km',
    price_per_mi: 'price per mile',
    price_per_hour: 'price per hour',
    price_per_waiting_hour: 'price per waiting hour',
    vehicle: 'vehicle',
    description: 'description',
    time_from: 'time from',
    time_to: 'time to',
    name: 'name',
    cost_type: 'cost type',
    cost_time: 'cost time',
    price: 'price',
    rebate_min: 'minimum driving orders',
    rebate_max: 'maximum driving orders',
    rebate: 'rebate',
    phone_number: 'phone number',
    organisation: 'organization',
    role: 'role',
    image: 'image',
    location_from: 'location from',
    location_to: 'location to',
    pickup_time: 'Pickup time',
    distance: 'distance',
    waiting_time: 'waiting time',
    comment: 'comment',
    address: 'address',
    vat: 'VAT',
    company_id: 'company id',
    company_personal_id: 'company id/personal id',
    licence: 'licence',
    warrant_number: 'warrant number',
    seal: 'seal',
    signature: 'signature',
    email: 'email',
    percentage: 'percentage',
    organizationName: 'organization name',
    package: 'package',
    choose_currency: 'choose currency',
    team_10_price: 'team 10 price',
    team_10_paddle_price_id: 'team 10 paddle price id',
    team_25_price: 'team 25 price',
    team_25_paddle_price_id: 'team 25 paddle price id',
    team_50_price: 'team 50 price',
    team_50_paddle_price_id: 'team 50 paddle price id',
    team_10_price_monthly: 'team 10 price',
    team_10_paddle_price_id_monthly: 'team 10 paddle price id',
    team_25_price_monthly: 'team 25 price',
    team_25_paddle_price_id_monthly: 'team 25 paddle price id',
    team_50_price_monthly: 'team 50 price',
    team_50_paddle_price_id_monthly: 'team 50 paddle price id',
    countries: 'countries',
    invoice_number: 'invoice number',
    status: 'status',
    total: 'total',
    billed_at: 'billed at',
    articles_of_law: 'articles of law',
    country: 'country',
    referent: 'referent',
    language: 'language',
    location: 'location',
    arrival_time: 'arrival time',
    waiting_board_text: 'waiting board text',
    flight_number: 'flight number',
    gnet_id: 'GNET ID',
    receiver_id: 'receiver ID',
    preferred_vehicle_type: 'preferred vehicle type',
    reservation_type: 'reservation type',
    run_type: 'run type',
    trip_duration_minutes: 'trip duration',
    client: 'client',
    client_type: 'client type',
    card_holder_name: 'cardholder name',
    card_number: 'card number',
    expiry_date: 'expiry date',
    cvv: 'cvv',
    code: 'code',
    symbol: 'symbol',
    quantity: 'quantity',
    discount: 'discount',
    driving_type: 'driving type',
    full_name: 'full name',
    suitcases_number: 'suitcases number',
    passengers_number: 'suitcases number',
    baby_seats_number: 'suitcases number',
    stops_number: 'suitcases number',
    commission_amount: 'commission',
  },
  welcomeModal: {
    welcome: 'Welcome to LimoExpress',
  },
  weekDays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  select: {
    noDataAvailable: 'No data available',
  },
  excelExport: {
    to_date_range: 'The report cannot be generated for future dates. Please enter valid dates',
    overlap_range: 'End time cannot be greater than the start time.',
  },
  setPassword: {
    success: 'Email sent',
    checkEmail: 'Check your email for further instructions.',
    already_changed: 'Already changed',
    send_new_reset_request: 'You have already changed password with this link. Send new reset password request.',
  },
  statisticsPage: {
    reportType: 'Report type',
    reportTime: 'Report time',
    vehicleUtilization: 'Vehicle utilization',
    costEffectiveness: 'Cost effectiveness',
    generalReport: 'General report',
    clientsReport: 'Report by Clients',
  },
  profile: {
    yourProfile: 'Your Profile',
    name: 'Name',
    role: 'Role',
    username: 'Username',
    password: 'Password',
    phoneNumber: 'Phone Number',
    email: 'Email',
    percentage: 'Percentage',
  },
  gpsMaps: {
    noTransfers: 'There are no ongoing or upcoming transfers.',
    map: 'Map',
    transfers: 'Transfers',
    drivers: 'Drivers',
    ongoing: 'Ongoing',
    upcoming: 'Upcoming',
    driving: 'Driving',
    driver: 'Driver',
    vehicle: 'Vehicle',
    requestDriverLocation: 'Request driver’s location',
    lastRecordedLocation: 'Last recorded location',
  },
  driverStatus: {
    available: 'Available',
    offline: 'Offline',
    inTransfer: 'In transfer',
  },
  onboardingTutorialMain: {
    languageStep: 'Here you can set your preferred language for the app.',
    notificationsStep: 'These are your notifications.',
    addTransferStep:
      'Hit this button to create your transfers and daily rents. Daily rents are custom transfers without a destination.',
    sideMenuButtonStep: 'Click this icon to show or hide the menu bar.',
    sideMenuExpStep:
      'From here, you can navigate to the main sections of the application: Transfers, Fleet, Costs, Administration, Statistics and Transactions.',
    sideMenuButtonCloseStep: 'Click the icon again to hide the menu bar.',
    supportStep: 'Would you need help with any problem, don’t hesitate to contact Support by clicking here.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Your vehicles will be listed here in a table, after you add them.',
    searchStep: `You can find vehicles easier by directly searching for them.`,
    addStep: `Add new vehicles with the “Add” button. Please note that you have to add “Vehicle Classes” before you use this feature.`,
  },
  onboardingTutorialStatistics: {
    typeStep: `In this section, you can find all your statistical data shown in graphs. Select the type of statistics here.`,
    intervalStep: `Add an interval, that you would like to view statistics for.`,
    downloadStep: `You can also download and print these tables.`,
  },
  onboardingTutorialGps: {
    tabOneStep: `In the GPS module, you can follow up on your drivers and transfers in the same place. Choose this tab to view ongoing and future transfers.`,
    tabTwoStep: `Choose vehicles to see them on the map. You can add free vehicles directly from here to transfers.`,
    mapStep: `Select or unselect vehicles by clicking on their pin on the map.`,
  },
  advancedSettings: {
    advancedSettings: 'Advanced settings',
    distanceUnit: 'Distance unit',
    distanceUnitDescription: 'Select the unit for measurements of distance.',
    kilometers: 'Kilometers',
    miles: 'Miles',
    dateFormat: 'Date format',
    dateFormatDescription: 'Select the date format you prefer to use.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Mandatory driving orders',
    mandatoryDrivingsDescription: 'Check to make all driving orders mandatory by default.',
    mandatoryDrivingsDefault: 'Mandatory Driving Orders (Default)',
    paidDrivings: 'Paid driving orders',
    paidDrivingsDescription: 'Check to make all drivingmakeRoutesAvailable orders paid by default.',
    paidDrivingsDefault: 'Paid Driving Orders (Default)',
    notificationsEmail: 'Email for notifications',
    notificationsEmailDescription:
      'Change the default email to receive notifications (if you don’t want to use the email address of your organization).',
    email: 'Email',
    articlesOfLaw: 'Articles of law',
    integrations: 'Integrations',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET is a comprehensive platform designed to optimize real-time tracking, fleet management, and dispatch operations for the transportation industry.',
    makeRoutesAvailable: 'Make integration routes available',
    makeRoutesAvailableDescription:
      'Activate your API key to integrate LimoExpress with your website, ERP system, and more.',
    makePublicLinkAvailable: 'Make public link available',
    makePublicLinkAvailableDescription:
      'Activate the link to access the public booking page so your clients can access the advanced booking form and easily create accounts. You can use the link in newsletters, on the website, flyers; it is a unique link only for your company.',
    emailsEnabled: 'Emails enabled',
    emailsEnabledDefault: 'Emails enabled',
    emailsEnabledDescription: 'Check to receive email notifications.',
    passengerReviewsEnabled: 'Passenger reviews enabled',
    passengerReviewsEnabledDefault: 'Passenger reviews enabled',
    passengerReviewsEnabledDescription:
      'By activating this feature, your passengers (if an email is entered) will receive a confirmation email after the ride is completed (marked as Finished) and will have the opportunity to provide ratings (feedback) for your services.',
    vat: 'VAT',
    vatPercentage: 'VAT percentage',
    vatPercentageDescription: 'Enter vat percentage.',
    invoicePaymentInstructions: 'Invoice payment instructions',
    stripe: 'Stripe',
    stripeDescription:
      'Activate Stripe to be able to charge your clients for your services. LimoExpress does not take any commission.',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Driving order number',
    drivingType: 'Driving order type',
    drivingStatus: 'Status',
    locationFrom: 'Starting point',
    locationTo: 'Destination',
    driver: 'Driver',
    client: 'Client',
    plateNumber: 'Registration number',
    note: 'Description',
    fuelConsumption: 'Car fuel consumption',
    pickupTime: 'Pickup time',
    numberOfPassengers: 'Number of passengers',
    passengers: 'Passengers',
    price: 'Price',
    paid: 'Paid',
    paymentMethod: 'Payment method',
    waitingTime: 'Waiting time (h)',
    distance: 'Distance (km)',
    distanceMi: 'Distance (miles)',
    selectAll: 'Select all',
  },
  gnet: {
    gnetInfo: 'GNET info',
    requesterId: 'Requester id',
    receiverId: 'Receiver id',
    preferredVehicleType: 'Preferred vehicle type',
    reservationType: 'Reservation type',
    runType: 'Run type',
    price: 'Price',
    currency: 'Currency',
    noteDriver: 'Note for driver',
    noteDispatcher: 'Note for dispatcher',
    acceptDriving: 'Are you sure you want to accept the driving order?',
    rejectDriving: 'Are you sure you want to reject the driving order?',
    tripDurationMinutes: 'Trip duration (minutes)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Invoice number',
    client: 'Client',
    totalPrice: 'Total price',
    drivings: 'Drivings',
    paid: 'Paid',
    currency: 'Currency',
    createdAt: 'Created at',
    actions: 'Actions',
  },
  invoicesPage: {
    newItem: 'New invoice entry',
    chooseClient: 'Choose client',
    chooseDrivings: 'Choose drivings',
    deleteMessage: 'Are you sure you want to delete this invoice?',
  },
  drivingOffersHeaders: {
    number: 'Driving offer number',
    totalPrice: 'Total price',
    description: 'Description',
    paid: 'Paid',
    currency: 'Currency',
    createdAt: 'Created at',
    actions: 'Actions',
  },
  drivingOffersPage: {
    newItem: 'New driving offer entry',
    item: 'Item',
    description: 'Description',
    title: 'Title',
    vehicleClass: 'Vehicle class',
    quantity: 'Quantity',
    price: 'Price',
    discount: 'Discount',
    vat: 'VAT',
    deleteMessage: 'Are you sure you want to delete this driving offer?',
  },
  modal: {
    reservationSuccessTitle: 'Success',
    reservationSuccessMessage: 'Reservation request sent successfully. Someone will get in touch with you shortly.',
    reservationErrorTitle: 'Form disabled',
    reservationErrorMessage:
      'Form is currently disabled by the organization. Please contact support or try again later.',
  },
  dashboardPage: {
    drivingCountToday: 'Number of driving orders (today)',
    drivingCountThisMonth: 'Number of driving orders (last 30 days)',
    dailyRentsAndTransfers: 'Number of driving orders (daily rents/transfers)',
    drivingTotalAmount: 'Total amount',
    driversCount: 'Active drivers (±7 days)',
    passengerCount: 'Number of passengers (last 30 days)',
  },
  reviewsHeaders: {
    rating: 'Rating',
    comment: 'Comment',
  },
  resourceOverviewHeaders: {
    actionType: 'Action type',
    user: 'User',
    action: 'Action',
    createdAt: 'Created at',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Number of SMS messages left',
    flightsCheckLeft: 'Number of flight checks left',
    sms: 'SMS',
    flightCheck: 'Flight check',
  },
};
