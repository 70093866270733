var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},_vm._l((_vm.newPassengers),function(item,index){return _c('v-card',{key:index,staticClass:"px-5 my-5"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('span',[_vm._v(" #"+_vm._s(index + 1)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","text":"","disabled":_vm.disable},on:{"click":function($event){return _vm.deletePassenger(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltips.delete')))])])],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":'first_name' + index,"custom-messages":{
                max: _vm.$t('customValidations.fieldLengthMax', {
                  field: _vm.$t('names.first_name'),
                  length: 200,
                }),
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"clearable":"","counter":"200","name":"first_name","label":_vm.$t('passengersHeaders.firstName'),"disabled":_vm.disable,"error-messages":errors},model:{value:(item.first_name),callback:function ($$v) {_vm.$set(item, "first_name", $$v)},expression:"item.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":'last_name' + index,"custom-messages":{
                max: _vm.$t('customValidations.fieldLengthMax', {
                  field: _vm.$t('names.last_name'),
                  length: 200,
                }),
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"clearable":"","counter":"200","name":"last_name","label":_vm.$t('passengersHeaders.lastName'),"disabled":_vm.disable,"error-messages":errors},model:{value:(item.last_name),callback:function ($$v) {_vm.$set(item, "last_name", $$v)},expression:"item.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"rules":{
                regex: /^\+?[0-9\s\-()]+$/,
                max: 200,
              },"name":'phone_number' + index,"custom-messages":{
                regex: _vm.$t('customValidations.fieldType', {
                  field: _vm.$t('names.phone_number'),
                  type: _vm.$t('names.phone_number'),
                }),
                max: _vm.$t('customValidations.fieldLengthMax', {
                  field: _vm.$t('names.phone_number'),
                  length: 200,
                }),
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"clearable":"","counter":"200","name":"phone","label":_vm.$t('passengersHeaders.phoneNumber'),"disabled":_vm.disable,"error-messages":errors},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"email|max:200","name":'email' + index,"custom-messages":{
                email: _vm.$t('customValidations.fieldType', {
                  field: _vm.$t('names.email'),
                  type: _vm.$t('names.email'),
                }),
                max: _vm.$t('customValidations.fieldLengthMax', {
                  field: _vm.$t('names.email'),
                  length: 200,
                }),
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"clearable":"","counter":"200","name":"email","label":_vm.$t('passengersHeaders.email'),"disabled":_vm.disable,"error-messages":errors},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":'passport' + index,"custom-messages":{
                max: _vm.$t('customValidations.fieldLengthMax', {
                  field: _vm.$t('names.passport'),
                  length: 200,
                }),
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"clearable":"","counter":"200","name":"passport","label":_vm.$t('passengersHeaders.passport'),"disabled":_vm.disable,"error-messages":errors},model:{value:(item.passport),callback:function ($$v) {_vm.$set(item, "passport", $$v)},expression:"item.passport"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":'nationality' + index,"custom-messages":{
                max: _vm.$t('customValidations.fieldLengthMax', {
                  field: _vm.$t('names.nationality'),
                  length: 200,
                }),
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"clearable":"","counter":"200","name":"nationality","label":_vm.$t('passengersHeaders.nationality'),"disabled":_vm.disable,"error-messages":errors},model:{value:(item.nationality),callback:function ($$v) {_vm.$set(item, "nationality", $$v)},expression:"item.nationality"}})]}}],null,true)})],1)],1)],1)}),1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary cancel","text":"","disabled":_vm.disable},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('driving.addPassenger'))+" ")])],1)],1),_c('v-dialog',{attrs:{"max-width":"650"},model:{value:(_vm.passengerDialogDelete),callback:function ($$v) {_vm.passengerDialogDelete=$$v},expression:"passengerDialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"info title white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.passengerToDelete?.url ? _vm.passengerToDelete?.url?.slice(19) : _vm.passengerToDelete?.first_name + ' ' + _vm.passengerToDelete?.last_name)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"mdi mdi-close",staticStyle:{"color":"white"},on:{"click":function($event){_vm.passengerDialogDelete = false}}})],1),_c('v-card-text',[_c('v-container',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('driving.deletePassenger'))+" ")])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":""},on:{"click":_vm.deletePassengerConfirm}},[_vm._v(" "+_vm._s(_vm.$t('buttons.yes'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }