export const defaultUser = {
  id: null,
  name: '',
  email: '',
  password: '',
  role_id: null,
  username: '',
  organisation_id: null,
  first_name: '',
  last_name: '',
  address: '',
  phone: '',
  image: null,
  percentage: 0,
};

export const defaultClient = {
  id: null,
  name: '',
  address: '',
  phone: '',
  email: '',
  pib: '',
  type: '',
  percent: 0,
  active: 0,
};

export const defaultVehicle = {
  id: null,
  brand: '',
  plate_number: '',
  year_of_manufacture: null,
  active: 0,
  passengers: null,
  vehicle_class_id: null,
  fuel_consumption: null,
  price_per_km: null,
  price_per_hour: null,
  price_per_waiting_hour: null,
  licence: null,
};

export const defaultVehicleExpense = {
  id: null,
  name: '',
  price: null,
  expense_date: null,
  expense_type_id: null,
  vehicle_id: null,
  currency_id: null,
  description: null,
};

export const defaultVehicleMaintenance = {
  id: null,
  note: '',
  date_from: null,
  date_to: null,
  vehicle_id: null,
};

export const defaultCurrency = {
  id: null,
  name: '',
  code: '',
  active: 0,
  default: 0,
};

export const defaultPaymentMethod = {
  id: null,
  name: '',
  code: '',
  active: 0,
  default: 0,
};

export const defaultVehicleClass = {
  id: null,
  name: '',
  code: '',
  active: 0,
};

export const defaultRebate = {
  id: null,
  min: null,
  max: null,
  percentage: null,
};

export const defaultPricingRegion = {
  id: null,
  name: '',
  currency: '',
  team_10_price: '',
  team_10_paddle_price_id: '',
  team_25_price: '',
  team_25_paddle_price_id: '',
  team_50_price: '',
  team_50_paddle_price_id: '',
  countries: [],
  description: '',
};

export const defaultOrganisation = {
  id: null,
  name: '',
  address: '',
  vat: '',
  email: '',
  phone_number: '',
  company_id: '',
  image: null,
  active: 1,
  subscription_type_id: '',
  billing_date: null,
  gnet_id: '',
};

export const defaultTransaction = {
  id: null,
  invoice_number: '',
  status: '',
  total: '',
  currency_id: '',
  billed_at: '',
  organisation_id: null,
  subscription_id: null,
};

export const defaultDriving = {
  id: '',
  number: '',
  currency_id: '',
  payment_method_id: '',
  driving_status_id: '',
  from_location: {},
  to_location: {},
  note: '',
  note_dispatcher: '',
  pickup_time: '',
  distance: 0,
  duration: '',
  expected_drop_off_time: '',
  expected_comeback_time: '',
  price: 0,
  paid: true,
  vehicle_id: '',
  user_id: '',
  mandatory: false,
  confirmation_until: '',
  to_location_name: '',
  from_location_name: '',
  message: '',
  num_of_waiting_hours: '',
  driving_type_id: '',
  client_confirmed: false,
  vehicle_class_id: '',
  attachments: [],
  passengers: [],
  waiting_board_text: '',
  flight_number: '',
  comments: [],
  checkpoints: [],
  comission_amount: 0,
};

export const defaultDrivingProposal = {
  id: null,
  user_id: null,
  mandatory: false,
  confirmation_until: null,
  message: null,
  driving_id: null,
};

export const defaultDrivingComment = {
  driving_id: null,
  rate: 0,
  body: null,
};

export const defaultFlightInfo = {
  airline_name: '',
  flight_iata: '',
  dep_name: '',
  dep_country: '',
  dep_time: '',
  arr_name: '',
  arr_country: '',
  arr_time: '',
  arr_delayed: '',
  status: '',
  percent: '',
};

export const defaultCard = {
  card_holder_name: '',
  card_number: '',
  expiry_date: '',
  cvv: '',
};

export const defaultInvoice = {
  client_id: '',
  driving_ids: [],
};

export const defaultDrivingOffer = {
  description: '',
  driving_offer_items: [],
};

export const defaultDrivingOfferItem = {
  title: '',
  vehicle_class_id: '',
  quantity: '',
  price: '',
  discount: '',
  vat: '',
};

export const defaultReservationDrivingItem = {
  public_booking_id: '',
  from_location: '',
  from_location_name: '',
  to_location: '',
  to_location_name: '',
  driving_type_id: '',
  pickup_time: '',
  vehicle_class_id: '',
  num_of_waiting_hours: '',
  full_name: '',
  phone_number: '',
  email: '',
  flight_number: '',
  waiting_board_text: '',
  suitcase_number: '',
  passenger_number: '',
  baby_seat_number: '',
  stop_number: '',
  note: '',
};

export const defaultDashboardBannerData = {
  drivingCountToday: 0,
  drivingCountThisMonth: 0,
  drivingTypeDailyRentCount: 0,
  drivingTypeTransfersCount: 0,
  drivingTotalAmount: 0,
  driversCount: 0,
  passengerCount: 0,
};
