import {
  getAllPaymentMethods,
  getPaymentMethod,
  savePaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  changePaymentMethodActiveStatus,
  changePaymentMethodDefaultStatus,
  changePaymentMethodVisibleStatus
} from '@/api/paymentMethods';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllPaymentMethods(state, item) {
      return getAllPaymentMethods(item);
    },
    async getPaymentMethod(state, item) {
      return getPaymentMethod(item);
    },
    async savePaymentMethod(state, item) {
      return savePaymentMethod(item);
    },
    async updatePaymentMethod(state, item) {
      return updatePaymentMethod(item);
    },
    async deletePaymentMethod(state, item) {
      return deletePaymentMethod(item);
    },
    async changePaymentMethodActiveStatus(state, item) {
      return changePaymentMethodActiveStatus(item);
    },
    async changePaymentMethodDefaultStatus(state, item) {
      return changePaymentMethodDefaultStatus(item);
    },
    async changePaymentMethodVisibleStatus(state, item) {
      return changePaymentMethodVisibleStatus(item);
    },
  },
  getters: {},
};
