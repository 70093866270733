import { getDistanceBetweenMultipleLocations, getLocationName } from '@/api/googleMapsAPI';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getDistanceBetweenMultipleLocations(state, item) {
      return getDistanceBetweenMultipleLocations(item);
    },

    async getLocationName(state, item) {
      return getLocationName(item);
    },
  },
};
