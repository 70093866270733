<template>
  <v-container>
    <validation-observer ref="gnetForm" v-slot="{ handleSubmit, failed }">
      <v-form lazy-validation @submit.prevent="handleSubmit(sendDrivingToGnet)">
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|max:255" v-slot="{ errors }" name="receiver_id">
              <v-autocomplete
                v-model="gnetData.requester_id"
                :items="partners"
                clearable
                :label="$t('gnet.receiverId')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"
                :error-messages="errors"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|max:255" v-slot="{ errors }" name="preferred_vehicle_type">
              <v-autocomplete
                v-model="gnetData.preferred_vehicle_type"
                :items="preferredVehicleTypes"
                clearable
                :label="$t('gnet.preferredVehicleType')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"
                :error-messages="errors"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|max:255" v-slot="{ errors }" name="reservation_type">
              <v-autocomplete
                v-model="gnetData.reservation_type"
                :items="reservationTypes"
                clearable
                :label="$t('gnet.reservationType')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"
                :error-messages="errors"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|max:255" v-slot="{ errors }" name="run_type">
              <v-autocomplete
                v-model="gnetData.run_type"
                :items="runTypes"
                clearable
                :label="$t('gnet.runType')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"
                :error-messages="errors"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" v-if="gnetData.run_type == 'HOURLY'">
            <validation-provider
              rules="required|numeric|min_value:0|max_value:1000|max:1000"
              v-slot="{ errors }"
              name="trip_duration_minutes">
              <v-text-field
                v-model="gnetData.trip_duration_minutes"
                clearable
                :label="$t('gnet.tripDurationMinutes')"
                type="text"
                :error-messages="errors"></v-text-field
            ></validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="numeric_decimal|min_value:0|max:255" v-slot="{ errors }" name="price">
              <v-text-field
                v-model="gnetData.price"
                clearable
                :label="$t('gnet.price')"
                type="text"
                :error-messages="errors"></v-text-field
            ></validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="max:255" v-slot="{ errors }" name="currency">
              <v-text-field
                v-model="gnetData.currency"
                clearable
                :label="$t('gnet.currency')"
                type="text"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <validation-provider rules="max:1000" v-slot="{ errors }" name="note_driver">
              <v-textarea
                v-model="gnetData.note_driver"
                counter="1000"
                :label="$t('gnet.noteDriver')"
                rows="2"
                clearable
                :error-messages="errors"></v-textarea
            ></validation-provider>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <validation-provider rules="max:1000" v-slot="{ errors }" name="note_dispatcher">
              <v-textarea
                v-model="gnetData.note_dispatcher"
                counter="1000"
                :label="$t('gnet.noteDispatcher')"
                rows="2"
                clearable
                :error-messages="errors"></v-textarea
            ></validation-provider>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12" class="d-flex justify-end">
            <button-submit :failed="loading || failed" :loading="loading" buttonText="buttons.send"></button-submit>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'GnetInfo',
  components: { ButtonSubmit },
  props: ['editedDrivingItem'],
  data: () => ({
    loading: false,
    gnetData: {
      requester_id: '',
      preferred_vehicle_type: '',
      reservation_type: '',
      run_type: '',
      trip_duration_minutes: '',
      price: '',
      currency: '',
      note_driver: '',
      note_dispatcher: '',
    },
    partners: [],
    preferredVehicleTypes: [
      'SEDAN_LUX',
      'SEDAN_CORP',
      'SEDAN',
      'SEDAN_HYBRID',
      'SUV_LUX',
      'SUV',
      'SUV_CORP',
      'SUV_SPECIALTY',
      'SPECIALTY',
      'LIMO',
      'VAN_MINI',
      'VAN_CARGO',
      'VAN_CORP',
      'SPRINTER',
      'BUS_MINI',
      'BUS',
      'COACH',
      'GREETER',
    ],
    reservationTypes: ['REGULAR', 'ONDEMAND', 'QUOTE'],
    runTypes: ['TRANSPORT', 'AIRPORT', 'HOURLY'],
  }),
  mounted() {},
  created() {
    this.loadAllPartners();
  },
  computed: {},
  methods: {
    async loadAllPartners() {
      await this.$store.dispatch('gnet/getAllPartners').then((res) => {
        this.partners = res.data;
      });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('driving_id', this.editedDrivingItem?.id);
      formData.append('requester_id', this.gnetData.requester_id ? this.gnetData.requester_id : '');
      formData.append(
        'preferred_vehicle_type',
        this.gnetData.preferred_vehicle_type ? this.gnetData.preferred_vehicle_type : ''
      );
      formData.append('reservation_type', this.gnetData.reservation_type ? this.gnetData.reservation_type : '');
      formData.append('run_type', this.gnetData.run_type ? this.gnetData.run_type : '');
      formData.append(
        'trip_duration_minutes',
        this.gnetData.trip_duration_minutes && this.gnetData.run_type == 'HOURLY'
          ? this.gnetData.trip_duration_minutes
          : ''
      );
      formData.append('price', this.gnetData.price ? this.gnetData.price : '');
      formData.append('currency', this.gnetData.currency ? this.gnetData.currency : '');
      formData.append('note_driver', this.gnetData.note_driver ? this.gnetData.note_driver : '');
      formData.append('note_dispatcher', this.gnetData.note_dispatcher ? this.gnetData.note_dispatcher : '');

      return formData;
    },

    async sendDrivingToGnet() {
      this.loading = true;

      let formData = this.createFormData();

      await this.$store
        .dispatch('gnet/sendDrivingToGnet', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.$emit('closeGnetModal');
          this.$emit('closeDrivingModal');
          // this.$emit('loadAllDrivings');
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch((error) => {
          this.gnetIdValid = false;

          this.$store
            .dispatch('errorMessages/errorMapper', error.response.data.message)
            .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {},
};
</script>
