import { getHttpClient } from './client';

export const getAllClients = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/get-all-clients';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString, 'POST').then((response) => response.data);
};

export const saveClient = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client', 'POST', data).then((response) => response.data);
};

export const updateClient = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client', 'POST', data).then((response) => response.data);
};

export const deleteClient = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const changeClientActiveStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client-active/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};
