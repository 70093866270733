import { getDrivingsData, getDriversData, requestDriverLocation, updateDrivingFromMap } from '@/api/gpsMapAPI';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getDrivingsData(state, item) {
      return getDrivingsData(item);
    },

    async getDriversData(state, item) {
      return getDriversData(item);
    },

    async requestDriverLocation(state, item) {
      return requestDriverLocation(item);
    },

    async updateDrivingFromMap(state, item) {
      return updateDrivingFromMap(item);
    },
  },
};
