import { getHttpClient } from './client';

export const loadDrivingDetails = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/driving-details/';
  return await getHttpClient(baseUrl + data.driving_id).then((response) => response.data);
};

export const getAllVehicleClasses = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/vehicle-class';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveDriving = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer/save-driving-and-proposal', 'POST', data).then(
    (response) => response.data
  );
};

export const cancelDriving = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/cancel-driving/' + data.id;
  return await getHttpClient(baseUrl, 'POST').then((response) => response.data);
};

export const getAllInvoices = async () => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/invoice';
  return await getHttpClient(baseUrl).then((response) => response.data);
};
