import { deleteRebate, getAllRebates, getRebate, saveRebate, updateRebate } from '@/api/rebates';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllRebates(state, item) {
      return getAllRebates(item);
    },
    async getRebate(state, item) {
      return getRebate(item);
    },
    async saveRebate(state, item) {
      return saveRebate(item);
    },
    async updateRebate(state, item) {
      return updateRebate(item);
    },
    async deleteRebate(state, item) {
      return deleteRebate(item);
    },
  },
  getters: {},
};
