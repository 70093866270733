import {
  downloadGeneralDataPDF,
  generalReportData,
  getProfitabilityQuarterReport,
  getProfitabilityYearReport,
  getUtilizationQuarterReport,
  getUtilizationYearReport,
  getClientsQuarterReport,
  getClientsYearReport,
} from '@/api/statistic';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getReportGeneralData(state, item) {
      return generalReportData(item);
    },
    getProfitabilityQuarterReport(state, item) {
      return getProfitabilityQuarterReport(item);
    },

    getProfitabilityYearReport(state, item) {
      return getProfitabilityYearReport(item);
    },

    getUtilizationQuarterReport(state, item) {
      return getUtilizationQuarterReport(item);
    },

    getUtilizationYearReport(state, item) {
      return getUtilizationYearReport(item);
    },

    getClientsQuarterReport(state, item) {
      return getClientsQuarterReport(item);
    },

    getClientsYearReport(state, item) {
      return getClientsYearReport(item);
    },

    async downloadGeneralDataPDF(state, item) {
      return downloadGeneralDataPDF(item);
    },
  },
};
