import Vue from 'vue';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import i18n from '@/i18n/i18n';
import store from '@/store';
import {
  alpha,
  alpha_dash,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  digits,
  dimensions,
  email,
  excluded,
  ext,
  image,
  oneOf,
  max,
  max_value,
  mimes,
  min,
  min_value,
  numeric,
  regex,
  required,
  required_if,
  size,
  double,
} from 'vee-validate/dist/rules';
import me from '@/validations/me.json';
import de from 'vee-validate/dist/locale/de.json';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import fr from 'vee-validate/dist/locale/fr.json';
import hr from 'vee-validate/dist/locale/hr.json';
import it from 'vee-validate/dist/locale/it.json';
import nl from 'vee-validate/dist/locale/nl.json';
import pt from 'vee-validate/dist/locale/pt_PT.json';
import ru from 'vee-validate/dist/locale/ru.json';
import sl from 'vee-validate/dist/locale/sl.json';
import sr from 'vee-validate/dist/locale/sr.json';
import tr from 'vee-validate/dist/locale/tr.json';
import meTranslations from '@/locales/me';
import deTranslations from '@/locales/de';
import enTranslations from '@/locales/en';
import esTranslations from '@/locales/es';
import frTranslations from '@/locales/fr';
import hrTranslations from '@/locales/hr';
import itTranslations from '@/locales/it';
import nlTranslations from '@/locales/nl';
import ptTranslations from '@/locales/pt';
import ruTranslations from '@/locales/ru';
import slTranslations from '@/locales/sl';
import srTranslations from '@/locales/sr';
import trTranslations from '@/locales/tr';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

localize({
  me: {
    messages: {
      ...me.messages,
      date_compare: 'Polje vrijeme mora biti poslije vrijeme od',
      range: 'Ovaj broj naloga već postoji',
      compare: 'Maksimum mora biti veći od minumuma',
      numeric_decimal: 'Polje {_field_} mora biti broj (može i decimalni)',
      date_format: 'Polje {_field_} mora biti u formatu MM/GG',
      date_in_past: 'Polje {_field_} mora biti validno',
      credit_card: 'Polje {_field_} mora biti važeći broj kreditne kartice',
    },
    names: {
      ...meTranslations.names,
    },
  },
  en: {
    messages: {
      ...en.messages,
      date_compare: 'The time to field must be after time from',
      range: 'This number of drivings is already taken',
      compare: 'Maximum must be bigger than minimum',
      numeric_decimal: 'The {_field_} field may only contain numeric characters (decimals allowed)',
      date_format: 'The {_field_} field must be in the format MM/YY',
      date_in_past: 'The {_field_} field must be valid',
      credit_card: 'The {_field_} field must be a valid credit card number',
    },
    names: {
      ...enTranslations.names,
    },
  },
  de: {
    messages: {
      ...de.messages,
      date_compare: 'Das Feld Bis-Datum muss nach dem Von-Datum liegen',
      range: 'Diese Anzahl von Fahrten ist bereits vergeben',
      compare: 'Das Maximum muss größer sein als das Minimum',
      numeric_decimal: 'Das Feld {_field_} darf nur numerische Zeichen enthalten (Dezimalstellen sind erlaubt)',
      date_format: 'Das Feld {_field_} muss im Format MM/JJ sein',
      date_in_past: 'Das Feld {_field_} muss gültig sein',
      credit_card: 'Das Feld {_field_} muss eine gültige Kreditkartennummer sein',
    },
    names: {
      ...deTranslations.names,
    },
  },
  es: {
    messages: {
      ...es.messages,
      date_compare: 'El campo Hasta debe ser posterior al campo Desde',
      range: 'Este número de viajes ya está ocupado',
      compare: 'El máximo debe ser mayor que el mínimo',
      numeric_decimal: 'El campo {_field_} solo puede contener caracteres numéricos (se permiten decimales)',
      date_format: 'El campo {_field_} debe estar en el formato MM/AA',
      date_in_past: 'El campo {_field_} debe ser válido',
      credit_card: 'El campo {_field_} debe ser un número de tarjeta de crédito válido',
    },
    names: {
      ...esTranslations.names,
    },
  },
  fr: {
    messages: {
      ...fr.messages,
      date_compare: "Le champ Jusqu'à doit être postérieur au champ De",
      range: 'Ce nombre de trajets est déjà pris',
      compare: 'Le maximum doit être supérieur au minimum',
      numeric_decimal:
        'Le champ {_field_} ne peut contenir que des caractères numériques (les décimales sont autorisées)',
      date_format: 'Le champ {_field_} doit être au format MM/AA',
      date_in_past: 'Le champ {_field_} doit être valide',
      credit_card: 'Le champ {_field_} doit être un numéro de carte de crédit valide',
    },
    names: {
      ...frTranslations.names,
    },
  },
  hr: {
    messages: {
      ...hr.messages,
      date_compare: 'Polje Do mora biti nakon polja Od',
      range: 'Ovaj broj vožnji već je zauzet',
      compare: 'Maksimum mora biti veći od minimuma',
      numeric_decimal: 'Polje {_field_} smije sadržavati samo brojčane znakove (dozvoljeni decimalni brojevi)',
      date_format: 'Polje {_field_} mora biti u formatu MM/GG',
      date_in_past: 'Polje {_field_} mora biti valjano',
      credit_card: 'Polje {_field_} mora biti važeći broj kreditne kartice',
    },
    names: {
      ...hrTranslations.names,
    },
  },
  it: {
    messages: {
      ...it.messages,
      date_compare: 'Il campo A deve essere successivo al campo Da',
      range: 'Questo numero di ordini di guida è già stato preso',
      compare: 'Il massimo deve essere maggiore del minimo',
      numeric_decimal: 'Il campo {_field_} può contenere solo caratteri numerici (sono consentiti decimali)',
      date_format: 'Il campo {_field_} deve essere nel formato MM/AA',
      date_in_past: 'Il campo {_field_} deve essere valido',
      credit_card: 'Il campo {_field_} deve essere un numero di carta di credito valido',
    },
    names: {
      ...itTranslations.names,
    },
  },
  nl: {
    messages: {
      ...nl.messages,
      date_compare: 'Het veld Tot moet na het veld Van zijn',
      range: 'Dit aantal ritten is al in gebruik',
      compare: 'Het maximum moet groter zijn dan het minimum',
      numeric_decimal: 'Het veld {_field_} mag alleen numerieke tekens bevatten (decimalen toegestaan)',
      date_format: 'Het veld {_field_} moet in het formaat MM/JJ zijn',
      date_in_past: 'Het veld {_field_} moet geldig zijn',
      credit_card: 'Het veld {_field_} moet een geldig creditcardnummer zijn',
    },
    names: {
      ...nlTranslations.names,
    },
  },
  pt: {
    messages: {
      ...pt.messages,
      date_compare: 'O campo Até deve ser posterior ao campo De',
      range: 'Este número de corridas já está em uso',
      compare: 'O máximo deve ser maior do que o mínimo',
      numeric_decimal: 'O campo {_field_} só pode conter caracteres numéricos (decimais permitidos)',
      date_format: 'O campo {_field_} deve estar no formato MM/AA',
      date_in_past: 'O campo {_field_} deve ser válido',
      credit_card: 'O campo {_field_} deve ser um número de cartão de crédito válido',
    },
    names: {
      ...ptTranslations.names,
    },
  },
  ru: {
    messages: {
      ...ru.messages,
      date_compare: 'Поле До должно быть после поля С',
      range: 'Это количество поездок уже занято',
      compare: 'Максимальное значение должно быть больше минимального',
      numeric_decimal: 'Поле {_field_} может содержать только числовые символы (разрешены десятичные)',
      date_format: 'Поле {_field_} должно быть в формате MM/ГГ',
      date_in_past: 'Поле {_field_} должно быть действительным',
      credit_card: 'Поле {_field_} должно быть действительным номером кредитной карты',
    },
    names: {
      ...ruTranslations.names,
    },
  },
  sl: {
    messages: {
      ...sl.messages,
      date_compare: 'Čas do mora biti po času od',
      range: 'To število voženj je že zasedeno',
      compare: 'Maksimum mora biti večji od minimuma',
      numeric_decimal: 'Polje {_field_} sme vsebovati samo numerične znake (dovoljena decimalna mesta)',
      date_format: 'Polje {_field_} mora biti v formatu MM/LL',
      date_in_past: 'Polje {_field_} mora biti veljavno',
      credit_card: 'Polje {_field_} mora biti veljavna številka kreditne kartice',
    },
    names: {
      ...slTranslations.names,
    },
  },
  sr: {
    messages: {
      ...sr.messages,
      date_compare: 'Поље До мора бити након поља Од',
      range: 'Овакав број возњи већ постоји',
      compare: 'Максимум мора бити већи од минимума',
      numeric_decimal: 'Поље {_field_} може садржавати само нумеричке карактере (дозвољени децимали)',
      date_format: 'Поље {_field_} мора бити у формату MM/ГГ',
      date_in_past: 'Поље {_field_} мора бити валидно.',
      credit_card: 'Поље {_field_} мора бити важећи број кредитне картице',
    },
    names: {
      ...srTranslations.names,
    },
  },
  tr: {
    messages: {
      ...tr.messages,
      date_compare: 'Bitiş alanı Başlangıç alanından sonra olmalıdır',
      range: 'Bu kadar sürüş zaten alındı',
      compare: 'Maksimum, minimumdan büyük olmalıdır',
      numeric_decimal: '{_field_} alanı yalnızca sayısal karakterler içerebilir (ondalıklar izinli)',
      date_format: '{_field_} alanı MM/YY formatında olmalıdır',
      date_in_past: '{_field_} alanı geçerli olmalıdır',
      credit_card: '{_field_} alanı geçerli bir kredi kartı numarası olmalıdır',
    },
    names: {
      ...trTranslations.names,
    },
  },
});

extend('alpha', alpha);
extend('alpha_dash', alpha_dash);
extend('alpha_num', alpha_num);
extend('alpha_spaces', alpha_spaces);
extend('between', between);
extend('confirmed', confirmed);
extend('digits', digits);
extend('dimensions', dimensions);
extend('email', email);
extend('excluded', excluded);
extend('ext', ext);
extend('image', image);
extend('oneOf', oneOf);
extend('max', max);
extend('max_value', max_value);
extend('mimes', mimes);
extend('min', min);
extend('min_value', min_value);
extend('numeric', numeric);
extend('regex', regex);
extend('required', required);
extend('required_if', required_if);
extend('size', size);
extend('double', double);

// Define a custom validation rule to compare two dates
extend('date_compare', {
  validate(value, { otherDate }) {
    if (!value || !otherDate) {
      return false; // Both dates must be provided for comparison
    }

    const date1 = formatDate(value);
    const date2 = formatDate(otherDate);

    return date2 <= date1; // Return true if date1 is earlier than date2
  },
  params: ['otherDate'], // Define the parameter names used in the rule
});

const formatDate = (date) => {
  const [day, month, year] = date.split('-').map(Number);

  // Month is zero-based in JavaScript, so subtract 1 from it
  return new Date(year, month - 1, day);
};

// Define a custom validation rule to validate ranges
extend('range', {
  validate(value, { rebates, index }) {
    for (let i = 0; i < rebates.length; i++) {
      if (value >= rebates[i].min && value <= rebates[i].max && i !== index) {
        return false;
      }
    }

    return true;
  },
  params: ['rebates', 'index'], // Define the parameter names used in the rule
});

// Define a custom validation rule to compare two ranges
extend('compare', {
  validate(value, { min, max }) {
    if (min >= max) {
      return false;
    }

    return true;
  },
  params: ['min', 'max'], // Define the parameter names used in the rule
});

// Define a custom validation rule to check double number
extend('numeric_decimal', {
  ...numeric, // Use the built-in numeric rule as a base
  validate(value) {
    // Use a regular expression to allow decimal numbers
    if (/^\d+(\.\d{1,2})?$/.test(value)) {
      return {
        valid: true,
      };
    }

    return {
      valid: false,
      message: 'Please enter a valid number.',
    };
  },
});

// Define a custom validation rule for date format MM/YY
extend('date_format', {
  validate(value) {
    // Regular expression to match MM/YY format
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    return regex.test(value);
  },
});

// Define a custom validation rule for date not in past
extend('date_in_past', {
  validate(value) {
    // Parse the MM/YY into a date object
    const [month, year] = value.split('/').map(Number);

    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so we add 1
    const currentYear = currentDate.getFullYear() % 100; // get last two digits of the year

    // Check if the year is greater than the current year
    if (year > currentYear) {
      return true;
    }

    // If the year is the same as the current year, check if the month is greater or equal to the current month
    if (year === currentYear && month >= currentMonth) {
      return true;
    }

    // If the date is not valid (in the past)
    return false;
  },
});

// Define a custom validation rule for card number with spaces
extend('credit_card', {
  validate(value) {
    // Remove all spaces for validation
    const sanitizedValue = value.replace(/\s+/g, '');

    // Define valid lengths for different card types
    const validLengths = [14, 15, 16, 19];

    // Check if the length of the sanitized value is valid
    if (!validLengths.includes(sanitizedValue.length)) {
      return false;
    }

    // Luhn Algorithm for validating the card number
    const luhnCheck = (num) => {
      let sum = 0;
      let shouldDouble = false;

      // Traverse the number from right to left
      for (let i = num.length - 1; i >= 0; i--) {
        let digit = parseInt(num.charAt(i), 10);

        if (shouldDouble) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }

        sum += digit;
        shouldDouble = !shouldDouble;
      }

      // Valid if the sum is divisible by 10
      return sum % 10 === 0;
    };

    // Validate using the Luhn algorithm
    if (!luhnCheck(sanitizedValue)) {
      return false;
    }

    // Validate specific card types if needed
    const firstDigits = sanitizedValue.slice(0, 6);

    // Add checks for specific test card types if needed
    const cardTypes = [
      { type: 'American Express', pattern: /^3[47]/, length: 15 },
      { type: 'Visa', pattern: /^4/, length: 16 },
      { type: 'MasterCard', pattern: /^5[1-5]/, length: 16 },
      { type: 'Discover', pattern: /^6(?:011|5)/, length: 16 },
      { type: 'Diners Club', pattern: /^3(?:0[0-5]|[68])/, length: 14 },
      { type: 'JCB', pattern: /^35/, length: 16 },
      { type: 'Maestro', pattern: /^(?:5018|5020|5038|6304|6759|676[1-3])/, length: [12, 19] }, // Maestro can vary in length
    ];

    const cardType = cardTypes.find((card) => card.pattern.test(firstDigits));

    if (cardType && Array.isArray(cardType.length)) {
      return cardType.length.includes(sanitizedValue.length);
    } else if (cardType) {
      return sanitizedValue.length === cardType.length;
    }

    return true; // Passes all checks
  },
});

localize(i18n.locale);

store.watch(
  (state) => state.language.currentLanguage,
  (newLanguage) => {
    localize(newLanguage);
  }
);
