import {
  getAllTransactions,
  getTransaction,
  saveTransaction,
  updateTransaction,
  deleteTransaction,
  getCurrentSubscription,
} from '@/api/transactions';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllTransactions(state, item) {
      return getAllTransactions(item);
    },
    async getTransaction(state, item) {
      return getTransaction(item);
    },
    async saveTransaction(state, item) {
      return saveTransaction(item);
    },
    async updateTransaction(state, item) {
      return updateTransaction(item);
    },
    async deleteTransaction(state, item) {
      return deleteTransaction(item);
    },
    async getCurrentSubscription(state, item) {
      return getCurrentSubscription(item);
    },
  },
  getters: {},
};
