import { getHttpClient } from './client';

export const getAllPartners = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer').then((response) => response.data);
};

export const getPartner = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer/' + data.id).then((response) => response.data);
};

export const savePartner = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer', 'POST', data).then((response) => response.data);
};

export const UpdatePartner = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer', 'POST', data).then((response) => response.data);
};

export const deletePartner = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
