<template>
  <div>
    <v-dialog v-model="cancelDrivingDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{ $t('driving.cancelDriving') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary cancel" text @click="cancelDrivingDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="cancelDriving" :disabled="loadingCancel" :loading="loadingCancel">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectedOpen"
      :activator="selectedElement"
      :close-on-content-click="false"
      max-width="800px"
      offset-x
      :key="selectedOpen">
      <v-card color="white lighten-4" flat min-width="350px">
        <div class="sticky-header">
          <v-toolbar class="primary" dark>
            <v-icon v-if="editedDrivingItem.id" :color="editedDrivingItem.color" class="me-2 status-circle"
              >mdi-circle</v-icon
            >
            <v-toolbar-title v-model="editedDrivingItem.number"
              >{{ editedDrivingItem.number || $t(formTitle) }}
              {{ editedDrivingItem.id ? '-' : '' }}
              {{ getStatus() }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <template>
              <v-menu offset-y :key="disable">
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.options') }}
                    </span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="duplicate">
                        <v-list-item-title v-bind="attrs" v-on="on">
                          {{ $t('buttons.duplicate') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.duplicate') }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="showMarkAsCanceled" left>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="display: block" v-bind="attrs" v-on="on">
                        <v-list-item
                          @click="cancelDrivingDialog = !cancelDrivingDialog"
                          :disabled="getStatusId() === 2">
                          <v-list-item-title>
                            {{ $t('buttons.cancelDriving') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="getStatusId() === 2">
                      {{ $t('tooltips.cannotMarkAsCanceled') }}
                    </span>
                    <span v-else>
                      {{ $t('tooltips.cancelDriving') }}
                    </span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>

            <v-icon class="mdi mdi-close mx-1" style="color: white" @click="selectedOpen = false"></v-icon>
          </v-toolbar>
        </div>
        <validation-observer ref="drivingForm" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(saveDriving)">
            <div class="sticky-header tabs d-flex align-center">
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" class="d-sm-flex align-center">
                    <v-tabs v-model="activeTab" scrollable show-arrows :key="activeTab">
                      <v-tab :disabled="failed && activeTab === 1" @click.prevent="selectTab(0)">
                        {{ $t('driving.general') }}
                      </v-tab>
                      <v-tab :disabled="failed && activeTab === 0" @click.prevent="selectTab(1)">
                        {{ $t('driving.checkpoints') }}
                      </v-tab>
                      <v-tab :disabled="failed && [0, 1].includes(activeTab)" @click.prevent="selectTab(2)">
                        {{ $t('driving.passengers') }}
                      </v-tab>
                      <v-tab :disabled="failed && [0, 1].includes(activeTab)" @click.prevent="selectTab(3)">
                        {{ $t('driving.attachments') }}
                      </v-tab>
                    </v-tabs>
                    <div
                      ref="testRef"
                      cols="12"
                      sm="2"
                      v-if="[0, 1, 2, 3].includes(activeTab) && !disable"
                      class="d-flex align-center justify-end pr-4 mt-5 mt-sm-0">
                      <button-submit
                        :failed="disable || failed"
                        :loading="loadingDriving"
                        buttonText="buttons.save"></button-submit>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="scrollable-content">
              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" hidden md="6" sm="12">
                          <v-text-field v-model="editedDrivingItem.id" :label="$t('driving.id')"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="12">
                          <gmap-autocomplete
                            :options="googleMapsOptions"
                            :setFieldsTo="GoogleApiFields"
                            :value="editedDrivingItem.from_location"
                            selectFirstOnEnter
                            @place_changed="selectFromAddress">
                            <template v-slot:default="slotProps">
                              <validation-provider rules="required" v-slot="{ errors }" name="location_from">
                                <v-text-field
                                  ref="input"
                                  clearable
                                  :autofocus="editedDrivingItem.from_location_name ? false : true"
                                  v-model="editedDrivingItem.from_location_name"
                                  :disabled="disable"
                                  :label="$t('driving.locationFrom')"
                                  @focusout="selectFromAddressFocusout"
                                  v-on:attrs="slotProps.attrs"
                                  v-on:listeners="slotProps.listeners"
                                  :error-messages="errors"
                                  @keydown.enter.prevent
                                  >-
                                </v-text-field>
                              </validation-provider>
                            </template>
                          </gmap-autocomplete>
                        </v-col>
                        <v-col
                          v-if="editedDrivingItem.driving_type_id === 1 || transferOrDaily === 1"
                          cols="12"
                          lg="6"
                          md="6"
                          sm="12">
                          <gmap-autocomplete
                            :options="googleMapsOptions"
                            :setFieldsTo="GoogleApiFields"
                            :value="editedDrivingItem.to_location"
                            selectFirstOnEnter
                            slot-ref-name="input2"
                            @place_changed="selectToAddress">
                            <template v-slot:default="slotProps">
                              <validation-provider rules="required" v-slot="{ errors }" name="location_to">
                                <v-text-field
                                  ref="input2"
                                  clearable
                                  v-model="editedDrivingItem.to_location_name"
                                  :disabled="disable"
                                  :label="$t('driving.locationTo')"
                                  @focusout="selectToAddressFocusout"
                                  v-on:attrs="slotProps.attrs"
                                  v-on:listeners="slotProps.listeners"
                                  :error-messages="errors"
                                  @keydown.enter.prevent
                                  >-
                                </v-text-field>
                              </validation-provider>
                            </template>
                          </gmap-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-autocomplete
                            v-model="editedDrivingItem.vehicle_class_id"
                            :disabled="disable"
                            :items="filteredVehicleClasses"
                            clearable
                            item-text="name"
                            item-value="id"
                            :label="$t('driving.vehicleClass')"
                            :no-data-text="$t('select.noDataAvailable')"
                            @focus="$event.target.click()"></v-autocomplete>
                        </v-col>
                        <v-col v-if="editedDrivingItem.id" cols="12" lg="6" md="6" sm="12">
                          <v-text-field
                            v-model="editedDrivingItem.vehicle_name"
                            clearable
                            :disabled="true"
                            :label="$t('driving.vehicle')" />
                        </v-col>
                        <v-col v-if="editedDrivingItem.id" cols="12" lg="6" md="6" sm="12">
                          <v-text-field
                            v-model="editedDrivingItem.driver"
                            clearable
                            :disabled="true"
                            :label="$t('driving.driver')" />
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-menu
                            ref="pickupTime"
                            v-model="pickupTime"
                            :close-on-content-click="false"
                            :return-value.sync="editedDrivingItem.pickup_time"
                            min-width="auto"
                            offset-y
                            transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider rules="required" v-slot="{ errors }" name="pickup_time">
                                <v-text-field
                                  v-model="editedDrivingItem.pickup_time"
                                  clearable
                                  :disabled="disable"
                                  :label="$t('driving.pickupTime')"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </template>
                            <DatePicker
                              v-model="editedDrivingItem.pickup_time"
                              :minute-increment="1"
                              :model-config="modelConfig"
                              class="no-border"
                              is24hr
                              mode="dateTime">
                            </DatePicker>
                            <div class="date-picker-buttons">
                              <v-btn class="primary cancel mr-2" elevation="2" text @click="pickupTime = false">
                                {{ $t('buttons.cancel') }}
                              </v-btn>
                              <v-btn class="primary mr-2" elevation="2" text @click="updatePickupTime">
                                {{ $t('buttons.confirm') }}
                              </v-btn>
                            </div>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
                            <v-textarea
                              v-model="editedDrivingItem.note_dispatcher"
                              :disabled="disable"
                              clearable
                              counter="1000"
                              :label="$t('driving.noteDispatcher')"
                              rows="2"
                              :error-messages="errors"></v-textarea>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <add-checkpoints
                    :editedDrivingItem="editedDrivingItem"
                    :checkpoints="checkpoints"
                    :headers="checkpointsHeaders"
                    :disable="disable"
                    :googleMapsOptions="googleMapsOptions"
                    :GoogleApiFields="GoogleApiFields"
                    :timeModelConfig="timeModelConfig"
                    @updateCheckpoints="updateCheckpoints"
                    @deleteCheckpoint="deleteCheckpoint" />
                </v-tab-item>

                <v-tab-item>
                  <add-passengers
                    :editedDrivingItem="editedDrivingItem"
                    :passengers="passengers"
                    :headers="passengersHeaders"
                    :disable="disable"
                    @updatePassengers="updatePassengers"
                    @deletePassenger="deletePassenger" />
                </v-tab-item>

                <v-tab-item>
                  <add-files
                    :editedItem="editedDrivingItem"
                    :attachments="editedDrivingItem.attachments"
                    :filesToSend="filesToSend"
                    :disable="disable"
                    deleteRoute="drivings/deleteDrivingAttachment"
                    @addNewFile="addNewFile"
                    @deleteNewFile="deleteNewFile"
                    @deleteOldFile="deleteOldFile" />
                </v-tab-item>
              </v-tabs-items>

              <v-card-text
                color="white"
                class="pt-0"
                v-if="editedDrivingItem?.created_at && editedDrivingItem?.userCreated?.username">
                <v-container>
                  <v-row>
                    <v-col class="py-0 pe-0">
                      <p
                        class="mb-0 mt-1 text-right d-block smaller-font"
                        v-html="
                          $t('driving.createdBy', {
                            time: editedDrivingItem?.created_at,
                            user: editedDrivingItem?.userCreated?.username,
                          })
                        "></p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </div>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <div class="tourStyle">
      <v-tour name="myTour" :steps="stepsTour"></v-tour>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { checkpointsHeaders } from '@/mixins/data-table-headers';
import { passengersHeaders } from '@/mixins/data-table-headers';
import { defaultDriving, defaultDrivingComment } from '@/mixins/default-items';
import AddFiles from '@/components/AddFiles.vue';
import AddPassengers from '@/components/drivings/AddPassengers.vue';
import AddCheckpoints from '@/components/drivings/AddCheckpoints.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { DatePicker } from 'v-calendar/src/components';
import { formatDateTime, formatDateTimeToDDMMHHmm } from '@/utils/formatDate';
import store from '@/store';

export default {
  name: 'DrivingModal',
  components: {
    DatePicker,
    AddFiles,
    AddPassengers,
    AddCheckpoints,
    ButtonSubmit,
  },
  props: ['selectedElement', 'transferOrDaily'],
  data: () => ({
    activeTab: 0,
    value: '',
    driving: {},
    selectedOpen: false,
    drivingFormValid: true,
    drivingCommentFormValid: true,
    editedDrivingItem: { attachments: [], checkpoints: [], passengers: [] },
    editedDrivingComment: {},
    enableDrivingCommentSave: true,
    enableDrivingSave: true,
    vehicleClasses: [],
    filteredVehicleClasses: [],
    vehicle: {},
    formTitle: 'driving.newTransfer',
    vehicles: [],
    GoogleApiFields: ['name', 'formatted_address', 'geometry', 'place_id', 'plus_code', 'url'],
    pickupTime: null,
    expectedDropOffTime: null,
    expectedComebackTime: null,
    confirmationUntil: null,
    expectedDuration: null,
    cancelDrivingDialog: false,
    panel: [1],
    disable: true,
    googleMapsOptions: {
      bounds: {
        north: 45.830329,
        south: 41.51274,
        east: 20.834725,
        west: 16.332208,
      },
      // componentRestrictions: {country: ["me", "hr", "rs", "ba", "al"]},
      strictBounds: false,
    },
    min: null,
    max: null,
    modelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY HH:mm'
          : 'DD-MM-YYYY HH:mm',
    },
    timeModelConfig: {
      type: 'string',
      mask: 'HH:mm',
    },
    filesToSend: [],
    checkpoints: [],
    passengers: [],
    loadingDriving: false,
    loadingComment: false,
    loadingCancel: false,
    loadingDelete: false,
    stepsTour: [],
    drivingCloning: false,
    user: {},
  }),
  mounted() {},
  created() {
    this.editedDrivingItem = Object.assign({}, defaultDriving);
    this.editedDrivingComment = Object.assign({}, defaultDrivingComment);
    this.filesToSend = [];
    this.checkpoints = [];
    this.passengers = [];
    this.getAllVehicleClasses();

    this.editedDrivingItem.driving_type_id = this.transferOrDaily;
    if (this.transferOrDaily === 1) {
      this.formTitle = 'driving.newDriving';
    } else {
      this.formTitle = 'driving.newDailyRent';
    }

    this.user = this.$store.getters['auth/user'];
  },
  computed: {
    checkpointsHeaders() {
      return checkpointsHeaders(i18n);
    },
    passengersHeaders() {
      return passengersHeaders(i18n);
    },
    showMarkAsCanceled() {
      const proposal = this.editedDrivingItem?.drivingProposal;
      return (
        this.editedDrivingItem?.id &&
        proposal &&
        (proposal.drivingProposalStatus === 'Pending' || proposal.drivingProposalStatus === 'Accepted')
      );
    },
  },
  methods: {
    async getAllVehicleClasses(id) {
      await this.$store
        .dispatch('customers/getAllVehicleClasses', {
          vehicle_class_id: id,
        })
        .then((res) => {
          this.vehicleClasses = res.data;
        });

      this.filterAllVehicleClasses();
    },

    filterAllVehicleClasses() {
      this.filteredVehicleClasses = this.vehicleClasses?.filter((item) => {
        if (item.active == '1' || item?.id === this.editedDrivingItem?.vehicle_class_id) {
          return item;
        }
      });
    },

    showEvent(event) {
      if (!event.id) {
        this.disable = false;
      }
      this.panel = [];

      const user = this.$store.getters['auth/user'];

      if (event?.id) {
        this.selectedOpen = true;
        this.editedDrivingItem = Object.assign({}, event);
        this.editedDrivingComment = Object.assign({}, defaultDrivingComment);
        this.editedDrivingComment.driving_id = parseInt(event.id);
        this.editedDrivingItem.to_location = JSON.parse(event?.to_location);
        this.editedDrivingItem.from_location = event.from_location ? JSON.parse(event.from_location) : null;
        this.editedDrivingItem.to_location_name = this.editedDrivingItem.to_location?.name;
        this.editedDrivingItem.from_location_name = this.editedDrivingItem.from_location?.name;
        this.editedDrivingItem.vehicle_class_id = this.editedDrivingItem?.vehicleClass?.id
          ? parseInt(this.editedDrivingItem?.vehicleClass?.id)
          : this.editedDrivingItem?.vehicle?.vehicleClass?.id
          ? parseInt(this.editedDrivingItem?.vehicle?.vehicleClass?.id)
          : null;

        this.editedDrivingItem.driver = event.drivingProposal?.driver
          ? event.drivingProposal?.driver?.first_name +
            ' ' +
            event.drivingProposal?.driver?.last_name +
            ' (' +
            event.drivingProposal?.driver?.phone +
            ')'
          : null;

        this.editedDrivingItem.driving_type_id = parseInt(event.drivingType?.id);

        this.editedDrivingItem.vehicle_name = event.vehicle?.name;
        this.filesToSend = [];

        this.loadDrivingDetails();

        this.disable = true;

        this.editedDrivingItem.distance_unit = user?.organisation?.organisation_settings?.distance_unit;
      } else {
        this.editedDrivingItem = Object.assign({}, { ...this.selectedElement, driving_type_id: this.transferOrDaily });

        this.disable = false;
        this.transferOrDailyDialog = true;

        this.editedDrivingItem.distance_unit = user?.organisation?.organisation_settings?.distance_unit;
      }
    },

    async loadDrivingDetails() {
      this.$store
        .dispatch('customers/loadDrivingDetails', { driving_id: this.editedDrivingItem.id })
        .then((res) => {
          this.editedDrivingItem.attachments = res.data.attachments;
          this.checkpoints = res.data.drivingCheckpoints.map((item) => {
            return {
              ...item,
              checkpoint_location: JSON.parse(item?.location),
              checkpoint_location_name: JSON.parse(item?.location)?.name ?? JSON.parse(item?.location)?.description,
              geometry: JSON.parse(item?.location)?.geometry,
            };
          });
          this.passengers = res.data.passengers;
        })
        .catch(() =>
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('snackbar.anErrorOccured'),
            color: 'red',
          })
        );
    },

    async selectTab(tab) {
      // if view mode, normally switch tabs
      if (this.disable) {
        this.activeTab = tab;
      } else {
        // if edit mode and first tab active, validate form before switching tabs
        if (this.activeTab == 0) {
          // to prevent changing tab without pickup_time, if confirm is not clicked on date picker modal
          if (this.editedDrivingItem.pickup_time) {
            this.updatePickupTime();
          }
          if (await this.$refs.drivingForm.validate()) {
            this.activeTab = tab;
          } else {
            this.activeTab = 0;
          }
          // if edit mode and second tab active, validate form before switching tabs
        } else if (this.activeTab == 1) {
          if (await this.$refs.drivingForm.validate()) {
            this.activeTab = tab;
          } else {
            this.activeTab = 1;
          }
        } else {
          this.activeTab = 0;
        }
      }
    },

    getFilteredPassengers(items) {
      // Trim the values in each object
      const trimmedArray = items.map((obj) => {
        const trimmedObj = {};
        for (const key in obj) {
          if (typeof obj[key] === 'string') {
            trimmedObj[key] = obj[key].trim();
          } else {
            trimmedObj[key] = obj[key];
          }
        }
        return trimmedObj;
      });

      const filteredArray = trimmedArray.filter((obj) => {
        // Check if any property in the object is not an empty string
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] !== '') {
            return true; // Keep the object if any property is not empty
          }
        }
        return false; // Remove the object if all properties are empty
      });

      return filteredArray;
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.editedDrivingItem.id ? this.editedDrivingItem.id : '');
      formData.append('number', this.editedDrivingItem.number ? this.editedDrivingItem.number : '');
      formData.append('from_location', JSON.stringify(this.editedDrivingItem.from_location));
      this.editedDrivingItem.driving_type_id === 1 &&
        formData.append('to_location', JSON.stringify(this.editedDrivingItem.to_location));
      formData.append(
        'note_dispatcher',
        this.editedDrivingItem.note_dispatcher ? this.editedDrivingItem.note_dispatcher : ''
      );
      formData.append(
        'pickup_time',
        this.editedDrivingItem.pickup_time ? formatDateTimeToDDMMHHmm(this.editedDrivingItem.pickup_time) : ''
      );

      formData.append(
        'to_location_name',
        this.editedDrivingItem.to_location_name ? this.editedDrivingItem.to_location_name : ''
      );
      formData.append(
        'from_location_name',
        this.editedDrivingItem.from_location_name ? this.editedDrivingItem.from_location_name : ''
      );
      formData.append(
        'driving_type_id',
        this.editedDrivingItem.driving_type_id ? this.editedDrivingItem.driving_type_id : ''
      );
      formData.append(
        'vehicle_class_id',
        this.editedDrivingItem.vehicle_class_id ? this.editedDrivingItem.vehicle_class_id : ''
      );
      this.filesToSend.map((item) => {
        formData.append('attachments[]', item);
      });

      this.checkpoints.map((item) => {
        formData.append('checkpoints[]', JSON.stringify(item));
      });
      formData.append('passengers', JSON.stringify(this.getFilteredPassengers(this.passengers)));
      formData.append('paid', 0);

      return formData;
    },

    saveDriving(doNotCloseModal = false) {
      // to prevent saving without pickup_time, if confirm is not clicked on date picker modal
      if (this.editedDrivingItem.pickup_time) {
        this.updatePickupTime();
      }

      this.enableDrivingSave = false;
      this.loadingDriving = true;

      let formData = this.createFormData();

      this.$store
        .dispatch('customers/saveDriving', formData)
        .then((res) => {
          this.editedDrivingItem.id = res.data.id;
          this.editedDrivingItem.number = res.data?.number;
          this.editedDrivingItem.drivingProposal = { ...res.data.drivingProposal };
          this.editedDrivingItem.color = res.data.color;
          if (doNotCloseModal) {
            this.editedDrivingItem.id = '';
            this.disable = false;
            this.selectedOpen = true;
            this.editedDrivingItem.number = null;
            this.formTitle =
              this.editedDrivingItem?.drivingType?.id == '2' || this.editedDrivingItem.driving_type_id == '2'
                ? 'driving.newDailyRent'
                : 'driving.newDriving';
            this.editedDrivingItem.color = '';
            this.drivingCloning = false;
          } else {
            this.disable = true;
          }

          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });

          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableDrivingSave = true;
          this.loadingDriving = false;
        });
    },
    selectFromAddress(input) {
      this.editedDrivingItem.from_location = input;
      this.editedDrivingItem.from_location_name = input.name + ` (${input.formatted_address})`;
    },
    selectToAddress(input2) {
      this.editedDrivingItem.to_location = input2;
      this.editedDrivingItem.to_location_name = input2.name + ` (${input2.formatted_address})`;
    },

    selectFromAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedDrivingItem.from_location?.name &&
        value !==
          this.editedDrivingItem.from_location?.name + ` (${this.editedDrivingItem.from_location?.formatted_address})`
      ) {
        this.editedDrivingItem.from_location = { name: value };
        this.editedDrivingItem.from_location_name = value;
      }
    },
    selectToAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedDrivingItem.to_location?.name &&
        value !==
          this.editedDrivingItem.to_location?.name + ` (${this.editedDrivingItem.to_location?.formatted_address})`
      ) {
        this.editedDrivingItem.to_location = { name: value };
        this.editedDrivingItem.to_location_name = value;
      }
    },

    updatePickupTime() {
      this.$refs.pickupTime.save(this.editedDrivingItem.pickup_time);
    },

    async cancelDriving() {
      this.loadingCancel = true;
      await this.$store
        .dispatch('customers/cancelDriving', this.editedDrivingItem)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingCanceled'), color: 'green' });

          this.selectedOpen = false;
          // this.$emit('loadAllDrivings');
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.loadingCancel = false;
          this.cancelDrivingDialog = false;
        });
    },

    updateCheckpoints(items) {
      this.checkpoints = [...items];
    },
    deleteCheckpoint(index) {
      this.checkpoints.splice(index, 1);
    },
    updatePassengers(items) {
      this.passengers = [...items];
    },
    deletePassenger(index) {
      this.passengers.splice(index, 1);
    },
    addNewFile(item) {
      this.filesToSend = [...this.filesToSend, item];
    },
    deleteOldFile(index) {
      this.editedDrivingItem.attachments.splice(index, 1);
    },
    deleteNewFile(index) {
      this.filesToSend.splice(index, 1);
    },
    getStatusId() {
      const proposal = this.editedDrivingItem?.drivingProposal;
      let id;
      if (this.editedDrivingItem?.id && proposal) {
        id = parseInt(proposal.driving_proposal_status_id);
      }

      return id;
    },
    getStatus() {
      const drivingProposalStatus = {
        1: 'drivingStatus.pending',
        2: 'drivingStatus.accepted',
        3: 'drivingStatus.rejected',
        4: 'drivingStatus.expired',
        5: 'drivingStatus.canceled',
        6: 'drivingStatus.draft',
        7: 'drivingStatus.done',
        12: 'drivingStatus.noShow',
      };

      const proposal = this.editedDrivingItem?.drivingProposal;
      let id;
      if (this.editedDrivingItem?.id && proposal) {
        id = parseInt(proposal.driving_proposal_status_id);
      }

      return drivingProposalStatus[id] ? i18n.t(drivingProposalStatus[id]) : '';
    },

    startTour() {
      this.$tours['myTour'].start();
      this.toggleTour(true);
    },
    async duplicate() {
      this.drivingCloning = true;
      // Delay the validation process using setTimeout
      setTimeout(async () => {
        if (await this.$refs.drivingForm.validate()) {
          this.saveDriving(true);
        }
      });
    },
  },
  watch: {
    selectedOpen(val) {
      this.modelConfig = {
        type: 'string',
        mask:
          store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
            ? 'MM-DD-YYYY HH:mm'
            : 'DD-MM-YYYY HH:mm',
      };

      if (!val) {
        this.editedDrivingItem = Object.assign({}, defaultDriving);
        this.editedDrivingComment = Object.assign({}, defaultDrivingComment);
        this.filesToSend = [];
        this.checkpoints = [];
        this.passengers = [];
        if (this.$refs.drivingCommentForm) {
          this.$refs.drivingCommentForm.reset();
        }
        if (this.$refs.drivingForm) {
          this.$refs.drivingForm.reset();
        }
        this.$refs.drivingForm.reset();
        this.activeTab = 0;
        this.filterAllVehicleClasses();

        this.user = this.$store.getters['auth/user'];
      } else {
        this.filterAllVehicleClasses();

        if (!this.editedDrivingItem.id && this.editedDrivingItem.pickup_time) {
          this.editedDrivingItem.pickup_time = formatDateTime(this.editedDrivingItem.pickup_time);

          this?.$refs?.pickupTime && this?.$refs?.pickupTime.save(this.editedDrivingItem.pickup_time);
        }

        this.user = this.$store.getters['auth/user'];
      }
    },
    selectedElement: {
      handler() {
        this.showEvent(this.selectedElement);

        this.selectedOpen = true;
      },
      deep: false,
    },
    transferOrDaily: {
      handler() {
        this.editedDrivingItem.driving_type_id = this.transferOrDaily;
        if (this.transferOrDaily === 1) {
          this.formTitle = 'driving.newDriving';
        } else {
          this.formTitle = 'driving.newDailyRent';
        }
      },
    },
  },
};
</script>

<style>
#arrivalTime .vc-date,
#expectedDuration .vc-date {
  display: none;
}

#expectedDuration {
  border: none;
  border-radius: 0;
  background-color: #fff;
}

.time-pick-label {
  font-size: 12px;
  position: absolute;
  z-index: 2;
  margin-top: -1px;
  color: rgba(0, 0, 0, 0.6);
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: inherit;
}

.scrollable-content {
  overflow-y: auto;
  max-height: 70vh; /* Adjust according to your dialog size */
}

.sticky-header.tabs {
  background-color: #fff;
}

.smaller-font {
  font-size: 12px;
}

.v-icon.status-circle {
  font-size: 16px;
}

.vc-day-content {
  display: none;
}

@media only screen and (min-width: 600px) {
  .sticky-header .v-tabs {
    width: 50%;
  }
}
</style>
