import { deleteClient, getAllClients, saveClient, updateClient, changeClientActiveStatus } from '@/api/clients';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getAllClients(state, item) {
      return getAllClients(item);
    },
    async saveClient(state, item) {
      return saveClient(item);
    },
    async updateClient(state, item) {
      return updateClient(item);
    },
    async deleteClient(state, item) {
      return deleteClient(item);
    },
    async changeClientActiveStatus(state, item) {
      return changeClientActiveStatus(item);
    },
  },
  getters: {},
};
