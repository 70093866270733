import { getHttpClient } from './client';

export const getAllDrivingOffers = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/driving-offer';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getDrivingOffer = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-offer/' + data).then((response) => response.data);
};

export const saveDrivingOffer = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-offer', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteDrivingOffer = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-offer/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const generateDrivingOfferPaymentLink = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/stripe/generate-driving-offer-checkout-link';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};
