import i18n from '@/i18n/i18n';

export function mainLayoutSteps() {
  return [
    {
      target: '#v-step-0',
      content: i18n.t('onboardingTutorialMain.languageStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#v-step-1',
      content: i18n.t('onboardingTutorialMain.notificationsStep'),
      targetId: '1',
    },
    {
      target: '#v-step-2',
      content: i18n.t('onboardingTutorialMain.addTransferStep'),
      targetId: '2',
    },
    {
      target: '#v-step-3',
      content: i18n.t('onboardingTutorialMain.supportStep'),
      targetId: '3',
      params: {
        placement: 'top',
        highlight: false,
      },
    },
    {
      target: '#v-step-4',
      content: i18n.t('onboardingTutorialMain.sideMenuButtonStep'),
      params: {
        placement: 'right',
      },
      targetId: '4',
    },
    {
      target: '#v-step-5',
      content: i18n.t('onboardingTutorialMain.sideMenuExpStep'),
      params: {
        placement: 'right',
      },
      targetId: '5',
    },
    {
      target: '#v-step-4',
      content: i18n.t('onboardingTutorialMain.sideMenuButtonCloseStep'),
      params: {
        placement: 'top',
      },
      targetId: '4',
    },
  ];
}
export function mainLayoutStepsSmallDevices() {
  return [
    {
      target: '#v-step-0',
      content: i18n.t('onboardingTutorialMain.languageStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#v-step-1',
      content: i18n.t('onboardingTutorialMain.notificationsStep'),
      targetId: '1',
    },
    {
      target: '#v-step-2',
      content: i18n.t('onboardingTutorialMain.addTransferStep'),
      targetId: '2',
    },
    {
      target: '#v-step-3',
      content: i18n.t('onboardingTutorialMain.supportStep'),
      targetId: '3',
      params: {
        placement: 'top',
        highlight: false,
      },
    },
    {
      target: '#v-step-4',
      content: i18n.t('onboardingTutorialMain.sideMenuButtonStep'),
      params: {
        placement: 'left',
      },
      targetId: '4',
    },
    {
      target: '#v-step-5',
      content: i18n.t('onboardingTutorialMain.sideMenuExpStep'),
      params: {
        placement: 'bottom',
      },
      targetId: '5',
    },
  ];
}

export function vehiclesPageSteps() {
  return [
    {
      target: '#vehicle-page-step-0',
      content: i18n.t('onboardingTutorialVehicles.tableStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#vehicle-page-step-1',
      content: i18n.t('onboardingTutorialVehicles.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#vehicle-page-step-2',
      content: i18n.t('onboardingTutorialVehicles.addStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];
}

export function statisticsPageSteps() {
  return [
    {
      target: '#statistics-page-step-0',
      content: i18n.t('onboardingTutorialStatistics.typeStep'),
      params: {
        disable: true,
        enableScrolling: false,
        placement: 'bottom',
      },
      targetId: '0',
    },
    {
      target: '#statistics-page-step-1',
      content: i18n.t('onboardingTutorialStatistics.intervalStep'),
      params: {
        disable: true,
        enableScrolling: false,
      },
      targetId: '1',
    },
    {
      target: '#statistics-page-step-2',
      content: i18n.t('onboardingTutorialStatistics.downloadStep'),
      params: {
        disable: true,
        enableScrolling: false,
      },
      targetId: '2',
    },
  ];
}

export function gpsPageSteps() {
  return [
    {
      target: '#gps-page-step-0',
      content: i18n.t('onboardingTutorialGps.tabOneStep'),
      params: {
        disable: true,
        enableScrolling: false,
        placement: 'bottom',
      },
      targetId: '0',
    },
    {
      target: '#gps-page-step-1',
      content: i18n.t('onboardingTutorialGps.tabTwoStep'),
      params: {
        disable: true,
        enableScrolling: false,
      },
      targetId: '1',
    },
    {
      target: '#gps-page-step-2',
      content: i18n.t('onboardingTutorialGps.mapStep'),
      params: {
        placement: 'bottom',
        disable: true,
        enableScrolling: false,
      },
      targetId: '2',
    },
  ];
}

export function getAllTourSteps() {
  return [
    {
      component_id: '1',
      step_id: '#v-step-0',
    },
    {
      component_id: '1',
      step_id: '#v-step-1',
    },
    {
      component_id: '1',
      step_id: '#v-step-2',
    },
    {
      component_id: '1',
      step_id: '#v-step-3',
    },
    {
      component_id: '1',
      step_id: '#v-step-4',
    },
    {
      component_id: '1',
      step_id: '#v-step-5',
    },
    {
      component_id: '1',
      step_id: '#v-step-5',
    },
    {
      component_id: '1',
      step_id: '#v-step-4',
    },
    {
      component_id: '2',
      step_id: '#vehicle-page-step-0',
    },
    {
      component_id: '2',
      step_id: '#vehicle-page-step-1',
    },
    {
      component_id: '2',
      step_id: '#vehicle-page-step-2',
    },
    {
      component_id: '4',
      step_id: '#gps-page-step-0',
    },
    {
      component_id: '4',
      step_id: '#gps-page-step-1',
    },
    {
      component_id: '4',
      step_id: '#gps-page-step-2',
    },
    {
      component_id: '3',
      step_id: '#statistics-page-step-0',
    },
    {
      component_id: '3',
      step_id: '#statistics-page-step-1',
    },
    {
      component_id: '3',
      step_id: '#statistics-page-step-2',
    },
  ];
}
