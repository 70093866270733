import { getHttpClient } from './client';

export const getAllVehicleExpenses = async (params) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/expense';
  let queryString = '?';
  if (params.from_date) {
    queryString += `from=${params.from_date}`;
  }
  if (params.to_date) {
    queryString += `&to=${params.to_date}`;
  }
  if (params.vehicle_id) {
    queryString += `&vehicle_id=${params.vehicle_id}`;
  }
  if (params.user_id) {
    queryString += `&user_id=${params.user_id}`;
  }
  if (params.driving_id) {
    queryString += `&driving_id=${params.driving_id}`;
  }
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAllExpensesTypes = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/expense-types').then((response) => response.data);
};

export const saveVehicleExpense = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/expense', 'POST', data).then((response) => response.data);
};

export const updateVehicleExpense = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/expense', 'POST', data).then((response) => response.data);
};

export const deleteVehicleExpense = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/expense/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const deleteExpenseAttachment = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/delete-expense-file/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const exportExcel = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/export-expenses';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};
