<template>
  <div
    :class="`card my-5 ${active && clickable ? 'active' : ''} ${clickable ? 'clickable' : 'readonly mx-5'}`"
    @click="handleActiveDriver(driver)">
    <div class="d-flex align-top justify-space-between mb-0">
      <div v-if="driver?.profile?.full_name || driver?.profile?.phone">
        <h1 class="driver mb-0">
          {{ driver?.profile?.full_name }}
        </h1>
        <p class="phone mb-0">
          {{ driver?.profile?.phone }}
        </p>
      </div>
      <div :class="'d-flex align-center status ml-5 ' + driver?.status">
        <div class="circle me-2"></div>
        <p class="mb-0">
          {{ $t('driverStatus.' + driver?.status) }}
        </p>
      </div>
    </div>
    <p
      class="driving-info mt-2 mb-0"
      v-if="driver?.driving?.number || driver?.driving?.duration || driver?.driving?.distance">
      <v-tooltip bottom v-if="driver && driver.driving && driver.driving.number">
        <template v-slot:activator="{ on }">
          <span v-on="on" class="driving">
            {{ driver.driving.number }}
          </span>
        </template>
        <div>
          <div class="d-flex justify-space-between">
            <span>{{ JSON.parse(driver?.driving?.from_location).name }}</span>
            <span class="ml-5">{{ driver?.driving?.pickup_time }} </span>
          </div>
          <div class="d-flex justify-space-between">
            <span>{{ JSON.parse(driver?.driving?.to_location).name }}</span>
            <span class="ml-5">{{ driver?.driving?.expected_drop_off_time }} </span>
          </div>
        </div>
      </v-tooltip>
      <span v-if="driver?.driving?.number && driver?.driving?.duration" class="line"> | </span>
      <span v-if="driver?.driving?.duration">
        {{ driver?.driving?.duration?.slice(0, 2) }}h {{ driver?.driving?.duration?.slice(3, 5) }}m
      </span>
      <span v-if="driver?.driving?.duration && driver?.driving?.distance" class="line"> | </span>
      <span v-if="driver?.driving?.distance">
        {{ driver?.driving?.distance }}
        {{ user?.organisation?.organisation_settings?.distance_unit == 'mi' ? 'mi' : 'km' }}
      </span>
    </p>

    <div class="d-flex align-center justify-space-between">
      <div>
        <p class="last-seen mt-2 mb-0" v-if="locationName">
          {{ $t('gpsMaps.lastRecordedLocation') }}
          <span class="line">|</span>
          {{ getCoordinatesTime }}
        </p>
        <p class="location mt-1 mb-0" v-if="locationName">
          <i class="mdi mdi-map-marker-outline"></i>
          {{ locationName }}
        </p>
      </div>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="icon-btn mt-2 mb-0 ml-5 pa-0"
            @click="requestDriverLocation(driver?.id)"
            v-bind="attrs"
            v-on="on"
            :disabled="notificationDisabled">
            <v-icon> mdi-bell-ring-outline </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('gpsMaps.requestDriverLocation') }}
        </span>
      </v-tooltip>
    </div>
    <p class="vehicle mt-2 mb-0" v-if="driver?.driving?.vehicle?.brand || driver?.driving?.vehicle?.licence_plate">
      {{ driver?.driving?.vehicle?.brand }}
      <span class="plates" v-if="driver?.driving?.vehicle?.licence_plate">
        {{ driver?.driving?.vehicle?.licence_plate }}
      </span>
    </p>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { formatTimeAgo } from '@/utils/formatDate';

export default {
  name: 'DriverCard',
  props: ['driver', 'active', 'clickable'],
  components: {},
  data: () => ({
    locationName: '',
    notificationDisabled: false,
    user: {},
  }),
  created() {
    this.getLocationName();

    this.user = this.$store.getters['auth/user'];
  },
  computed: {
    getCoordinatesTime() {
      return formatTimeAgo(this.driver?.latest_gps_coordinate?.created_at);
    },
  },
  methods: {
    handleActiveDriver(driver) {
      this.$emit('handleActiveDriver', driver); // To know on which driver we have clicked on
    },
    async requestDriverLocation(driverId) {
      await this.$store
        .dispatch('gpsMapAPI/requestDriverLocation', { id: driverId })
        .then((res) => {
          if (res.success) {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.driverNotifiedToTurnGpsOn'),
              color: 'green',
            });
            this.notificationDisabled = true;
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    getLocationName() {
      this.$store
        .dispatch('googleMapsAPI/getLocationName', {
          lat: this.driver?.latest_gps_coordinate?.lat,
          lng: this.driver?.latest_gps_coordinate?.lng,
        })
        .then((data) => {
          this.locationName = data;
        });
    },
    createFormData() {
      let formData = new FormData();

      formData.append('user_id', this?.driver?.id);
      // formData.append('vehicle_id', 1);

      return formData;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.card {
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;

  &.clickable {
    &:hover,
    &.active {
      background: var(--brand-variations-light-background-2, #edeefe);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.readonly {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.driver {
  color: var(--greys-Grey-1, #1b1d20);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.phone {
  color: var(--greys-Grey-6, #868686);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.driving-info {
  color: var(--greys-Grey-1, #1b1d20);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  .driving {
    font-weight: 700;
  }
}

.status {
  height: fit-content;
  background: var(--brand-variations-light-background-2, #edeefe);
  border-radius: 30px;
  padding: 8px 12px;

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
  }

  &.inTransfer {
    .circle {
      background-color: #fe4040;
      animation: blink-animation 1s infinite; /* Apply the animation */
    }
  }

  &.available {
    .circle {
      background-color: #3cb503;
    }
  }

  &.offline {
    .circle {
      background-color: #9ca0c8;
    }
  }

  p {
    color: var(--greys-Grey-1, #1b1d20);
    font-family: 'Inter', 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

.vehicle {
  color: var(--brand-variations-mid-dark2, #45484f);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;

  .plates {
    font-weight: 400;
  }
}

.last-seen {
  color: var(--greys-Grey-6, #868686);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}

.location {
  color: var(--greys-Grey-1, #1b1d20);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.line {
  color: var(--greys-Grey-7, #bcbcbc);
}

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
