import { getHttpClient } from './client';

export const getResourceData = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/organisation-extra-resource';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAuditData = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/organisation-extra-resources-audits';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const generateMoreSmsLink = async () => {
  let baseUrl = process.env.VUE_APP_API_URL + '/stripe/generate-sms-checkout-link';
  return await getHttpClient(baseUrl).then((response) => response.data);
};
