import App from '../main';
import axios from 'axios';

export function getHttpClient(url, method = 'GET', data = null, token = '') {
  let httpClient = axios.create({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      Authorization: token ? 'Bearer ' + token : '',
    },
  });

  httpClient.interceptors.request.use(requestAuthInterceptor);

  // Set up interceptor
  httpClient.interceptors.request.use(
    (config) => {
      if (config.method === 'get' || config.method === 'GET') {
        // Show loader for GET requests
        App.$store.dispatch('loader/showLoader'); // Dispatch an action to show loader
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  httpClient.interceptors.response.use(
    (response) => {
      // Hide loader
      App.$store.dispatch('loader/hideLoader'); // Dispatch an action to hide loader

      return response;
    },
    (error) => {
      // Hide loader even if there's an error
      App.$store.dispatch('loader/hideLoader'); // Dispatch an action to hide loader

      if (error.response.status === 401) {
        App.$store.dispatch('auth/removeUserData');
        App.$router.push({ name: 'Login' });
      }
      if (error.response.status === 429) {
        App.$store.dispatch('showSnackbar', { text: App.$i18n.t('snackbar.toManyRequests'), color: 'red' });
      }
      return Promise.reject(error);
    }
  );

  switch (method.toUpperCase()) {
    case 'GET':
      return httpClient.get(url);
    case 'POST':
      return httpClient.post(url, data);
    case 'PUT':
      Object.assign(data, { _method: method });
      return httpClient.post(url, data);
    case 'DELETE':
      Object.assign(data, { _method: method });
      return httpClient.post(url, data);
    default:
      return httpClient.get(url);
  }
}

export function getHttpClientForBlob(url) {
  let httpClientBlob = axios.create({
    responseType: 'blob',
  });

  httpClientBlob.interceptors.request.use(requestAuthInterceptor);

  return httpClientBlob.get(url);
}

const requestAuthInterceptor = (config) => {
  // config.headers['Authorization'] = App.$store.getters['auth/authToken'] ? 'Bearer ' + App.$store.getters['auth/authToken'] : ''
  if (App.$store.getters['auth/authToken'])
    config.headers['Authorization'] = 'Bearer ' + App.$store.getters['auth/authToken'];

  return config;
};

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
});

httpClient.interceptors.request.use(requestAuthInterceptor);
// httpClient.interceptors.response.use(responseInterceptor, responseErrorInterceptor)
const cancelTokenSource = () => axios.CancelToken.source();
export { httpClient, cancelTokenSource };
